export function copyElementTextToClipboard(elementId: string): boolean {
  // @ts-ignore
  window.getSelection().selectAllChildren(document.getElementById(elementId));
  let success = false;
  try {
    success = document.execCommand("copy");
    // @ts-ignore
    window.getSelection().removeAllRanges();
  } catch (e) {
    console.error("Unable to copy to clipboard.", e);
  }

  return success;
}
