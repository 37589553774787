// @flow

import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingIndicator from "../../ratecards/components/LoadingIndicator";
import UndoDeleteMessageAlert from "./UndoDeleteMessageAlert";
import PunchOutRatesTab from "./PunchOutRatesTab";
import BuyRateState from "../../../models/BuyRateState";
import PunchOutModal from "./PunchOutModal";
import ContainerSection from "../../shared/ContainerSection";
import Modal from "../../../components/modals/Modal";
import Button from "../../../components/lib/Button";
import TextInput from "../../../components/lib/TextInput";
import Box from "../../../components/lib/Box";
import { ModalButtons, ModalTitle } from "../../ratecards/components/FilterModal";
import { PunchoutButton, PunchoutButtonGroup } from "./HourlyPunchOutLevelRow";

// const PunchOutMarketCompareTab = observer(props => {
//   let search = props.modalState.store.search;
//   let buyRate = props.modalState.buyRate;
//   return <InternalRateCardvsMarketChart search={search} buyRate={buyRate} />;
// });

const PunchOutCard = (props: { buyRateState: BuyRateState }) => {
  const search = props.buyRateState.search;
  const buyRate = props.buyRateState.buyRate;

  if (!buyRate) {
    return (
      <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 buy-rate-card-container">
        <div className="panel panel-default panel-results">
          <Box className="panel-heading">
            <PunchoutButtonGroup className="btn-group header-options text-right pull-right">
              <PunchoutButton onClick={props.buyRateState.showModal}>
                Create Punchout
              </PunchoutButton>
            </PunchoutButtonGroup>
          </Box>
          <div className="panel-body">
            <div className="alert alert-info no-items" role="alert">
              <p>
                Punchouts allow you to save, view and export your custom rate ranges by
                level for the current job. By creating a Punchout, you can compare your
                internal rates to the market rates.
              </p>
              <p>
                <Button size="large" color="brand" onClick={props.buyRateState.showModal}>
                  Create Punchout
                </Button>
              </p>
            </div>
          </div>
        </div>

        <PunchOutModal title="Create Punchout" buyRateState={props.buyRateState}>
          <ContainerSection>
            <label htmlFor="buyRatesLabel" className="block">
              Name:
            </label>
            <TextInput
              fill
              id="buyRatesLabel"
              value={props.buyRateState.name}
              onChange={(e) => props.buyRateState.onNameChange(e.target.value)}
            />
          </ContainerSection>
        </PunchOutModal>
      </div>
    );
  }

  const ratesTabSelected =
    props.buyRateState.selectedTab === props.buyRateState.PUNCH_OUT_RATES_TAB;
  // let compareTabSelected =
  //   props.buyRateState.selectedTab ===
  //   props.buyRateState.PUNCH_OUT_MARKET_COMPARE_TAB;

  return (
    <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6 buy-rate-card-container">
      <div
        className="panel panel-default panel-results"
        onClick={props.buyRateState.ignoreDeletedInternalResult}
      >
        <div className="panel-heading">
          <h3 className="panel-title pull-left" style={{ lineHeight: "1.75rem" }}>
            {buyRate.name || `Label: N/A`}
          </h3>
          <PunchoutButtonGroup className="btn-group header-options text-right pull-right">
            {!props.buyRateState.isEmptyBuyRate() && (
              <PunchoutButton
                color="primary"
                title="Export"
                onClick={props.buyRateState.exportToExcel}
              >
                <FontAwesomeIcon icon="download" />
              </PunchoutButton>
            )}
            <PunchoutButton
              color="brand"
              title="Edit"
              onClick={props.buyRateState.showModal}
            >
              <FontAwesomeIcon icon="edit" />
            </PunchoutButton>
            <PunchoutButton
              color="danger"
              title="Delete"
              onClick={props.buyRateState.confirmDeletion}
            >
              <FontAwesomeIcon icon="trash-alt" className="danger-icon" />
            </PunchoutButton>
          </PunchoutButtonGroup>
        </div>
        <div className="panel-body">
          <div className="row buy-rate-details">
            <div className="col-sm-3">
              <section className="section-detail text-small">
                <header className="text-xx-small">Job Title</header>
                {search.title}
              </section>
            </div>
            <div className="col-sm-3">
              <section className="section-detail text-small">
                <header className="text-xx-small">Location</header>
                {search.locationDetail()}
              </section>
            </div>
            <div className="col-sm-2">
              <section className="section-detail text-small">
                <header className="text-xx-small">Category</header>
                {search.category}
              </section>
            </div>
            <div className="col-sm-2">
              <section className="section-detail text-small">
                <header className="text-xx-small">Industry</header>
                {search.industry.title}
              </section>
            </div>
            <div className="col-sm-2">
              <section className="section-detail text-small">
                <header className="text-xx-small">Created Date</header>
                {buyRate.createdDate.format("MMMM DD, YYYY")}
              </section>
            </div>
          </div>

          {props.buyRateState.deletedPunchOutRow && (
            <UndoDeleteMessageAlert
              undoDelete={props.buyRateState.undoBuyRateDelete}
              punchOutRow={props.buyRateState.deletedPunchOutRow}
            />
          )}
          <div className="pull-right">
            {/* TODO: Re-enable this section once we have better scope for T3146 */}
            {/*<ToggleButton*/}
            {/*name="show-options"*/}
            {/*type="radio"*/}
            {/*value={props.buyRateState.PUNCH_OUT_RATES_TAB}*/}
            {/*selected={ratesTabSelected}*/}
            {/*onClick={props.buyRateState.onTabChange}*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon="usd" fixedWidth className="icon" />*/}
            {/*<span className="hidden-xs hidden-sm"> Punchout Rates</span>*/}
            {/*</ToggleButton>*/}
            {/*<ToggleButton*/}
            {/*name="show-options"*/}
            {/*type="radio"*/}
            {/*value={props.buyRateState.PUNCH_OUT_MARKET_COMPARE_TAB}*/}
            {/*selected={compareTabSelected}*/}
            {/*onClick={props.buyRateState.onTabChange}*/}
            {/*>*/}
            {/*<FontAwesomeIcon icon="bar-chart" fixedWidth className="icon" />*/}
            {/*<span className="hidden-xs hidden-sm"> Market Comparison</span>*/}
            {/*</ToggleButton>*/}
          </div>
        </div>

        {ratesTabSelected && <PunchOutRatesTab buyRateState={props.buyRateState} />}

        {/*{compareTabSelected &&*/}
        {/*<PunchOutMarketCompareTab modalState={props.buyRateState} />}*/}

        <div
          className={classNames({
            "loading-indicator-container": true,
            show: props.buyRateState.networkState.loading,
          })}
        >
          <LoadingIndicator
            style={{
              minHeight: 0,
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </div>
      </div>
      <PunchOutModal title="Edit Punchout" buyRateState={props.buyRateState}>
        <ContainerSection>
          <label htmlFor="buyRatesLabel" className="block">
            Name:
          </label>
          <TextInput
            fill
            id="buyRatesLabel"
            value={props.buyRateState.name}
            onChange={(e) => props.buyRateState.onNameChange(e.target.value)}
          />
        </ContainerSection>
      </PunchOutModal>
      <Modal
        show={props.buyRateState.showDeletionConfirmation}
        onHide={props.buyRateState.cancelDeletion}
      >
        <ContainerSection className="header">
          <ModalTitle>Confirmation needed</ModalTitle>
        </ContainerSection>
        <ContainerSection>
          <p>Are you sure you want to delete this Punchout Card?</p>
          <p className="text-danger">
            <FontAwesomeIcon icon="exclamation-triangle" />{" "}
            <em>This action cannot be undone!</em>
          </p>
        </ContainerSection>
        <ContainerSection className="footer">
          <ModalButtons>
            <Button size="large" color="danger" onClick={props.buyRateState.doDelete}>
              Yes, Delete Punchout
            </Button>
            <Button size="large" onClick={props.buyRateState.cancelDeletion}>
              No, Cancel
            </Button>
          </ModalButtons>
        </ContainerSection>
      </Modal>
    </div>
  );
};

export default observer(PunchOutCard);
