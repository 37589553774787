// @flow
import * as React from "react";
import { Router, Route, IndexRoute, browserHistory } from "react-router";
import type { FetchGraphQL, FetchAPI } from "./App";
import MobXStore from "./stores/mobx/MobXStore.js";

import SignupForm from "./views/login/SignupForm";
import LoginForm from "./views/login/LoginForm";
import PrimaryComponent from "./views/shared/PrimaryComponent";
import ResetPassword from "./views/login/ResetPassword";

import DashboardIndex from "./views/dashboard/Index";
import Account from "./views/Account";
import SearchCounterContainer from "./views/search_counter/SearchCounterContainer";

import OldRateSearch from "./views/ratesearch/RateSearch";
import OldRateSearchForm from "./views/ratesearch/RateSearchForm";

import JobsList from "./views/job_labels/List";
import AddJob from "./views/job_labels/AddJob";
import JobsDetail from "./views/job_labels/Detail";

import RateCardsList from "./views/ratecards/List";
import RateCardsCreate from "./views/ratecards/Create";
import RateCardsNotFound from "./views/ratecards/NotFound";
import RateCardsDetail from "./views/ratecards/Detail";
import RateCardsDetailList from "./views/ratecards/DetailList";
import RateCardsDetailVisualize from "./views/ratecards/DetailVisualize";
import RateCardsEdit from "./views/ratecards/Edit";
import RateCardsMap from "./views/ratecards/Map";
import RateCardStitches from "./views/ratecards2/RateCardsStitches";

import ProjectCostEstimateList from "./views/project-cost-estimate/List";
import ProjectCostEstimateCreate from "./views/project-cost-estimate/Create";
import ProjectCostEstimateDetail from "./views/project-cost-estimate/Detail";

import PunchoutsList from "./views/punchouts/List";

import SavedSearchesList from "./views/saved_searches/List";

import Searches from "./views/searches/Searches";

import RegionList from "./views/region/List";
import RegionCreate from "./views/region/Create";
import RegionDetail from "./views/region/Detail";

import NotificationsList from "./views/notifications/List";

import ReportsList from "./views/reports/List";

import NegotiationWorksheetList from "./views/negotiation_worksheets/List";
import NegotiationWorksheetDetail from "./views/negotiation_worksheets/Detail";
import CreateNegotiationWorksheet from "./views/negotiation_worksheets/CreateNegotiationWorksheet";

import TagList from "./views/tag_management/List";
import TagCreate from "./views/tag_management/Create";
import TagDetail from "./views/tag_management/Detail";

import SearchAllocator from "./views/SearchAllocator";

import SearchesList from "./views/searches/List";
import SearchesDetail from "./views/searches/Detail";

import AdminRegionList from "./views/pt-admin/region/List";
import AdminRegionDetail from "./views/pt-admin/region/Detail";

import AdminProjectCostEstimateList from "./views/pt-admin/project-cost-estimate/List";
import AdminProjectCostEstimateDetail from "./views/pt-admin/project-cost-estimate/Detail";

import AdminTags from "./components/AdminTags";
import AdminTagsDetail from "./views/pt-admin/tag_management/Detail";

import AdminClientJobs from "./components/AdminClientJobs";
import AdminClientJobsDetail from "./views/pt-admin/job_labels/Detail";

import Theme from "./views/theme/Theme";

import AdminRateCardsList from "./views/pt-admin/ratecards/List";
import AdminRateCardsDetail from "./views/pt-admin/ratecards/Detail";

import AdminSearches from "./components/AdminSearches";

import AdminNegotiationWorksheetsList from "./views/pt-admin/negotiation_worksheets/List";
import AdminNegotiationWorksheetsDetail from "./views/pt-admin/negotiation_worksheets/Detail";

import AdminClientsList from "./views/pt-admin/clients/List";
import AdminClientsCreate from "./views/pt-admin/clients/Create";
import AdminClientsDetail from "./views/pt-admin/clients/Detail";

import AdminUsersList from "./views/pt-admin/users/List";
import AdminUsersCreate from "./views/pt-admin/users/Create";
import AdminUsersDetail from "./views/pt-admin/users/Detail";

import AdminPunchoutsList from "./views/pt-admin/punchouts/List";

import AdminUserManagementList from "./views/pt-admin/user_management/List";

import BatchSearchList from "./views/scheduled_search/List";
import BatchSearchCreate from "./views/scheduled_search/Create";
import BatchSearchDetail from "./views/scheduled_search/Detail";

import NotFound from "./views/error_pages/NotFound";
import SessionInfo from "./models/SessionInfo";
import JobLibrary from "./views/job_library/JobLibrary";
import JobLibraryDetailRedirect from "./views/job_library/JobLibraryDetailRedirect";
import JobLibraryDetail from "./views/job_library/Detail";
import JobLibraryTitleRequests from "./views/job_library/LibraryTitleRequests";
import JobLibraryRequests from "./views/job_library/JobLibraryRequests";
import JobLibraryCreateCustom from "./views/job_library/JobLibraryCreateCustom";
import JobLibraryEditCustom from "./views/job_library/JobLibraryEditCustom";
import JobLibraryRequestCreate from "./views/job_library/JobLibraryRequestCreate";
import LibraryTitleRequestCreate from "./views/job_library/LibraryTitleRequestCreate";
import LibraryTitleRequestCountryCreate from "./views/job_library/LibraryTitleRequestCountryCreate";
import AdminSearchDetail from "./components/AdminSearchDetail";
import LearningCenter from "./components/LearningCenter";

// Val5000 Admin UI
import Val5KAdminEntry from "./views/validator5K_admin/Val5KAdmin";
import Val5KAdminDashboard from "./views/validator5K_admin/pages/DashboardPage";
import Val5KReviewFromRatecardForm from "./views/validator5K_admin/pages/ReviewFromRatecardForm";
import Val5KReviewsList from "./views/validator5K_admin/pages/ReviewsListPage";
import Val5KReviewItem from "./views/validator5K_admin/pages/ReviewItemPage";
import Val5KReviewContextProvider from "./views/validator5K_admin/context/ReviewDataContext";
import Val5KAttemptsList from "./views/validator5K_admin/pages/AttemptsListPage";
import Val5KAttemptItem from "./views/validator5K_admin/pages/AttemptItemPage";
import Val5KAttemptContextProvider from "./views/validator5K_admin/context/AttemptDataContext";
import Val5KPermissionsList from "./views/validator5K_admin/pages/PermissionsListPage";
import Val5KAlertsList from "./views/validator5K_admin/pages/AlertsListPage";
import Val5KPerformance from "./views/validator5K_admin/pages/PerformancePage";
import Val5KPaymentReport from "./views/validator5K_admin/pages/PaymentReportPage";

// Val5000 Public UI
import Val5KPublicEntry from "./views/validator5K/Val5KPublicEntry";
import Val5KSurveysList from "./views/validator5K/pages/SurveysListPage/SurveysListPage";
import Val5KSurveysHistory from "./views/validator5K/pages/SurveysHistoryPage/SurveysHistoryPage";
import Val5KSurveyItemEntry from "./views/validator5K/pages/SurveyItemPage/SurveyItemEntry";
import Val5KSurveyItemPageClassic from "./views/validator5K/pages/SurveyItemPage/SurveyItemPageClassic";
import Val5KSurveyItemPageOrdered from "./views/validator5K/pages/SurveyItemPage/SurveyItemPageOrdered";
import Val5KSurveyItemPageFillInTheBlanks from "./views/validator5K/pages/SurveyItemPage/SurveyItemPageFillInTheBlanks";
import Val5KSurveysPeckingOrder from "./views/validator5K/pages/SurveysPeckingOrderPage/SurveysPeckingOrderPage";

// Private Index UI
import PrivateIndex from "./views/private_index/PrivateIndex";
import ProgramsList from "./views/private_index/pages/ProgramsList";
import RegularUploadPage from "./views/private_index/pages/RegularUploadPage";
import ProgramEditor from "./views/private_index/pages/ProgramEditor";
import ProgramDataProvider from "./views/private_index/ProgramDataProvider";
import ProgramAccessEditor from "./views/private_index/pages/ProgramAccessEditor";
import ProgramDetails from "./views/private_index/pages/ProgramDetails";
import StoredIndexDataProvider from "./views/private_index/StoredIndexDataProvider";
import StoredIndexCreate from "./views/private_index/pages/StoredIndexCreate";
import StoredIndexDetails from "./views/private_index/pages/StoredIndexDetails";
import ReviewFromContractorsWizard from "./views/private_index/pages/ReviewFromContractorsWizard";
import AdminUploadPage from "./views/private_index/pages/AdminUploadPage";
import UploadDetailsPage from "./views/private_index/pages/UploadDetailsPage";
import FailedContractorsList from "./views/private_index/pages/FailedContractorsList";
import NeedApprovalContractorsList from "./views/private_index/pages/NeedApprovalContractorsList";
import ProcessingsList from "./views/private_index/pages/ProcessingsList";
import MarketSearchResults from "./views/private_index/pages/MarketSearchResults";
import DiscoveryDashboard from "./views/private_index/pages/DiscoveryDashboard";
import DiscoverySearchResultDetails from "./views/private_index/pages/DiscoverySearchResultDetails";
import RateSearchPage from "./views/ratesearch2/RateSearchPage";
import RateSearchResultsPageView from "./views/ratesearch2/RateSearchResultsPage";
import RpEula from "./views/rp-eula/RpEula";
import { GlobalContextProvider } from "./globalContext";

type Props = {
  store: MobXStore,
  fetchGraphQL: FetchGraphQL,
  fetchAPI: FetchAPI,
  fetchAPINew: FetchAPI,
  fetchGraphQLNew: FetchGraphQL,
  loggedIn: boolean,
  sessionInfo: ?SessionInfo,
  updateLoginState: (boolean, string | null, SessionInfo | null) => void,
  reactrURL: string,
  services: Object,
};

// type OnEnterRouteFn = (nextState: any, replace: any, next: any) => void;

class Routes extends React.Component<Props, {}> {
  createElement: (
    typeof React.Component,
    Object
  ) => React.Element<typeof React.Component>;

  createElement = (Component: typeof React.Component, props: Object) => {
    return (
      <GlobalContextProvider
        router={props.router}
        location={props.location}
        store={this.props.store}
        services={this.props.services}
        sessionInfo={this.props.sessionInfo}
        fetchAPI={this.props.fetchAPI}
        fetchGraphQL={this.props.fetchGraphQL}
        fetchAPINew={this.props.fetchAPINew}
        fetchGraphQLNew={this.props.fetchGraphQLNew}
        updateLoginState={this.props.updateLoginState}
      >
        {/* DO NOT wrap the component below, it should be a direct descendant of global context provider in the tree */}
        <Component
          store={this.props.store}
          fetchAPI={this.props.fetchAPI}
          fetchGraphQL={this.props.fetchGraphQL}
          fetchAPINew={this.props.fetchAPINew}
          fetchGraphQLNew={this.props.fetchGraphQLNew}
          sessionInfo={this.props.sessionInfo}
          updateLoginState={this.props.updateLoginState}
          services={this.props.services}
          {...props}
        />
      </GlobalContextProvider>
    );
  };

  setupLoginRedirect = (nextState: any, replace: any) => {
    const queryParams = nextState.location.query;
    const loginToken = queryParams.login_token;
    const redirectUrlQuery = Object.keys(queryParams)
      .filter((k) => k !== "token")
      .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
      .join("&");
    const redirectUrl = `${nextState.location.pathname}?${redirectUrlQuery}`;

    let query: { redirect: string, login_token?: string } = {
      redirect: redirectUrl,
    };
    if (loginToken) {
      query.login_token = loginToken;
    }

    replace({
      pathname: "/login",
      query: query,
      state: {
        error: {
          message: "User not logged in.",
        },
      },
    });
  };

  onEnterDashboardRedirectTest = (nextState: any, replace: any, next: any) => {
    if (this.props.loggedIn) {
      replace({ pathname: "/" });
    }
    next();
  };

  onEnterAuthenticatedUserTest = (nextState: any, replace: any, next: any) => {
    if (this.props.loggedIn) {
      next();
      return;
    }

    this.setupLoginRedirect(nextState, replace);
    next();
  };

  onEnterIsPTReportsUserTest = (nextState: any, replace: any, next: any) => {
    const user = this.props.sessionInfo?.user ?? null;
    if (this.props.loggedIn && user) {
      if (!user.isReportsUser()) {
        // not allowed
        replace({ pathname: "/not-found" });
      }

      next();
      return;
    }

    this.setupLoginRedirect(nextState, replace);
    next();
  };

  onEnterIsPTClientAdminTest = (nextState: any, replace: any, next: any) => {
    const user = this.props.sessionInfo?.user ?? null;
    if (this.props.loggedIn && user) {
      if (!user.isClientAdmin()) {
        // not allowed
        replace({ pathname: "/not-found" });
      }

      next();
      return;
    }

    this.setupLoginRedirect(nextState, replace);
    next();
  };

  onEnterIsPTAdminTest = (nextState: any, replace: any, next: any) => {
    const user = this.props.sessionInfo?.user ?? null;
    if (this.props.loggedIn && user) {
      if (!user.isPTAdmin()) {
        // not allowed
        replace({ pathname: "/not-found" });
      }

      next();
      return;
    }

    this.setupLoginRedirect(nextState, replace);
    next();
  };

  loadJobLibraries = (nextState: any, replace: any, next: any) => {
    this.props.store.jobLibraryListStore.pagination.goFetch().then(() => {
      next();
    });
  };

  render() {
    return (
      <Router
        history={this.props.history || browserHistory}
        createElement={this.createElement}
      >
        {/* Public, Not logged in area */}
        <Route
          path="login"
          component={LoginForm}
          onEnter={this.onEnterDashboardRedirectTest}
        />
        <Route path="signup/:id" component={SignupForm} />
        <Route path="reset-password/:token" component={ResetPassword} />
        <Route path="eula" component={RpEula} />
        <Route
          path="/theme/stitches"
          component={Theme}
          onEnter={this.onEnterIsPTAdminTest}
        />
        <Route
          path="/ratecards/stitches"
          component={RateCardStitches}
          onEnter={this.onEnterIsPTAdminTest}
        />

        {/* val5000 public routes */}
        <Route path="skills-village/validate-rates" component={Val5KPublicEntry}>
          <IndexRoute component={Val5KSurveysList} />
          <Route path="surveys/:reviewId" component={Val5KSurveyItemEntry}>
            <Route path="classic" component={Val5KSurveyItemPageClassic} />
            <Route path="ordered" component={Val5KSurveyItemPageOrdered} />
            <Route path="fill-the-blank" component={Val5KSurveyItemPageFillInTheBlanks} />
            <Route path="pecking-order" component={Val5KSurveysPeckingOrder} />
          </Route>
          <Route path="surveys" component={Val5KSurveysList} />
          <Route path="history" component={Val5KSurveysHistory} />
        </Route>

        {/* Logged in area */}
        <Route component={OldRateSearch} onEnter={this.onEnterAuthenticatedUserTest}>
          <Route path="old-rate-search" component={OldRateSearchForm} />
        </Route>
        <Route
          path="/"
          component={PrimaryComponent}
          onEnter={this.onEnterAuthenticatedUserTest}
        >
          <IndexRoute component={DashboardIndex} />
          <Route path="rate-search" component={RateSearchPage} />
          <Route path="rate-search-result" component={RateSearchResultsPageView} />
          <Route path="account" component={Account} />
          <Route path="search-counter" component={SearchCounterContainer} />
          <Route path="learning-center" component={LearningCenter} />
          <Route path="notifications" component={NotificationsList} />
          <Route
            path="search-allocator"
            component={SearchAllocator}
            serviceURL={this.props.reactrURL}
          />
          <Route path="job-labels">
            <IndexRoute component={JobsList} />
            <Route path="create" component={AddJob} />
            <Route path=":jobId" component={JobsDetail} />
          </Route>
          <Route path="ratecards">
            <IndexRoute component={RateCardsList} />
            <Route path="create" component={RateCardsCreate} />
            <Route path="not-found" component={RateCardsNotFound} />
            <Route path=":rateCardId" component={RateCardsDetail}>
              <IndexRoute component={RateCardsDetailList} />
              <Route path="visualize" component={RateCardsDetailVisualize} />
            </Route>
            <Route path=":rateCardId/edit" component={RateCardsEdit} />
            <Route path=":rateCardId/map" component={RateCardsMap} />
          </Route>
          {/*PCE routings for listings, create worksheet and detail*/}
          <Route path="project-cost-estimate">
            <IndexRoute component={ProjectCostEstimateList} />
            <Route path="create" component={ProjectCostEstimateCreate} />
            <Route path=":projectCostEstimateId" component={ProjectCostEstimateDetail} />
          </Route>
          <Route path="punchouts">
            <IndexRoute component={PunchoutsList} />
          </Route>
          <Route path="saved-searches">
            <IndexRoute component={SavedSearchesList} />
          </Route>
          <Route path="searches" component={Searches}>
            <IndexRoute component={SearchesList} />
            <Route path=":searchId" component={SearchesDetail} />
            <Route path=":searchId/edit" component={SearchesList} />
          </Route>
          <Route path="regions">
            <IndexRoute component={RegionList} />
            <Route path="create" component={RegionCreate} />
            <Route path=":regionId" component={RegionDetail} />
          </Route>
          <Route path="batch-searches">
            <IndexRoute component={BatchSearchList} />
            <Route path="create" component={BatchSearchCreate} />
            <Route path=":batchSearchUUID" component={BatchSearchDetail} />
          </Route>
          <Route
            path="/reports"
            component={ReportsList}
            onEnter={this.onEnterIsPTReportsUserTest}
          />
          <Route path="negotiation-worksheets">
            <IndexRoute component={NegotiationWorksheetList} />
            <Route path="create" component={CreateNegotiationWorksheet} />
            <Route path=":worksheetId" component={NegotiationWorksheetDetail} />
          </Route>
          <Route
            path="job-library"
            component={JobLibrary}
            onEnter={this.loadJobLibraries}
          >
            <Route path="view">
              <IndexRoute component={JobLibraryDetailRedirect} />
              <Route path=":libraryId">
                <IndexRoute component={JobLibraryDetail} />
                <Route path="edit" component={JobLibraryEditCustom} />
              </Route>
              <Route path="countries">
                <Route path=":countryId">
                  <IndexRoute component={JobLibraryDetail} />
                </Route>
              </Route>
            </Route>
            <Route path="title-requests">
              <IndexRoute component={JobLibraryTitleRequests} />
              <Route path="create-title-request" component={LibraryTitleRequestCreate} />
              <Route
                path="create-country-request"
                component={LibraryTitleRequestCountryCreate}
              />
            </Route>
            <Route path="library-requests">
              <IndexRoute component={JobLibraryRequests} />
              <Route path="create" component={JobLibraryRequestCreate} />
            </Route>
            <Route path="create-custom" component={JobLibraryCreateCustom} />
          </Route>
          <Route path="tag">
            <IndexRoute component={TagList} />
            <Route path="create" component={TagCreate} />
            <Route path=":tagId" component={TagDetail} />
          </Route>

          {/* private-index routes */}
          <Route path="private-index/programs" component={PrivateIndex}>
            <IndexRoute component={ProgramsList} />
            <Route path="upload" component={RegularUploadPage} />
            <Route path="create" component={ProgramEditor} />
            <Route path=":programId/edit" component={ProgramEditor} />
            <Route path=":programId" component={ProgramDataProvider}>
              <IndexRoute component={ProgramDetails} />
              <Route path="edit-permissions" component={ProgramAccessEditor} />
              <Route path="indexes/create" component={StoredIndexCreate} />
              <Route path="indexes/:indexId" component={StoredIndexDataProvider}>
                <IndexRoute component={StoredIndexDetails} />
                <Route
                  path="validations/create"
                  component={ReviewFromContractorsWizard}
                />
              </Route>
              <Route
                path="contractors/:contractorId/market-search"
                component={MarketSearchResults}
              />
              <Route path="contractors/upload" component={AdminUploadPage} />
              <Route path="contractors/uploads/:uploadId" component={UploadDetailsPage} />
              <Route path="contractors/failed" component={FailedContractorsList} />
              <Route
                path="contractors/need-approval"
                component={NeedApprovalContractorsList}
              />
              <Route path="contractors/processings" component={ProcessingsList} />
              <Route
                path="contractors/validations/create"
                component={ReviewFromContractorsWizard}
              />
              <Route path="discovery-dashboard" component={DiscoveryDashboard} />
              <Route
                path="discovery-dashboard/search/:collectionId"
                component={DiscoverySearchResultDetails}
              />
            </Route>
          </Route>
        </Route>

        {/* start pt-admin routes*/}
        <Route
          path="/admin"
          component={PrimaryComponent}
          onEnter={this.onEnterIsPTAdminTest}
        >
          <Route path="regions">
            <IndexRoute component={AdminRegionList} />
            <Route path=":regionId" component={AdminRegionDetail} />
          </Route>

          <Route path="project-cost-estimate">
            <IndexRoute component={AdminProjectCostEstimateList} />
            <Route
              path=":projectCostEstimateId"
              component={AdminProjectCostEstimateDetail}
            />
          </Route>

          <Route path="tags">
            <IndexRoute fetchAPI={this.props.fetchAPI} component={AdminTags} />
            <Route path=":tagId" component={AdminTagsDetail} />
          </Route>

          <Route path="jobs">
            <IndexRoute fetchAPI={this.props.fetchAPI} component={AdminClientJobs} />
            <Route path=":jobId" component={AdminClientJobsDetail} />
          </Route>

          <Route path="ratecards">
            <IndexRoute component={AdminRateCardsList} />
            <Route path=":rateCardId" component={AdminRateCardsDetail} />
          </Route>

          <Route path="searches">
            <IndexRoute fetchAPI={this.props.fetchAPI} component={AdminSearches} />
            <Route path=":searchId" component={AdminSearchDetail} />
          </Route>

          <Route path="worksheets">
            <IndexRoute component={AdminNegotiationWorksheetsList} />
            <Route path=":worksheetId" component={AdminNegotiationWorksheetsDetail} />
          </Route>

          <Route path="clients">
            <IndexRoute component={AdminClientsList} />
            <Route path="create" component={AdminClientsCreate} />
            <Route path=":clientId" component={AdminClientsDetail} />
          </Route>

          <Route path="users">
            <IndexRoute component={AdminUsersList} />
            <Route path="create" component={AdminUsersCreate} />
            <Route path=":userId" component={AdminUsersDetail} />
          </Route>

          <Route path="punchouts">
            <IndexRoute component={AdminPunchoutsList} />
          </Route>

          <Route path="user-management" component={AdminUserManagementList} />

          {/* val5000 admin routes */}
          <Route path="val5000" component={Val5KAdminEntry}>
            <IndexRoute component={Val5KAdminDashboard} />
            <Route path="create-surveys" component={Val5KReviewFromRatecardForm} />
            <Route path="surveys" component={Val5KReviewsList} />
            <Route path="reviews/:reviewId" component={Val5KReviewContextProvider}>
              <IndexRoute component={Val5KReviewItem} />
            </Route>
            <Route path="validations" component={Val5KAttemptsList} />
            <Route path="validations/:attemptId" component={Val5KAttemptContextProvider}>
              <IndexRoute component={Val5KAttemptItem} />
            </Route>
            <Route path="permissions" component={Val5KPermissionsList} />
            <Route path="alerts" component={Val5KAlertsList} />
            <Route path="performance" component={Val5KPerformance} />
            <Route path="payment-report" component={Val5KPaymentReport} />
          </Route>
        </Route>

        <Route path="*" component={NotFound} />
      </Router>
    );
  }
}

export default Routes;
