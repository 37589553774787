// @flow
import type { ObservableMap } from "mobx";

import { extendObservable, computed, action, runInAction, observable } from "mobx";
import R from "ramda";
import {
  JobLabelFilter,
  RateTypeFilter,
  JobTitleFilter,
} from "../../../models/FilterState";
import CategoryFilter from "../../../models/FilterState/CategoryFilter";
import IndustryFilter from "../../../models/FilterState/IndustryFilter";
import LocationFilter, {
  LOCATION_FILTER_TYPE,
} from "../../../models/FilterState/LocationFilter";
import CurrentUser from "../../../models/User";
import RateCard from "../../../models/RateCard";
import Search from "../../../models/Search";
import ModalState from "../../../models/ModalState";
import type { FilterColumn } from "../../../models/Filter";
import type { GraphQLQuery } from "../../../models/GraphQL";
import FilterObject, { FILTER_COLUMN } from "../../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../../models/Sort";
import NetworkState from "../../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../../models/PaginationState";
import PaginationState from "../../../models/PaginationState";
import UnattachedSearchStore from "../../../stores/mobx/UnattachedSearchStore";
import { SearchListStore } from "../../../stores/mobx/interfaces/SearchListStore";
import { RateCardListComponentStore } from "./RateCardListStore";
import ExportOptionsState from "../../../models/ExportOptionsState";
import CustomBuyRatesExportModalState, {
  MARKUP_SOURCE,
} from "../../../models/CustomBuyRatesExportModalState";
import ExportOptions, {
  FILE_TYPE,
  EXPORT_TYPE,
  LEVELS_TYPE,
  CURRENCY_TYPE,
} from "../../../models/ExportOptions";
import CustomBuyRatesExportOptions from "../../../models/CustomBuyRatesExportOptions";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "../SupportFunctions";
import MessageState from "../../../models/MessageState";
import type { FetchGraphQL, FetchAPI } from "../../../App";
import type MobXStore from "../MobXStore";

// const fragments: {
//   [id: string]: (args: string[]) => string
// } = {
//   searches: (args = []) =>
//     `
//   fragment searches on RateCardNode {
//     searches (${args.join(",")}){
//       totalCount
//       pageInfo {
//         startCursor
//         endCursor
//       }
//       edges {
//         node {
//           userId
//           jobLabel
//           jobTitle
//           country
//           state
//           city
//           createdDate
//           lastUpdated
//           region {
//             userId
//             name
//           }
//           rateTypeName
//           industryName
//         }
//       }
//     }
//   }`,
//   details: (args = []) =>
//     `
//   fragment details on RateCardNode {
//     id
//     userId
//     name
//     createdDate
//     updateDate
//     active
//     shared
//   }`,
//   owner: (args = []) =>
//     `
//   fragment owner on RateCardNode {
//     ownerUserId
//     ownerFirstName
//     ownerLastName
//     ownerClientId
//   }`
// };

// const query = (params = [], args: { [id: string]: string[] } = {}) => {
//   let argsCopy = Object.assign({}, args);
//   let selectedFragments = Object.keys(argsCopy);
//   return `
//   ${selectedFragments.map(fragment => fragments[fragment](argsCopy[fragment])).join("\n")}
//   query getRatecard(${params.join(",")}, $rateCardId: Int!) {
//     viewer {
//       user {
//         userId
//         username
//         firstName
//         lastName
//         permissions {
//           allowExportRateCardsData
//         }
//       }
//       rateCard (userId: $rateCardId){
//         ${selectedFragments.map(fragment => "..." + fragment).join("\n")}
//       }
//     }
//   }`;
// };

const jobLabelsFilterCriteriaQuery = `
query getRateCard($rateCardId: Int!) {
 viewer {
   rateCard(legacyId: $rateCardId) {
     searchesFilterCriteria {
       jobLabels {
         jobLabel
       }
     }
   }
 }
}
`;

const jobTitlesFilterCriteriaQuery = `
query getRateCard($rateCardId: Int!) {
 viewer {
   rateCard(legacyId: $rateCardId) {
     searchesFilterCriteria {
       jobTitles {
         jobTitle
       }
     }
   }
 }
}
`;

const categoriesFilterCriteriaQuery = `
  query getRateCard($rateCardId: Int!){
    viewer {
      rateCard (userId: $rateCardId){
        searchesFilterCriteria {
          jobCategories {
            userId
            name
          }
        }
      }
    }
  }
`;

const industriesFilterCriteriaQuery = `
query rateCardSearches($rateCardId: Int!) {
  viewer {
    rateCardSearches(id: $rateCardId) {
      edges {
        node {
          industry {
            value
            legacyId
          }
          searchId
        }
      }
    }
  }
}

`;

const locationsFilterCriteriaQuery = `
query getRateCard($rateCardId: Int!) {
 viewer {
   rateCard(legacyId: $rateCardId) {
     searchesFilterCriteria {
       countries {
         country
       }
       states {
         state
       }
       cities {
         city
       }
       regions {
         region
       }
     }
   }
 }
}
`;

const rateTypesFilterCriteriaQuery = `
query getRateCard($rateCardId: Int!, $rateType: JobRateType!) {
  viewer {
    rateCardSearches(id: $rateCardId, filters: {rateType: $rateType}) {
      edges {
        node {
          searchId
          country
          state
          jobDescription
          job {
            jobLabel
            jobTitle
            jobTitleId
            jobDescription
          }
        }
      }
    }
  }
}
`;

class User {
  id: string;
  userId: string;
  username: string;
  firstName: string;
  lastName: string;
  selected: string;

  constructor(object: Object) {
    this.id = object.userId;
    this.userId = object.userId;
    this.username = object.username;
    this.firstName = object.firstName;
    this.lastName = object.lastName;

    extendObservable(this, {
      selected: object.selected,
    });
  }
}

export default class RateCardDetailStore implements SearchListStore {
  currentUser: CurrentUser;
  showHelpModal: boolean;
  showCurrencyModal: boolean;
  hasOwnership: boolean;
  shareUsers: User[];
  shareUsersViewState: ObservableMap<Object>;
  shareUsersInstantSearchValue: string;
  shareUsersTotalCount: number;
  shareUsersSelectedCount: number;
  allSelected: boolean;
  allSelectedfilter: boolean;
  allOnPageSelected: Boolean;
  rateCardId: ?number;
  first: ?number;
  rateCard: ?RateCard;
  searches: Search[];
  searchesViewState: ObservableMap<Object>;
  network: NetworkState;
  networkShareUsers: NetworkState;
  pagination: PaginationState;
  jobLabelFilter: JobLabelFilter;
  rateTypeFilter: RateTypeFilter;
  locationFilter: LocationFilter;
  categoryFilter: CategoryFilter;
  jobTitleFilter: JobTitleFilter;
  industryFilter: IndustryFilter;
  confirmDeleteModal: ModalState;
  shareModal: ModalState;
  updatedSearches: Array<any>;
  currencyType: "";
  currencyTypeArray: Array<any>;
  mobxStore: MobXStore;

  exportRateCardModal: ExportOptionsState;
  exportSearchesModal: ExportOptionsState;
  customExportRateCardModal: CustomBuyRatesExportModalState;

  isEditing: ?boolean;
  allowExpand: boolean;
  allowViewDetails: boolean;
  allowMultipleItemSelection: boolean;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };

  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  applyFilter: (FilterColumn, FilterObject) => void;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;

  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;

  getRateCard: (PageQuery) => Promise<PaginationInfo>;
  expandAllSearches: () => void;
  collapseAllSearches: () => void;
  clearFilters: () => void;
  showHelp: () => void;
  hideHelp: () => void;
  showCurrency: () => void;
  hideCurrency: () => void;
  changeCurrencyType: (SyntheticInputEvent<HTMLInputElement>) => void;
  changeCurrency: () => void;

  searchesView: () => [Search];
  hasOnlyHourlySearches: () => boolean;

  moveSearchesModal: ModalState;
  confirmMoveSearchesModal: ModalState;
  networkMoveRatecards: NetworkState;
  rateCardsListStore: RateCardListComponentStore;
  moveSearchesToRateCardId: *;
  unSelectedSearches: [];

  confirmRemoveSearchesModal: ModalState;
  confirmDeleteSearchesModal: ModalState;

  confirmUpdateRatesOnRateCardModal: ModalState;
  confirmUndoUpdateRatesOnRateCardModal: ModalState;

  confirmRefreshSearchesModal: ModalState;
  confirmUndoLastUpdateSearchesModal: ModalState;

  addSearchesModal: ModalState;
  addSearchesStore: UnattachedSearchStore;

  renameRateCardModal: ModalState;
  newRateCardName: string;
  showRenameRateCardModal: () => void;
  renameRateCard: () => void;
  onNewRateCardNameChange: (Event) => void;

  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleSelected: (Search) => void;
  toggleExpanded: (Search) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getShareUsers: () => void;
  share: () => void;
  setSharedUserSelectedValue: () => void;
  shareUsersOnSelectAll: (Event) => void;
  shareUsersOnDeselectAll: (Event) => void;
  shareUsersOnInstantSearch: (string) => void;
  performShare: () => void;
  refreshRateCards: () => void;
  selectRateSearches: () => void;

  exportRateCard: (options: ExportOptions) => void;
  validateExportRateCardOptions: (modalState: ExportOptionsState) => void;
  exportSearches: (options: ExportOptions) => void;
  validateExportSearchesOptions: (modalState: ExportOptionsState) => void;
  customExportRateCard: (CustomBuyRatesExportOptions) => void;

  viewRatecardInMap: () => void;
  viewSearchesInMap: () => void;
  performAddSearches: () => void;
  onAddSearchesClick: () => void;
  onMoveSearchesClick: (event: Event) => void;
  confirmMoveSearches: () => void;
  performMoveSearches: () => void;
  removeSearches: () => void;
  deleteSearches: () => void;
  applyDefaultSort: () => void;
  messaging: MessageState;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobxStore: MobXStore) {
    this.mobxStore = mobxStore;
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.router = null;
    this.unSelectedSearches = [];

    this.getRateCard = action(this.getRateCard.bind(this));
    // NOTE: Bound early to pass into filter states
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    // this.getRatecardsToMove = action(this.getRatecardsToMove.bind(this))

    // Bound early to pass into export state
    this.exportRateCard = action(this.exportRateCard.bind(this));
    this.validateExportRateCardOptions = action(
      this.validateExportRateCardOptions.bind(this)
    );
    this.exportSearches = action(this.exportSearches.bind(this));
    this.validateExportSearchesOptions = action(
      this.validateExportSearchesOptions.bind(this)
    );
    this.customExportRateCard = action(this.customExportRateCard.bind(this));
    this.selectRateSearches = action(this.selectRateSearches.bind(this));
    this.changeCurrencyType = action(this.changeCurrencyType.bind(this));
    this.changeCurrency = action(this.changeCurrency.bind(this));

    extendObservable(this, {
      showHelpModal: false,
      showCurrencyModal: false,
      hasOwnership: false,
      currencyType: "",
      confirmDeleteRateCardModal: new ModalState(),
      confirmUpdateRatesOnRateCardModal: new ModalState(),
      confirmUndoUpdateRatesOnRateCardModal: new ModalState(),
      confirmUpdateRatesOnSearchModal: new ModalState(),
      confirmUndoUpdateRatesOnSearchModal: new ModalState(),
      confirmRemoveSearchesModal: new ModalState(),
      confirmDeleteSearchesModal: new ModalState(),
      confirmRefreshSearchesModal: new ModalState(),
      confirmUndoLastUpdateSearchesModal: new ModalState(),

      exportRateCardModal: new ExportOptionsState(
        this.exportRateCard,
        this.validateExportRateCardOptions
      ),
      exportSearchesModal: new ExportOptionsState(
        this.exportSearches,
        this.validateExportSearchesOptions
      ),
      customExportRateCardModal: new CustomBuyRatesExportModalState(
        fetchGraphQL,
        this.customExportRateCard
      ),

      isEditing: null, // we start with null so some view elements be hidden initially
      allowExpand: true,
      allowViewDetails: true,
      allowMultipleItemSelection: true,
      rateCardId: null,
      rateCard: null,
      currencyTypeArray: [
        { id: "0", selected: true, value: "Default" },
        { id: "1", selected: false, value: "USD" },
      ],
      searches: [],
      updatedSearches: [],
      searchesViewState: observable.map({}),
      searchesView: computed(() => {
        return this.searches.map((search) => {
          if (this.searchesViewState.has(search.id)) {
            search.viewState = this.searchesViewState.get(search.id);
          }
          return search;
        });
      }),
      hasOnlyHourlySearches: computed(() => {
        return this.searches.every((search) => search.frequency === 1);
      }),
      selectedCount: computed(() => {
        const selectedValues = this.searchesView.map(
          (search) => search.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.searchesView.map(
          (search) => search.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      allSelected: false,
      allSelectedfilter: false,
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      jobLabelFilter: new JobLabelFilter(
        this,
        FILTER_COLUMN.JOB_LABEL,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      rateTypeFilter: new RateTypeFilter(
        this,
        FILTER_COLUMN.RATE_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
      jobTitleFilter: new JobTitleFilter(
        this,
        FILTER_COLUMN.JOB_TITLE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      categoryFilter: new CategoryFilter(
        this,
        FILTER_COLUMN.CATEGORY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      industryFilter: new IndustryFilter(
        this,
        FILTER_COLUMN.INDUSTRY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      locationFilter: new LocationFilter(
        this,
        FILTER_COLUMN.LOCATION,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      isFiltered: false,
      network: new NetworkState(),
      pagination: new PaginationState(this.getRateCard),

      // Rename Rate Card
      newRateCardName: "",
      renameRateCardModal: new ModalState(),

      // Move searches modal
      moveSearchesModal: new ModalState(),
      confirmMoveSearchesModal: new ModalState(),
      networkMoveRatecards: new NetworkState(),
      rateCardsListStore: new RateCardListComponentStore(fetchGraphQL),
      moveSearchesToRateCardId: null,

      // Add searches modal
      addSearchesModal: new ModalState(),
      addSearchesStore: new UnattachedSearchStore(fetchGraphQL),

      // Share searches modal
      shareModal: new ModalState(),
      shareUsers: observable.shallow([]),
      shareUsersViewState: observable.map({}),
      shareUsersInstantSearchValue: "",
      shareUsersView: [],
      networkShareUsers: new NetworkState(),
      shareUsersTotalCount: computed(() => {
        if (!this.shareUsers) return 0;

        return this.shareUsers.length;
      }),
      shareUsersSelectedCount: computed(() => {
        if (!this.shareUsersViewState) return 0;

        const selected = this.shareUsersViewState
          .entries()
          .filter((entry) => entry[1] === true);
        return selected.length;
      }),
      messaging: new MessageState(),
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.hideCurrency = action(this.hideCurrency.bind(this));
    this.showCurrency = action(this.showCurrency.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.toggleSelected = action(this.toggleSelected.bind(this));
    this.toggleExpanded = action(this.toggleExpanded.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.refreshRateCards = action(this.refreshRateCards.bind(this));
    this.undoLastUpdateRateCard = action(this.undoLastUpdateRateCard.bind(this));
    this.refreshSearches = action(this.refreshSearches.bind(this));
    this.undoLastUpdateSearches = action(this.undoLastUpdateSearches.bind(this));
    this.getShareUsers = action(this.getShareUsers.bind(this));
    this.setSharedUserSelectedValue = action(this.setSharedUserSelectedValue.bind(this));
    this.shareUsersOnSelectAll = action(this.shareUsersOnSelectAll.bind(this));
    this.shareUsersOnDeselectAll = action(this.shareUsersOnDeselectAll.bind(this));
    this.shareUsersOnInstantSearch = action(this.shareUsersOnInstantSearch.bind(this));
    this.performShare = action(this.performShare.bind(this));
    this.deleteRatecard = action(this.deleteRatecard.bind(this));
    this.addSearches = action(this.addSearches.bind(this));
    this.showRenameRateCardModal = action(this.showRenameRateCardModal.bind(this));
    this.renameRateCard = action(this.renameRateCard.bind(this));
    this.onNewRateCardNameChange = action(this.onNewRateCardNameChange.bind(this));

    this.share = this.share.bind(this);
    this.viewSearchesInMap = this.viewSearchesInMap.bind(this);
    this.viewRatecardInMap = this.viewRatecardInMap.bind(this);
    this.exportRateCard = this.exportRateCard.bind(this);
    this.exportSearches = this.exportSearches.bind(this);

    this.expandAllSearches = action(this.expandAllSearches.bind(this));
    this.collapseAllSearches = action(this.collapseAllSearches.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.performAddSearches = action(this.performAddSearches.bind(this));
    this.onAddSearchesClick = action(this.onAddSearchesClick.bind(this));
    this.onMoveSearchesClick = action(this.onMoveSearchesClick.bind(this));
    this.confirmMoveSearches = action(this.confirmMoveSearches.bind(this));
    this.performMoveSearches = action(this.performMoveSearches.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.cancelAddSearchesClick = action(this.cancelAddSearchesClick.bind(this));

    this.applyDefaultSort();
    this.exportRateCardModal.exportOptions.levelType = LEVELS_TYPE.DEFAULT;
  }

  applyDefaultSort() {
    this.jobLabelFilter.sortState.direction = SORT_DIRECTION.ASC;
    this.jobLabelFilter.sort = this.jobLabelFilter.buildQuerySort();
    this.applySort(this.jobLabelFilter.column, this.jobLabelFilter.sort);
  }

  changeCurrencyType(e: SyntheticInputEvent<HTMLInputElement>) {
    this.currencyTypeArray.map((val) => {
      val.selected = false;
      return true;
    });
    e.selected = true;
  }

  changeCurrency() {
    this.currencyTypeArray.map((val) => {
      if (val.selected) {
        this.currencyType = val.value;
      }
      return true;
    });
    this.hideCurrency();
    if (this.currencyType && this.currencyType !== "Default") {
      this.isFiltered = true;
    }
    if (
      Object.keys(this.appliedFilters).length === 0 &&
      this.currencyType &&
      this.currencyType === "Default"
    ) {
      this.isFiltered = false;
    }
    this.pagination.goFetch(null);
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.JOB_LABEL:
        return {
          query: jobLabelsFilterCriteriaQuery,
          variables: {
            rateCardId: this.rateCardId,
          },
        };

      case FILTER_COLUMN.RATE_TYPE:
        return {
          query: rateTypesFilterCriteriaQuery,
          variables: {
            rateCardId: this.rateCardId,
            rateType: "Annual",
          },
        };

      case FILTER_COLUMN.JOB_TITLE:
        return {
          query: jobTitlesFilterCriteriaQuery,
          variables: {
            rateCardId: this.rateCardId,
          },
        };

      case FILTER_COLUMN.CATEGORY:
        return {
          query: categoriesFilterCriteriaQuery,
          variables: {
            rateCardId: this.rateCardId,
          },
        };

      case FILTER_COLUMN.INDUSTRY:
        return {
          query: industriesFilterCriteriaQuery,
          variables: {
            rateCardId: this.rateCardId,
          },
        };

      case FILTER_COLUMN.LOCATION:
        return {
          query: locationsFilterCriteriaQuery,
          variables: {
            rateCardId: this.rateCardId,
          },
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): Array {
    switch (column) {
      case FILTER_COLUMN.JOB_LABEL:
        let nodelValues = payload.data.viewer.rateCard.searchesFilterCriteria.jobLabels;
        let jobLbl = [];
        for (var i = 0; i < nodelValues.length; i++) {
          if (nodelValues[i].jobLabel) jobLbl[i] = nodelValues[i];
        }
        //  const jobLabels: [{ jobLabel: String }] = payload.data.viewer.rateCardSearches.searchesFilterCriteria.jobLabels;
        return addIdToPayload(jobLbl);

      case FILTER_COLUMN.RATE_TYPE:
        //  const rateTypes: [String] = payload.data.viewer.rateCard.searchesFilterCriteria.rateTypes;
        const rateTypes: [String] = ["Hourly", "Annual"];
        return addIdToPayload(rateTypes);

      case FILTER_COLUMN.JOB_TITLE:
        // const jobTitles: [{ jobTitle: String }] = payload.data.viewer.rateCard.searchesFilterCriteria.jobTitles;
        nodelValues = payload.data.viewer.rateCard.searchesFilterCriteria.jobTitles;
        let jobTitles = [];
        for (i = 0; i < nodelValues.length; i++) {
          if (nodelValues[i].jobTitle) jobTitles[i] = nodelValues[i];
        }
        return addIdToPayload(jobTitles);

      case FILTER_COLUMN.CATEGORY:
        const categories: [
          {
            name: String,
            userId: String,
          }
        ] = payload.data.viewer.rateCard.searchesFilterCriteria.jobCategories;

        let processedCategories = observable.map({});
        categories.forEach((category) => {
          processedCategories.set(String(category.userId), {
            id: String(category.userId),
            ...category,
          });
        });

        return processedCategories;

      case FILTER_COLUMN.INDUSTRY:
        nodelValues = payload.data.viewer.rateCardSearches.edges;
        let industries = [];
        for (i = 0; i < nodelValues.length; i++) {
          industries[i] = {
            name: nodelValues[i].node.industry.value,
            userId: nodelValues[i].node.industry.legacyId,
          };
        }
        //  const industries: [{ name: String, userId: String }] =
        //  payload.data.viewer.rateCard.searchesFilterCriteria.industries;

        let processedIndustries = observable.map({});
        industries.forEach((industry) => {
          processedIndustries.set(String(industry.userId), {
            id: String(industry.userId),
            ...industry,
          });
        });

        return processedIndustries;

      case FILTER_COLUMN.LOCATION:
        const regions: [
          {
            region: String,
          }
        ] = payload.data.viewer.rateCard.searchesFilterCriteria.regions;

        const countries: [
          {
            country: String,
          }
        ] = payload.data.viewer.rateCard.searchesFilterCriteria.countries;

        const states: [
          {
            state: String,
          }
        ] = payload.data.viewer.rateCard.searchesFilterCriteria.states;

        const cities: [
          {
            city: String,
          }
        ] = payload.data.viewer.rateCard.searchesFilterCriteria.cities;

        let processedLocations = observable.map({});
        regions.forEach((item, i) => {
          if (item.region)
            processedLocations.set(LOCATION_FILTER_TYPE.REGION + String(i), {
              id: LOCATION_FILTER_TYPE.REGION + String(i),
              type: LOCATION_FILTER_TYPE.REGION,
              location: item.region,
            });
        });

        countries.forEach((item, i) => {
          if (item.country)
            processedLocations.set(LOCATION_FILTER_TYPE.COUNTRY + String(i), {
              id: LOCATION_FILTER_TYPE.COUNTRY + String(i),
              type: LOCATION_FILTER_TYPE.COUNTRY,
              location: item.country,
            });
        });

        states.forEach((item, i) => {
          if (item.state)
            processedLocations.set(LOCATION_FILTER_TYPE.STATE + String(i), {
              id: LOCATION_FILTER_TYPE.STATE + String(i),
              type: LOCATION_FILTER_TYPE.STATE,
              location: item.state,
            });
        });

        cities.forEach((item, i) => {
          if (item.city)
            processedLocations.set(LOCATION_FILTER_TYPE.CITY + String(i), {
              id: LOCATION_FILTER_TYPE.CITY + String(i),
              type: LOCATION_FILTER_TYPE.CITY,
              location: item.city,
            });
        });

        return processedLocations;
      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];
    if (!Object.entries(this.appliedFilters).length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.jobLabelFilter = new JobLabelFilter(
      this,
      FILTER_COLUMN.JOB_LABEL,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.rateTypeFilter = new RateTypeFilter(
      this,
      FILTER_COLUMN.RATE_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );
    this.jobTitleFilter = new JobTitleFilter(
      this,
      FILTER_COLUMN.JOB_TITLE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.categoryFilter = new CategoryFilter(
      this,
      FILTER_COLUMN.CATEGORY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.industryFilter = new IndustryFilter(
      this,
      FILTER_COLUMN.INDUSTRY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.locationFilter = new LocationFilter(
      this,
      FILTER_COLUMN.LOCATION,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.currencyType = "";
    this.currencyTypeArray = [
      { id: "0", selected: true, value: "Default" },
      { id: "1", selected: false, value: "USD" },
    ];
    return this.pagination.goFetch(null);
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showCurrency() {
    this.showCurrencyModal = true;
  }

  hideCurrency() {
    this.showCurrencyModal = false;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.allowExpand = false;
    this.searchesView.forEach((search) => search.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allowExpand = true;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.searchesViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
      viewState.expanded = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.searchesView.forEach((search) => {
      search.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedSearches = [];
    this.searchesView.forEach((search) => {
      search.toggleSelected(e, null, null, true);
    });
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  deselectAllPage(e: Event) {
    this.searchesView.forEach((search) => {
      search.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleSelected(search: Search) {
    const viewState = this.searchesViewState.get(search.id);

    viewState.selected = !viewState.selected;

    if (viewState.selected === false && this.allSelected) {
      this.allSelected = false;
    }

    if (viewState.selected === false) {
      this.unSelectedSearches.push(search.id.toString());
    } else {
      for (var i = 0; i < this.unSelectedSearches.length; i++) {
        if (this.unSelectedSearches[i] === search.id) {
          this.unSelectedSearches.splice(i, 1);
        }
      }
    }

    if (!this.allowMultipleItemSelection) {
      // deselect all other rate cards
      this.searchesViewState.forEach((currentViewState) => {
        if (currentViewState === viewState) return;

        currentViewState.selected = false;
      });
    }
  }

  toggleExpanded(search: Search) {
    search.viewState.expanded = !search.viewState.expanded;
    if (search.viewState.expanded) {
      search.setCurrencyType(this.currencyType);
      search.getSearchResults();
    }
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.searchesViewState.forEach((value) => {
        value.selected = false;
      });
    }
    this.exportRateCardModal.exportOptions.levelType = LEVELS_TYPE.DEFAULT;
  }

  clearAllSelections() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.searchesViewState.forEach((value) => {
      value.selected = false;
    });
  }

  showRenameRateCardModal() {
    this.newRateCardName = this.rateCard.name;
    this.renameRateCardModal.showModal();
  }

  onNewRateCardNameChange(e: Event) {
    this.newRateCardName = e.target.value;
  }

  expandAllSearches() {
    this.searches.forEach((search: Search) => {
      search.viewState.expanded = true;
      search.setCurrencyType(this.currencyType);
      search.getSearchResults();
    });
  }

  collapseAllSearches() {
    this.searches.forEach((search: Search) => (search.viewState.expanded = false));
  }

  getSelectedSearches() {
    const searches = this.searchesViewState;

    let selectedSearches = [];
    this.exportRateCardModal.exportOptions.levelType = LEVELS_TYPE.DEFAULT;

    searches.forEach((value, key) => {
      if (value.selected) {
        selectedSearches.push(key);
      }
    });

    return selectedSearches;
  }

  getUnSelectedSearches() {
    const searches = this.searchesViewState;

    searches.forEach((value, key) => {
      if (!value.selected) {
        this.unSelectedSearches.push(key);
      }
    });

    return this.unSelectedSearches;
  }

  setSharedUserSelectedValue(user: Object) {
    const oldSelectedValue = this.shareUsersViewState.get(user.userId);

    if (oldSelectedValue) {
      this.shareUsersViewState.set(user.userId, !oldSelectedValue);
      user.selected = !oldSelectedValue;
    } else {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    }
  }

  shareUsersOnSelectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, true);
      user.selected = true;
    });
  }

  shareUsersOnDeselectAll(e: Object) {
    this.shareUsersView.forEach((user) => {
      this.shareUsersViewState.set(user.userId, false);
      user.selected = false;
    });
  }

  shareUsersOnInstantSearch(value: string) {
    this.shareUsersInstantSearchValue = value;

    if (!this.shareUsersInstantSearchValue) {
      this.shareUsersView = this.shareUsers;
      return;
    }

    this.shareUsersView = this.shareUsers.filter((user) => {
      const firstName = user.firstName.toLowerCase();
      const lastName = user.lastName.toLowerCase();
      const username = user.username.toLowerCase();
      const query = this.shareUsersInstantSearchValue.toLowerCase();

      // this works because ~ is the binary inverse of a number and ~ -1 = 0
      return (
        ~firstName.indexOf(query) || ~lastName.indexOf(query) || ~username.indexOf(query)
      );
    });
  }

  share() {
    this.getShareUsers();
    this.shareModal.showModal();
  }

  addSearches() {
    this.addSearchesModal.showModal();
    this.addSearchesStore.isEditing = true;
    this.addSearchesStore.pagination.goFetch();
  }

  onAddSearchesClick(searchesView) {
    if (searchesView.length > 0) {
      searchesView.forEach((search) => {
        if (search.viewState.selected) {
          this.updatedSearches.push(String(search.searchId));
        }
      });
    }
    this.performAddSearches();
  }

  cancelAddSearchesClick() {
    this.addSearchesModal.hideModal();
  }

  validateExportRateCardOptions(modalState: ExportOptionsState) {
    if (modalState.exportOptions.fileType === FILE_TYPE.CSV) {
      modalState.disableExportTypeOptions = true;
      modalState.exportOptions.exportType = EXPORT_TYPE.DETAILED;
      modalState.info = "CSV file format will always include rates data.";
    } else {
      modalState.disableExportTypeOptions = false;
      modalState.info = null;
    }
  }

  exportRateCard(options: ExportOptions) {
    if (!this.rateCardId) {
      console.warn("exportRateCard", "rateCardId is null/undefined");
    }

    if (options.fileType === "csv") {
      options.exportType = "basic";
    }

    var exportURL = [
      "ratecards/",
      this.rateCardId,
      "/searches/",
      options.exportType,
      "/list/export/",
      options.fileType,
      "/",
    ].join("");

    var url = exportURL;

    // Change the Filename
    const params = { fileName: options.fileName, section: "admin" };

    this.fetchAPI(url, params)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((e) => {
        console.error("Error downloading excel", e);
        throw e;
      });
  }

  validateExportSearchesOptions(modalState: ExportOptionsState) {
    if (modalState.exportOptions.fileType === FILE_TYPE.CSV) {
      modalState.disableExportTypeOptions = true;
      modalState.exportOptions.exportType = EXPORT_TYPE.DETAILED;
      modalState.info = "CSV file format will always include rates data.";
    } else {
      modalState.disableExportTypeOptions = false;
      modalState.info = null;
    }
  }

  selectRateSearches(type) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};
    let searchCriteria = "";

    if (this.allSelected) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);

      searchCriteria = {
        searchParam: vars,
      };
    } else {
      const selectedRateSearches = this.getSelectedSearches();

      if (!selectedRateSearches) {
        console.error("Cannot " + type + " rate search: No Search selected");
        return;
      }
      searchCriteria = {
        searchId: selectedRateSearches,
      };
    }

    return searchCriteria;
  }

  exportSearches(options: ExportOptions) {
    if (!this.rateCardId) {
      console.warn("exportRateCard", "rateCardId is null/undefined");
    }

    var searchCriteria = this.selectRateSearches("export");

    let parameters = "";

    // Get only the selectedSearches
    if (searchCriteria.searchId !== null && searchCriteria.searchId !== undefined) {
      parameters = `{"only": [${searchCriteria.searchId}]}`;
    } else {
      parameters = JSON.stringify(searchCriteria.searchParam);
    }

    let exportURL = [
      "ratecards/",
      this.rateCardId,
      "/searches/",
      options.exportType,
      "/list/export/",
      options.fileType,
      "/",
    ].join("");

    var url = this.sevenUrl.seven + exportURL;
    // Change the Filename
    var fileName = options.fileName;
    var params;

    if (parameters === "") {
      params = `{"fileName": "${fileName}", "searchFilters": {${parameters}}}`;
    } else {
      params = `{"fileName": "${fileName}", "searchFilters": ${parameters}}`;
    }
    // POST request in JavaScript
    var xhr = new XMLHttpRequest();
    // Pass session id in cookies
    xhr.withCredentials = true;
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        try {
          setTimeout(() => {
            const response = {
              file: JSON.parse(xhr.response).url,
            };
            window.location.href = response.file; // GET request for response returned from the POST request made above
          }, 100);
        } catch (e) {
          console.error("Error downloading excel", e);
          // TODO: Handle errors properly
          throw e; // Prevent success action from running
        }
      }
    };

    xhr.send(params);
    this.handleStopEdit();
  }

  customExportRateCard(options: CustomBuyRatesExportOptions) {
    if (!this.rateCardId) {
      console.warn("customExportRateCard", "rateCardId is null/undefined");
    }

    this.network.loading = true;

    let exportURL = [
      "ratecards/",
      this.rateCardId,
      "/searches/custom/export/",
      options.fileType,
      "/",
    ].join("");

    let variables = {
      fileName: options.fileName,
      markupPercent: options.markupPercent,
      rateFrequencyType: options.rateFrequencyType,
      rateMultiplier: options.rateMultiplier,
      exportFormat: options.fileTemplate,
      searchFilters: {
        exclude: [], // this will include all searches
      },
      convertToUSD: options.currencyType === CURRENCY_TYPE.USD,
    };

    if (options.markupSource === MARKUP_SOURCE.MARKET) {
      variables.useMarkupsFromRateCard = true;
    }
    variables = JSON.stringify(variables);
    //  var params = `{"fileName": "${fileName}"}`;

    var xhr = new XMLHttpRequest();
    //var url = 'http://34.227.27.131/api/ratecards/3/searches/basic/list/export/excel/';
    var url = this.sevenUrl.seven + exportURL;
    xhr.withCredentials = true;
    xhr.open("POST", url, true);
    //xhr.credentials= 'same-origin';
    xhr.setRequestHeader("Content-type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        try {
          setTimeout(() => {
            const response = {
              file: JSON.parse(xhr.response).url,
            };
            window.location.href = response.file;
          }, 100);
        } catch (e) {
          console.error("Error downloading excel", e);
          throw e;
        }
      }
    };

    xhr.send(variables);
  }

  viewRatecardInMap() {
    throw new Error("viewRatecardInMap is not implemented");
  }

  viewSearchesInMap() {
    throw new Error("viewSearchesInMap is not implemented");
  }

  onMoveSearchesClick(event: Event) {
    this.rateCardsListStore.allowMultipleItemSelection = false;
    this.rateCardsListStore.isEditing = true;
    // Filter out this rate card
    const exceptFilter: FilterObject = new FilterObject(
      "$exclude: [ID!]",
      "exclude: $exclude",
      {
        exclude: [this.rateCardId],
      }
    );
    this.rateCardsListStore.applyDefaultFilter(FILTER_COLUMN.EXCLUDE, exceptFilter);
    this.rateCardsListStore.pagination.goFetch();
    this.moveSearchesModal.showModal();
  }

  confirmMoveSearches() {
    const selectedRateCards = this.rateCardsListStore.getSelectedRateCards();
    if (!selectedRateCards || !selectedRateCards.length) return;

    this.moveSearchesToRateCardId = selectedRateCards[0];
    this.confirmMoveSearchesModal.showModal();
  }

  async renameRateCard() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newRateCardName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Rate Card Name.");
      return;
    }

    const query = `
    mutation updateRateCard($rateCardId: ID!, $name: String!){
      updateRateCards(input: {name: $name, rateCardId: $rateCardId}){
      rateCard{
      ratecardId,
      name,
      createdDate,
      updateDate
      }
      }
      }
    `;
    const variables = {
      rateCardId: this.rateCardId,
      name: this.newRateCardName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Rate cards detail", e);
      // TODO: Display user friendly error message
      return;
    }

    this.renameRateCardModal.hideModal();
    this.handleStopEdit();

    runInAction("renameRateCard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async getRateCard(pageQuery: PageQuery): Promise<PaginationInfo> {
    let res = null;
    if (!/^\d+$/.test(this.rateCardId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    if (sortCriteria.length === 0) {
      sortCriteria.push("{field: JOB_LABEL, direction: ASC}"); // default
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");
    variables.rateCardId = this.rateCardId;

    const query = `
      query rateCardSearches (${queryParams}){
       viewer {
        rateCardDetail(id: ${this.rateCardId},section:ADMIN){
          ratecardId,
          name,
          tags{
            name
            tagId
          }
          shared,
          owner{
            firstName,
            lastName,
            userId,
            username,
            email,
            termsOfAgreement
          },
          createdDate,
          updateDate
        },
        user{
          firstName
          lastName
          userId
          username
          email
        }
        rateCardSearches(${queryArgs},id:${this.rateCardId}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
          totalCount
          pageInfo {
           startCursor
           endCursor
         }
         edges{
           node{
             id
             job{
                jobLabel
                jobTitle
                jobDescription
              }
            industry{
              legacyId
              value
            }
            tags{
              name
              tagId
            }
            rateType
            createdDate
            lastUpdated
            country
            city
            state
            locationId
            searchId
            }
          }
        }
       }
      }
    `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting Rate cards detail", e);
      // TODO: Display user friendly error message
      return;
    }

    return runInAction("getRateCard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      if (!res.data.viewer.rateCardDetail) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      if (res == null) {
        return;
      }
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      this.rateCard = new RateCard(this, res.data.viewer.rateCardDetail);
      const searchEdges = res.data.viewer.rateCardSearches.edges;
      const owner = this.rateCard.owner;
      if (owner && owner.clientId) {
        this.hasOwnership = String(owner.clientId) === String(this.currentUser.userId);
      }

      this.searches = searchEdges.map((edge) => {
        const search = new Search(this, edge.node);
        search.needsUpdateBtn = false;
        search.isPtAdmin = true;
        search.lastUpdatedDateDisplay = search.lastUpdated.format("MMM, YYYY");

        if (!this.searchesViewState.has(search.id)) {
          this.searchesViewState.set(search.id, {
            selected: this.allSelected,
            editing: this.isEditing,
            expanded: false,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.searchesViewState.get(search.id).selected;

          this.searchesViewState.set(search.id, {
            selected: selectedValue,
            editing: this.isEditing,
            expanded: false,
          });
        }

        return search;
      });

      let searches = res.data.viewer.rateCardSearches;
      return {
        totalCount: searches.totalCount,
        startCursor: searches.pageInfo.startCursor,
        endCursor: searches.pageInfo.endCursor,
      };
    });
  }

  async getShareUsers() {
    const query = `
    query getShareUsers($rateCardId: Int!, $excludeUsers: [ID]!) {
  viewer {
    legacyUsers(filters: {exclude: $excludeUsers}, order: [{field: FIRST_NAME}, {field: LAST_NAME}]) {
      totalCount
      edges {
        node {
          userId
          username
          firstName
          lastName
        }
        cursor
      }
    }
    currentlySharedWithUsers(rateCardId: $rateCardId, filters: {exclude: $excludeUsers}) {
      edges {
        node {
          userId
          firstName
          lastName
          lastLogin
          username
          email
        }
      }
    }

    rateCardDetail(id: $rateCardId){
      ratecardId
      name
    }
  }
}
    `;

    // exclude current user and rate card owner
    let excludeUsers = [this.currentUser.userId];
    if (this.rateCard.owner.clientId !== this.currentUser.userId)
      excludeUsers.push(this.rateCard.owner.id);

    const variables = {
      rateCardId: this.rateCardId,
      excludeUsers: excludeUsers,
    };

    this.networkShareUsers.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.networkShareUsers.handleError("Getting Users to share", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("getShareUsers--success", () => {
      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;
      if (this.networkShareUsers.logGraphQLError("Get users to share query", res)) {
        // TODO: Display user friendly error message
        // "There was an error retrieving the data for this page. Please reload and try again later."
        return;
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const users = res.data.viewer.legacyUsers.edges;
      const currentlySharedUsers = res.data.viewer.currentlySharedWithUsers.edges;

      this.networkShareUsers.loading = false;
      this.networkShareUsers.error = null;

      // set as selected the users that are currently shared with
      this.shareUsersViewState = observable.map({});
      currentlySharedUsers.forEach((edge) => {
        this.shareUsersViewState.set(edge.node.userId, true);
      });

      // TODO: Deserialize this properly...
      this.shareUsers = users.map((edge) => {
        const user = new User(edge.node);

        if (!user.firstName) user.firstName = "";
        if (!user.lastName) user.lastName = "";

        if (this.shareUsersViewState.has(user.userId)) {
          user.selected = this.shareUsersViewState.get(user.userId);
        } else {
          user.selected = false;
        }

        return user;
      });

      this.shareUsersView = this.shareUsers;
    });
  }

  async performShare() {
    if (this.network.loading) {
      return;
    }
    const selectedUserIds = this.shareUsersViewState
      .entries()
      .filter((entry) => entry[1] === true)
      .map((entry) => entry[0]);

    const query = `
      mutation shareRateCard($userIds: [String]!, $rateCardId: [String]!) {
        shareRateCards(input: { ratecardId:$rateCardId, userId: $userIds }) {

           shareRateCards {
             user{
          firstName,
          lastName,
          username
        },
        rateCard{
          name
        }

           }

         }
      }
    `;
    const variables = {
      userIds: selectedUserIds,
      rateCardId: this.rateCardId,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Sharing Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.shareModal.hideModal();
    this.handleStopEdit();

    runInAction("performShare--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("shareRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async refreshRateCards() {
    if (this.network.loading) {
      return;
    }

    const query = `
      mutation updateMarketRates($rateCardId: String){
        updateMarketRate(input: {rateCardId: $rateCardId, filters: {}}){
          ok
        }
      }
    `;

    const variables = {
      rateCardId: String(this.rateCardId),
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Updating Searches on Rate Cards", e);
      // TODO: Display user friendly error message
      return;
    }

    this.confirmUpdateRatesOnRateCardModal.hideModal();
    this.handleStopEdit();

    runInAction("refreshRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("refreshRateCards", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async undoLastUpdateRateCard() {
    if (this.network.loading) {
      return;
    }

    const query = `
      mutation undoUpdateMarketRates($rateCardId: String){
        undoUpdateRatecardSearchMarketRate(input: {rateCardId: $rateCardId, filters: {}}){
          ok
        }
      }
    `;

    const variables = {
      rateCardId: String(this.rateCardId),
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Undo Last Update", e);
      // TODO: Display user friendly error message
      return;
    }

    this.confirmUndoUpdateRatesOnRateCardModal.hideModal();
    this.handleStopEdit();

    runInAction("undoLastUpdateRateCard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("undoLastUpdateRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async refreshSearches() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};
    //let input = {};

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [String]!");
      params.push("$rateCardId: String!");
      filterargs.push("exclude: $exclude");
      filterargs.push("rateCardId: $rateCardId");
      vars.exclude = this.unSelectedSearches;
      vars.rateCardId = this.rateCardId;
    } else {
      const selectedSearches = this.getSelectedSearches();
      if (!selectedSearches || !selectedSearches.length) {
        console.error("Cannot update searches: No searches selected");
        return;
      }
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = selectedSearches;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
    mutation updateMarketRate(${queryParams}){
      updateMarketRate(input:{${filterargs}, filters: { ${queryArgs} }}) {
        ok
      }
    }
    `;

    this.confirmRefreshSearchesModal.hideModal();
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Updating Rates on Selected Searches", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("refreshSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("updateRatesOnSearches", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async undoLastUpdateSearches() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};
    //let input = {};

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [String]!");
      params.push("$rateCardId: String!");
      filterargs.push("exclude: $exclude");
      filterargs.push("rateCardId: $rateCardId");
      vars.exclude = this.unSelectedSearches;
      vars.rateCardId = this.rateCardId;
    } else {
      const selectedSearches = this.getSelectedSearches();
      if (!selectedSearches || !selectedSearches.length) {
        console.error("Cannot update searches: No searches selected");
        return;
      }
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = selectedSearches;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
    mutation undoLastMarketRate(${queryParams}){
      undoUpdateRatecardSearchMarketRate(input:{${filterargs}, filters: { ${queryArgs} }}) {
        ok
      }
    }
    `;

    this.confirmUndoLastUpdateSearchesModal.hideModal();
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Undo Rates on Selected Searches", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("undoLastUpdateSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("undoUpdateRatesOnSearches", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async deleteRatecard() {
    if (this.network.loading) {
      return;
    }

    let rateCardId = this.rateCardId;

    let params = [];
    let args = [];
    let vars = {};

    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = rateCardId;

    const query = `
    mutation deleteRateCard($only: [ID]){
      deleteRateCards(input: {ratecardId: $only}){
      ok
      }
      }
    `;

    const variables = vars;

    this.confirmDeleteRateCardModal.hideModal();
    this.network.loading = true;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("delete rate card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("deleteRatecard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (this.router) {
        this.router.push({
          pathname: `/ratecards/`,
          query: this.router.query,
        });
      }
    });
  }

  async performAddSearches() {
    if (this.network.loading) {
      return;
    }

    if (this.updatedSearches.length === 0) {
      console.error("Cannot update rateCard: No searches selected");
      return;
    }

    const vars = {
      rateCardId: this.rateCardId,
      only: this.updatedSearches,
    };

    const query = `
      mutation addSearchToRateCard($rateCardId: String!, $only: [String]!) {
        addSearchToRateCard(input: {rateCardId: $rateCardId, only: $only, filters: {}}) {
          searches {
            searchId
          }
        }
      }
    `;

    this.network.loading = true;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("updating rate card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.addSearchesModal.hideModal();
    this.addSearchesStore.clearFilters();
    this.handleStopEdit();

    runInAction("performAddSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("performAddSearches", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async performMoveSearches() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};
    //let input = {};

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [String]!");
      params.push("$rateCardId: String!");
      filterargs.push("exclude: $exclude");
      filterargs.push("rateCardId: $rateCardId");
      vars.exclude = this.unSelectedSearches;
      vars.rateCardId = this.rateCardId;
    } else {
      const selectedSearches = this.getSelectedSearches();
      if (!selectedSearches || !selectedSearches.length) {
        console.error("Cannot update searches: No searches selected");
        return;
      }
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = selectedSearches;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    // $fromRateCardId: String!,
    // fromRateCardId: $fromRateCardId,
    const query = `
    mutation moveSearchToRateCard($toRateCardId: String!, ${queryParams} ){
      moveSearchToDifferentRateCard(input: {toRateCardId: $toRateCardId, ${filterargs}, filters: { ${queryArgs} }}){
        searches{
          searchId
        }
      }
    }
    `;

    // vars.fromRateCardId = this.rateCardId;
    vars.toRateCardId = this.moveSearchesToRateCardId;

    this.confirmUndoLastUpdateSearchesModal.hideModal();
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Moving Searches to Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    this.confirmMoveSearchesModal.hideModal();
    this.moveSearchesModal.hideModal();

    this.moveSearchesToRateCardId = null;
    this.rateCardsListStore.handleStopEdit();
    this.rateCardsListStore.clearFilters();

    runInAction("performMoveSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("moveSearchesToRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async removeSearches() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};
    //let input = {};

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [String]!");
      params.push("$rateCardId: String!");
      filterargs.push("exclude: $exclude");
      filterargs.push("rateCardId: $rateCardId");
      vars.exclude = this.unSelectedSearches;
      vars.rateCardId = this.rateCardId;
    } else {
      const selectedSearches = this.getSelectedSearches();
      if (!selectedSearches || !selectedSearches.length) {
        console.error("Cannot remove searches: No searches selected");
        return;
      }
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = selectedSearches;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
    mutation removeSearchFromRateCard(${queryParams}){
      removeSearchFromRateCard(input:{${filterargs}, filters: { ${queryArgs} }}) {
        searches {
          searchId
        }
      }
    }
    `;

    this.confirmRemoveSearchesModal.hideModal();
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Removing searches on Rate card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();
    this.confirmRemoveSearchesModal.hideModal();

    runInAction("removeSearches--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("removeRateCardSearches", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  toJSON() {
    return {};
  }
}
