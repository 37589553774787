// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import moment from "moment";
import axios from "axios";
import PaginationState from "../../models/PaginationState";
import NetworkState from "../../models/NetworkState";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import CountryFilter from "../../models/FilterState/CountryFilter";
import NegotiationWorksheet from "../../models/NegotiationWorksheet";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import {
  CreatedOnFilter,
  MarkUpOnFilter,
  RegionFilter,
  PayTypeFilter,
  TagsFilter,
} from "../../models/FilterState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "./SupportFunctions";
import ModalState from "../../models/ModalState";
import Sort from "../../models/Sort";
import ApplyTagState from "../../models/ApplyTagState";
import CurrentUser from "../../models/User";
import MessageState from "../../models/MessageState";
import type { FetchGraphQL } from "../../App";

const negotiationWorksheetFilterCriteria = `
query getCountries {
 viewer {
     negotiationWorksheetFilterCriteria {
       countries {
         country
     }
   }
 }
}
`;

const payTypeWorksheetFilterCriteria = `
query getPayTypes {
 viewer {
     negotiationWorksheetFilterCriteria {
      payTypes
   }
 }
}
`;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: NEGOTIATION_WORKSHEET){
       tag
     }
   }
 }
}
`;

export class NegotiationWorksheetListComponentStore {
  fetchGraphQL: FetchGraphQL;
  showHelpModal: boolean;
  tagsViewState: Object;
  getWorksheets: (PageQuery) => Promise<PaginationInfo>;
  network: NetworkState;
  pagination: PaginationState;
  totalCount: any;
  negotiationList: NegotiationWorksheet[];
  negotiationListView: NegotiationWorksheet[];
  negotiationListViewState: Object;
  createdOnFilter: CreatedOnFilter;
  markUpOnFilter: MarkUpOnFilter;
  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  isFiltered: boolean;
  appliedSortsOrder: Array<FilterColumn>;
  showHelp: () => void;
  hideHelp: () => void;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  clearFilters: () => void;
  isEditing: ?boolean;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  clearAllSelections: () => void;
  allSelected: boolean;
  allOnPageSelected: boolean;
  allowMultipleItemSelection: boolean;
  confirmDeleteModal: ModalState;
  helpModal: ModalState;
  getSelectednegotiationList: () => Array<string>;
  getSelectedTagList: () => Array<number>;
  countryFilter: CountryFilter;
  tagsFilter: TagsFilter;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  payTypeFilter: PayTypeFilter;
  negotiationFilter: RegionFilter;
  applyDefaultSort: () => void;
  unSelectedNegotiation: [];
  tagModal: ModalState;
  showTagModal: () => void;
  applyTagState: ApplyTagState;
  isTagView: ?boolean;
  setTagViewFn: () => void;
  CurrentUser: CurrentUser;
  selectAllOnPageItem: () => void;
  showRenameWorksheetModal: () => void;
  onNewWorksheetNameChange: () => void;
  newWorksheetName: string;
  getFirstSelectedNegotiationWorksheet: () => void;
  messaging: MessageState;
  renameWorksheetModal: ModalState;
  clearAllSelectionsOnTagView: () => void;
  toDateRange: () => void;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getWorksheets = action(this.getWorksheets.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));
    this.unSelectedNegotiation = [];

    extendObservable(this, {
      helpModal: new ModalState(),
      network: new NetworkState(),
      tagNetwork: new NetworkState(),
      pagination: new PaginationState(this.getWorksheets),
      totalCount: 0,
      negotiationList: [],
      negotiationListViewState: observable.map({}),
      negotiationListView: computed(() => {
        return this.negotiationList.map((region) => {
          if (this.negotiationListViewState.has(region.regionId)) {
            region.viewState = this.negotiationListViewState.get(region.regionId);

            return region;
          }

          return region;
        });
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.negotiationListView.map(
          (negotiationListView) => negotiationListView.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      appliedSortsOrder: observable.shallow([]),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.WORKSHEET_DATE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      markUpOnFilter: new MarkUpOnFilter(
        this,
        FILTER_COLUMN.MARKUP_AMT,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      negotiationFilter: new RegionFilter(
        this,
        FILTER_COLUMN.REGION_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      countryFilter: new CountryFilter(
        this,
        FILTER_COLUMN.WORKSHEET_COUNTRY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      payTypeFilter: new PayTypeFilter(
        this,
        FILTER_COLUMN.PAY_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      isFiltered: false,
      isEditing: null,
      allowMultipleItemSelection: true,
      selectedCount: computed(() => {
        const selectedValues = this.negotiationListView.map(
          (worksheet) => worksheet.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allSelected: false,
      allSelectedfilter: false,
      confirmDeleteModal: new ModalState(),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
      isTagView: null,
      newWorksheetName: "",
      messaging: new MessageState(),
      renameWorksheetModal: new ModalState(),
    });
    this.CurrentUser = null;
    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectednegotiationList = action(this.getSelectednegotiationList.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.showRenameWorksheetModal = action(this.showRenameWorksheetModal.bind(this));
    this.onNewWorksheetNameChange = action(this.onNewWorksheetNameChange.bind(this));
    this.getFirstSelectedNegotiationWorksheet = action(
      this.getFirstSelectedNegotiationWorksheet.bind(this)
    );
    this.clearAllSelectionsOnTagView = action(
      this.clearAllSelectionsOnTagView.bind(this)
    );
    this.applyDefaultSort();
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  showRenameWorksheetModal() {
    this.messaging.removeAll();
    let worksheet = this.getFirstSelectedNegotiationWorksheet();
    if (!worksheet) {
      return;
    }
    this.newWorksheetName = worksheet.worksheetName;
    this.renameWorksheetModal.showModal();
  }

  onNewWorksheetNameChange(e) {
    this.newWorksheetName = e.target.value;
  }

  applyDefaultSort() {
    // deprecated. default sort is applied in getWorksheets
    // this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    // this.createdOnFilter.sortState.field = "CREATE_DATE";
    // this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    // this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  handleStartEdit() {
    this.isEditing = true;
    this.negotiationListView.forEach((region) => region.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.negotiationListViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.negotiationListView.forEach((region) => {
      region.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedNegotiation = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.negotiationListView.forEach((region) => {
      region.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.negotiationListView.forEach((region) => {
      region.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  clearAllSelections() {
    this.isTagView = false;
    //  this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.negotiationListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  clearAllSelectionsOnTagView() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.negotiationListViewState.forEach((value) => {
      value.selected = false;
    });
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts[column] = sort;

    const index = this.appliedSortsOrder.indexOf(column);
    if (index === -1) this.appliedSortsOrder.push(column);
  }

  removeSort(column: FilterColumn) {
    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  //clear all filters
  clearFilters() {
    this.countryFilter = new CountryFilter(
      this,
      FILTER_COLUMN.WORKSHEET_COUNTRY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.negotiationFilter = new RegionFilter(
      this,
      FILTER_COLUMN.REGION_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.WORKSHEET_DATE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.markUpOnFilter = new MarkUpOnFilter(
      this,
      FILTER_COLUMN.MARKUP_AMT,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.payTypeFilter = new PayTypeFilter(
      this,
      FILTER_COLUMN.PAY_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.isFiltered = false;
    this.applyDefaultSort();

    return this.pagination.goFetch(null);
  }

  //creating country filter query
  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.WORKSHEET_COUNTRY:
        return {
          query: negotiationWorksheetFilterCriteria,
          variables: {},
        };

      case FILTER_COLUMN.PAY_TYPE:
        return {
          query: payTypeWorksheetFilterCriteria,
          variables: {},
        };

      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      default:
        return null;
    }
  }

  //process country filter result data
  processFilterCriteria(column: FilterColumn, payload: Object): Array {
    switch (column) {
      case FILTER_COLUMN.WORKSHEET_COUNTRY:
        const countries: [
          {
            country: string,
          }
        ] = payload.data.viewer.negotiationWorksheetFilterCriteria.countries;
        let processedCountry = observable.map({});
        countries.forEach((country, index) => {
          processedCountry.set(String(index), {
            id: String(index),
            ...country,
          });
        });
        return processedCountry;

      case FILTER_COLUMN.PAY_TYPE:
        const payTypes: [String] =
          payload.data.viewer.negotiationWorksheetFilterCriteria.payTypes;
        return addIdToPayload(payTypes);

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      default:
        return null;
    }
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.projectCostsView.forEach((value) => {
        value.viewState.selected = false;
      });
    }
  }

  getSelectednegotiationList(): Array<string> {
    const negotiationList = this.negotiationListViewState;

    let selectednegotiationList = [];

    negotiationList.forEach((value, key) => {
      if (value.selected) {
        selectednegotiationList.push(key);
      }
    });

    return selectednegotiationList;
  }

  getFirstSelectedNegotiationWorksheet() {
    for (let j = 0; j < this.negotiationListView.length; j++) {
      if (this.negotiationListView[j].viewState.selected)
        return this.negotiationListView[j];
    }

    return null;
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  //function for get worksheet list
  async getWorksheets(pageQuery: PageQuery): Promise<PaginationInfo> {
    let res = null;
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];
    let sortCriteria: string[] = [];

    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    if (sortCriteria.length === 0) {
      // default sort
      // sortCriteria.push("{field: CREATE_DATE, direction: DESC, caseSensitive: true}");
      //sortCriteria.push("{field: NAME, direction: ASC}");
    }

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
      query worksheetlist(${queryParams}) {
        viewer {
          user {
            firstName
            lastName
            userId
            username
            email
          }
          negotiationWorksheets(${queryArgs},filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
            totalCount
            edges {
              node {
                worksheetId
                worksheetName
                created
                totalMarkupPercent
                payType
                country {
                  name
                }
                tags {
                  name
                  tagId
                }
              }
            }
          }
        }
      }
      `;

    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Worksheets", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Worksheets query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getWorksheets--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get worksheet query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      const worksheets = res.data.viewer.negotiationWorksheets.edges;
      this.totalCount = res.data.viewer.negotiationWorksheets.totalCount;
      // // TODO: Deserialize this properly...
      this.negotiationList = worksheets.map((work) => {
        const worksheet = new NegotiationWorksheet(this, work.node);

        if (!this.negotiationListViewState.has(worksheet.worksheetId)) {
          this.negotiationListViewState.set(worksheet.worksheetId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.negotiationListViewState.get(worksheet.worksheetId).selected;

          this.negotiationListViewState.set(worksheet.worksheetId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        worksheet.viewState = this.negotiationListViewState.get(worksheet.worksheetId);

        return worksheet;
      });
      return {
        totalCount: res.data.viewer.negotiationWorksheets.totalCount,
      };
    });
  }
}

export default class NegotiationWorksheetListStore extends NegotiationWorksheetListComponentStore {
  deleteWorksheets: () => void;
  selectWorksheets: () => void;
  applyTags: () => void;
  deleteSingleTags: () => void;
  renameNegotiationWorksheet: () => void;

  constructor(fetchGraphQL: FetchGraphQL) {
    super(fetchGraphQL);

    this.deleteWorksheets = action(this.deleteWorksheets.bind(this));
    this.selectWorksheets = action(this.selectWorksheets.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.renameNegotiationWorksheet = action(this.renameNegotiationWorksheet.bind(this));
  }

  selectWorksheets(type) {
    if (this.network.loading) {
      return;
    }
    let searchCriteria = "";

    //if (this.allSelected) {
    // consolidateAppliedFilters(this.appliedFilters, params, args, vars);
    // searchCriteria = {
    //   searchParam: vars
    // }

    // } else {
    const selectednegotiationList = this.getSelectednegotiationList();

    if (!selectednegotiationList) {
      console.error("Cannot " + type + " jobs: No Worksheets selected");
      return;
    }
    searchCriteria = {
      worksheetId: selectednegotiationList,
    };
    // }

    return searchCriteria;
  }

  async deleteWorksheets() {
    // var searchCriteria = this.selectWorksheets("delete");
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [String]");
      filterargs.push("exclude: $exclude");
      vars.exclude = this.unSelectedNegotiation;
      vars.exclude = vars.exclude.filter(function (item, pos) {
        return vars.exclude.indexOf(item) === pos;
      });
    } else {
      const selectedNegotiationList = this.getSelectednegotiationList();
      if (!selectedNegotiationList || !selectedNegotiationList.length) {
        console.error("Cannot delete Worksheets: No Worksheet selected");
        return;
      }
      params.push("$worksheetIds: [Int]!");
      filterargs.push("worksheetIds: $worksheetIds");
      vars.worksheetIds = selectedNegotiationList;
    }

    if (this.negotiationFilter.textToLookFor) {
      params.push("$search: String");
      searchargs.push("search:$search");
      vars.search = this.negotiationFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation deleteWorksheet(${queryParams}){
       deleteNegotiationWorksheet(input:{${filterargs}, filters: { ${queryArgs} }, ${searchargs}}) {
         ok
       }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }
    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }
    this.handleStopEdit();

    runInAction("deleteWorksheets--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteWorksheets", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async applyTags() {
    //var searchCriteria = this.selectWorksheets("delete");
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Worksheets: No Worksheet selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = this.unSelectedNegotiation;
      // vars.exclude = vars.exclude.filter(function(item, pos) {
      //   return vars.exclude.indexOf(item) == pos;
      // })
    } else {
      const selectedNegotiationList = this.getSelectednegotiationList();
      if (!selectedNegotiationList || !selectedNegotiationList.length) {
        console.error("Cannot apply tage Worksheets: No Worksheet selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = selectedNegotiationList;
    }

    if (this.negotiationFilter.textToLookFor) {
      params.push("$nameIContains: String");
      args.push("nameIContains:$nameIContains");
      vars.nameIContains = this.negotiationFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToNegotiationWorksheets(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    this.handleStopEdit();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.applyTagState.tagModal.hideModal();
      this.pagination.goFetch(null);
    });
  }

  async deleteSingleTags(content) {
    if (this.network.loading) {
      return;
    }

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :NEGOTIATION_WORKSHEET,contentId:${content.contentId}}){
        ok
        }
      }
    `;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, null);
    } catch (e) {
      this.network.handleError("Deleting selected Tag", e);
      // TODO: Display user friendly error message
      return;
    }

    //this.handleStopEdit();

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    runInAction("deleteSingleTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      //this.pagination.goFetch(null);
    });
    return {
      result: res.data.removeTagsFromContent.ok,
    };
  }

  async renameNegotiationWorksheet() {
    if (this.network.loading) {
      return;
    }

    this.messaging.removeAll();

    if (!this.newWorksheetName.trim()) {
      this.messaging.createMessage("info", "Please enter a New Worksheet Name.");
      return;
    }

    const query = `
      mutation renameNegotiationWorksheet($worksheetId: Int!, $name: String!){
        renameNegotiationWorksheet(input: {name: $name, worksheetId: $worksheetId}){
          worksheet{
            worksheetId,
            worksheetName
          }
          errors {
            __typename
            ... on NameAlreadyExistError {
              message
            }
            ... on NameEmptyError {
              message
            }
         }
        }
      }
    `;
    const variables = {
      worksheetId: this.getSelectednegotiationList()[0],
      name: this.newWorksheetName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Getting worksheet detail", e);
      // TODO: Display user friendly error message
      return;
    }

    if (res.errors) {
      console.error("Errors", res.errors);
      this.network.loading = false;
      return;
    }

    // this.handleStopEdit();

    runInAction("renameNegotiationWorksheet--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (res.data.renameNegotiationWorksheet.errors) {
        this.messaging.removeAll();
        this.messaging.createMessage(
          "error",
          res.data.renameNegotiationWorksheet.errors[0].message
        );
        return;
      }
      if (this.network.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      //this.negDetail.worksheetName = res.data.renameNegotiationWorksheet.worksheet.worksheetName;
      this.handleStopEdit();
      this.pagination.goFetch(null);
      this.renameWorksheetModal.hideModal();
    });
  }
}
