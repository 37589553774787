// @flow

import R from "ramda";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import axios from "axios";
import moment from "moment";
import SavedSearch from "../../models/SavedSearch";
import RateCard from "../../models/RateCard";
import CurrentUser from "../../models/User";
import LocationFilter, {
  LOCATION_FILTER_TYPE,
} from "../../models/FilterState/LocationFilter";
import IndustryFilter from "../../models/FilterState/IndustryFilter";
import {
  RateTypeFilter,
  JobLabelFilter,
  JobTitleFilter,
  RateCardLabelFilter,
  DraftFilter,
  CreatedOnFilter,
  JobFilter,
  TagsFilter,
} from "../../models/FilterState";
import NetworkState from "../../models/NetworkState";
import PaginationState from "../../models/PaginationState";
import FilterObject, { FILTER_COLUMN } from "../../models/Filter";
import Sort, { SORT_DIRECTION } from "../../models/Sort";
import ModalState from "../../models/ModalState";
import {
  addIdToPayload,
  consolidateAppliedFilters,
  consolidateAppliedSorts,
} from "./SupportFunctions";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import { RateCardListComponentStore } from "./RateCardListStore";
import type { FilterColumn } from "../../models/Filter";
import type { GraphQLQuery } from "../../models/GraphQL";
import ExportOptionsState from "../../models/ExportOptionsState";
import ExportOptions, { FILE_TYPE, EXPORT_TYPE } from "../../models/ExportOptions";
import RateCardListStore from "./RateCardListStore";
import Search from "../../models/Search";
import ApplyTagState from "../../models/ApplyTagState";
import type { FetchGraphQL, FetchAPI } from "../../App";

const rateTypesCriteriaQuery = `
query {
  viewer {
    rateCardsFilterCriteria {
      rateTypes
    }
  }
}
`;

const jobTitlesFilterCriteriaQuery = `
query{
  viewer{
    savedsearches{
      searchFilterCriteria{
        jobTitles(limit: 10000) {
          jobTitle
        }
      }
    }
  }
}
`;

const locationsFilterCriteriaQuery = `
query{
  viewer{
    savedsearches{
      searchFilterCriteria{
        countries {
          country
        }
        cities {
          city
        }
        states {
          state
        }
        regions {
          region
        }
      }
    }
  }
}
`;

const jobLabelsFilterCriteriaQuery = `
query{
  viewer{
    savedsearches{
      searchFilterCriteria{
        jobLabels(limit: 10000) {
          jobLabel
        }
      }
    }
  }
}
`;

const industriesFilterCriteriaQuery = `
query{
  viewer{
    savedsearches{
      searchFilterCriteria{
        industries {
          legacyId
          name
        }
      }
    }
  }
}
`;

const tagsFilterCriteria = `
query getTags {
 viewer{
   tagsFilterCriteria{
     tagnames(contentType: SAVED_SEARCHES){
       tag
     }
   }
 }
}
`;

// type Owner = { userId: string, username: string };
// class User {
//   id: string;
//   userId: string;
//   username: string;
//   firstName: string;
//   lastName: string;
//   selected: string;

//   constructor(object: Object) {
//     this.id = object.userId;
//     this.userId = object.userId;
//     this.username = object.username;
//     this.firstName = object.firstName;
//     this.lastName = object.lastName;

//     extendObservable(this, {
//       selected: object.selected,
//     })
//   }
// }

export class SavedSearchesListComponentStore {
  network: NetworkState;
  networkSavedSearchDetail: NetworkState;
  pagination: PaginationState;

  rateCards: SavedSearch[];
  allRateCards: RateCard[];
  rateCardsViewState: Object;
  rateCardsView: SavedSearch[];
  savedSearchCount: any;

  currentUser: CurrentUser;
  isEditing: ?boolean;
  allOnPageSelected: boolean;
  allSelected: boolean;
  allSelectedfilter: boolean;
  draftSearch: boolean;
  allowMultipleItemSelection: boolean;
  rateCardsListStore: RateCardListComponentStore;

  industryFilter: IndustryFilter;
  jobLabelFilter: JobLabelFilter;
  rateTypeFilter: RateTypeFilter;
  jobFilter: JobTitleFilter;
  rateCardLabelFilter: RateCardLabelFilter;
  locationFilter: LocationFilter;
  draftFilter: DraftFilter;
  createdOnFilter: CreatedOnFilter;
  tagsFilter: TagsFilter;
  rateCardsStore: RateCardListStore;

  defaultFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedFilters: {
    [key: FilterColumn]: FilterObject,
  };
  appliedSorts: {
    [key: FilterColumn]: Sort,
  };
  appliedSortsOrder: Array<FilterColumn>;
  isFiltered: boolean;
  textToLookFor: String;
  rateCardSearchText: {};
  jobSearchFilter: JobFilter;
  unSelectedSearches: Array<any>;

  getSavedSearch: (PageQuery) => Promise<PaginationInfo>;
  getAllRateCardsList: () => void;
  savedSearchDetail: () => void;
  applyDefaultFilter: (FilterColumn, FilterObject) => void;
  handleStartEdit: () => void;
  handleStopEdit: () => void;
  toggleSelectAllPage: (Object) => void;
  selectAllPage: (Event) => void;
  deselectAllPage: (Event) => void;
  toggleAllItems: () => void;
  clearAllSelections: () => void;
  getSelectedRateCards: () => Array<string>;
  getFirstSelectedRateCard: () => Object;
  toDateRange: () => void;
  getFilterCriteriaQuery: (FilterColumn) => GraphQLQuery;
  processFilterCriteria: (FilterColumn, Object) => Array<Object>;
  applyFilter: (FilterColumn, FilterObject) => void;
  removeFilter: (FilterColumn) => void;
  applySort: (FilterColumn, Sort) => void;
  removeSort: (FilterColumn) => void;
  filterRateCards: () => void;
  clearFilters: () => void;
  applyDefaultSort: () => void;
  searchRateCardChange: () => void;
  searchRateApply: () => void;
  searches: Search;
  applyTagState: ApplyTagState;
  applyTagStateDetail: ApplyTagState;
  setTagViewFn: () => void;
  isTagView: ?boolean;
  selectAllOnPageItem: () => void;
  savedSearchTags: Array<any>;
  selectedTags: Object;
  clearAllSelectionsOnTagView: () => void;
  fetchGraphQL: FetchGraphQL;

  constructor(fetchGraphQL: FetchGraphQL) {
    this.fetchGraphQL = fetchGraphQL;
    this.getSavedSearch = action(this.getSavedSearch.bind(this));
    this.getAllRateCardsList = action(this.getAllRateCardsList.bind(this));
    this.savedSearchDetail = action(this.savedSearchDetail.bind(this));
    this.getFilterCriteriaQuery = action(this.getFilterCriteriaQuery.bind(this));
    this.processFilterCriteria = action(this.processFilterCriteria.bind(this));
    this.applyFilter = action(this.applyFilter.bind(this));
    this.applySort = action(this.applySort.bind(this));
    this.removeFilter = action(this.removeFilter.bind(this));
    this.removeSort = action(this.removeSort.bind(this));
    this.searchRateCardChange = action(this.searchRateCardChange.bind(this));
    this.searchRateApply = action(this.searchRateApply.bind(this));
    this.toDateRange = action(this.toDateRange.bind(this));

    extendObservable(this, {
      network: new NetworkState(),
      networkSavedSearchDetail: new NetworkState(),
      pagination: new PaginationState(this.getSavedSearch),
      rateCardsListStore: new RateCardListComponentStore(fetchGraphQL),
      rateCards: [],
      allRateCards: [],
      savedSearchTags: [],
      savedSearchCount: 0,
      savedsearchdetaildata: [],
      draftSearch: false,
      rateCardsViewState: observable.map({}),
      rateCardsStore: new RateCardListStore(fetchGraphQL),
      rateCardsView: computed(() => {
        return this.rateCards.map((rateCard) => {
          if (this.rateCardsViewState.has(rateCard.searchId)) {
            rateCard.viewState = this.rateCardsViewState.get(rateCard.searchId);

            return rateCard;
          }

          return rateCard;
        });
      }),
      isEditing: null, // we start with null so some view elements be hidden initially
      isFiltered: false,
      allSelected: false,
      allSelectedfilter: false,
      allowMultipleItemSelection: true,
      unSelectedSearches: [],
      selectedCount: computed(() => {
        const selectedValues = this.rateCardsView.map(
          (rateCard) => rateCard.viewState.selected
        );

        if (this.allSelected) {
          return this.pagination.totalCount;
        }

        let count = 0;

        selectedValues.forEach((v) => {
          if (v) {
            count += 1;
          }
        });

        return count;
      }),
      allOnPageSelected: computed(() => {
        const allTrue = R.all(R.equals(true));
        const selectedValues = this.rateCardsView.map(
          (rateCard) => rateCard.viewState.selected
        );

        if (selectedValues.length === 0) {
          return false;
        }

        return allTrue(selectedValues);
      }),
      defaultFilters: {},
      appliedFilters: {},
      appliedSorts: {},
      appliedSortsOrder: observable.shallow([]),
      searches: {},
      rateTypeFilter: new RateTypeFilter(
        this,
        FILTER_COLUMN.RATE_TYPE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.removeFilter
      ),

      jobLabelFilter: new JobLabelFilter(
        this,
        FILTER_COLUMN.JOB_LABEL,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      jobFilter: new JobTitleFilter(
        this,
        FILTER_COLUMN.JOB_TITLE,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      rateCardLabelFilter: new RateCardLabelFilter(
        this,
        FILTER_COLUMN.RATE_CARD_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      locationFilter: new LocationFilter(
        this,
        FILTER_COLUMN.LOCATION,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      draftFilter: new DraftFilter(
        this,
        FILTER_COLUMN.DRAFT,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      createdOnFilter: new CreatedOnFilter(
        this,
        FILTER_COLUMN.DATE_RANGE,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      industryFilter: new IndustryFilter(
        this,
        FILTER_COLUMN.INDUSTRY,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      jobSearchFilter: new JobFilter(
        this,
        FILTER_COLUMN.JOB_LABEL,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      applyTagState: new ApplyTagState(fetchGraphQL, this),
      applyTagStateDetail: new ApplyTagState(fetchGraphQL, this),
      tagsFilter: new TagsFilter(
        this,
        FILTER_COLUMN.TAGS,
        this.getFilterCriteriaQuery,
        this.processFilterCriteria,
        this.applyFilter,
        this.applySort,
        this.removeFilter,
        this.removeSort
      ),
      isTagView: null,
    });

    this.currentUser = null;

    this.applyDefaultFilter = action(this.applyDefaultFilter.bind(this));
    this.handleStartEdit = action(this.handleStartEdit.bind(this));
    this.handleStopEdit = action(this.handleStopEdit.bind(this));
    this.toggleSelectAllPage = action(this.toggleSelectAllPage.bind(this));
    this.selectAllPage = action(this.selectAllPage.bind(this));
    this.deselectAllPage = action(this.deselectAllPage.bind(this));
    this.toggleAllItems = action(this.toggleAllItems.bind(this));
    this.clearAllSelections = action(this.clearAllSelections.bind(this));
    this.getSelectedRateCards = action(this.getSelectedRateCards.bind(this));
    this.getFirstSelectedRateCard = action(this.getFirstSelectedRateCard.bind(this));

    this.filterRateCards = action(this.filterRateCards.bind(this));
    this.clearFilters = action(this.clearFilters.bind(this));
    this.applyDefaultSort = action(this.applyDefaultSort.bind(this));
    this.setTagViewFn = action(this.setTagViewFn.bind(this));
    this.selectAllOnPageItem = action(this.selectAllOnPageItem.bind(this));
    this.clearAllSelectionsOnTagView = action(
      this.clearAllSelectionsOnTagView.bind(this)
    );

    this.applyDefaultSort();
  }

  setTagViewFn() {
    this.isTagView = true;
    this.isEditing = true;
  }

  searchRateCardChange(val) {
    this.textToLookFor = val;
  }
  searchRateApply(searchTxt) {
    this.rateCardSearchText = {
      first: 10,
      nameFilterText: "Ayush",
      offset: 1,
    };
    this.getAllRateCardsList();
  }

  applyDefaultSort() {
    this.createdOnFilter.sortState.direction = SORT_DIRECTION.DESC;
    this.createdOnFilter.sort = this.createdOnFilter.buildQuerySort();
    this.applySort(this.createdOnFilter.column, this.createdOnFilter.sort);
  }

  handleStartEdit() {
    this.isEditing = true;
    this.rateCardsView.forEach((rateCard) => rateCard.toggleEdit());
  }

  handleStopEdit() {
    this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((viewState) => {
      viewState.selected = false;
      viewState.editing = false;
    });
  }

  toggleSelectAllPage(e: Object) {
    if (!this.allowMultipleItemSelection) return;

    const setValue = !this.allOnPageSelected;

    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, setValue);
    });

    // When All items selected flag is up, clear selection
    if (setValue === false && this.allSelected) this.allSelected = false;
  }

  selectAllPage(e: Event) {
    this.unSelectedSearches = [];
    this.allSelected = true;
    this.allSelectedfilter = true;
  }

  selectAllOnPageItem(e: Event) {
    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, true);
    });
  }

  deselectAllPage(e: Event) {
    this.rateCardsView.forEach((rateCard) => {
      rateCard.toggleSelected(e, null, null, false);
    });

    this.allSelected = false;
    this.allSelectedfilter = false;
  }

  toggleAllItems() {
    if (!this.allowMultipleItemSelection) return;

    this.allSelected = !this.allSelected;

    if (this.allSelected === false) {
      this.rateCardsViewState.forEach((value) => {
        value.selected = false;
      });
    }
  }

  clearAllSelections() {
    this.isTagView = false;
    //  this.isEditing = false;
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  clearAllSelectionsOnTagView() {
    this.allSelected = false;
    this.allSelectedfilter = false;
    this.rateCardsViewState.forEach((value) => {
      value.selected = false;
    });
  }

  getFilterCriteriaQuery(column: FilterColumn): GraphQLQuery {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        return {
          query: rateTypesCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.JOB_LABEL:
        return {
          query: jobLabelsFilterCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.JOB_TITLE:
        return {
          query: jobTitlesFilterCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.LOCATION:
        return {
          query: locationsFilterCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.INDUSTRY:
        return {
          query: industriesFilterCriteriaQuery,
          variables: {},
        };

      case FILTER_COLUMN.TAGS:
        return {
          query: tagsFilterCriteria,
          variables: {},
        };

      default:
        return null;
    }
  }

  processFilterCriteria(column: FilterColumn, payload: Object): ?Array<Object> {
    switch (column) {
      case FILTER_COLUMN.RATE_TYPE:
        const rateTypes: [String] = payload.data.viewer.rateCardsFilterCriteria.rateTypes;
        return addIdToPayload(rateTypes);

      case FILTER_COLUMN.JOB_LABEL:
        const jobLabels: [{ jobLabel: String }] =
          payload.data.viewer.savedsearches.searchFilterCriteria.jobLabels;
        return addIdToPayload(jobLabels);

      case FILTER_COLUMN.JOB_TITLE:
        const jobTitles: [{ jobTitle: String }] =
          payload.data.viewer.savedsearches.searchFilterCriteria.jobTitles;
        return addIdToPayload(jobTitles);

      case FILTER_COLUMN.LOCATION:
        const regions: [
          {
            region: String,
          }
        ] = payload.data.viewer.savedsearches.searchFilterCriteria.regions;

        const countries: [
          {
            country: String,
          }
        ] = payload.data.viewer.savedsearches.searchFilterCriteria.countries;

        const states: [
          {
            state: String,
          }
        ] = payload.data.viewer.savedsearches.searchFilterCriteria.states;

        const cities: [
          {
            city: String,
          }
        ] = payload.data.viewer.savedsearches.searchFilterCriteria.cities;

        let processedLocations = observable.map({});
        regions.forEach((item, i) => {
          if (item.region)
            processedLocations.set(LOCATION_FILTER_TYPE.REGION + String(i), {
              id: LOCATION_FILTER_TYPE.REGION + String(i),
              type: LOCATION_FILTER_TYPE.REGION,
              location: item.region,
            });
        });

        countries.forEach((item, i) => {
          if (item.country)
            processedLocations.set(LOCATION_FILTER_TYPE.COUNTRY + String(i), {
              id: LOCATION_FILTER_TYPE.COUNTRY + String(i),
              type: LOCATION_FILTER_TYPE.COUNTRY,
              location: item.country,
            });
        });

        states.forEach((item, i) => {
          if (item.state)
            processedLocations.set(LOCATION_FILTER_TYPE.STATE + String(i), {
              id: LOCATION_FILTER_TYPE.STATE + String(i),
              type: LOCATION_FILTER_TYPE.STATE,
              location: item.state,
            });
        });

        cities.forEach((item, i) => {
          if (item.city)
            processedLocations.set(LOCATION_FILTER_TYPE.CITY + String(i), {
              id: LOCATION_FILTER_TYPE.CITY + String(i),
              type: LOCATION_FILTER_TYPE.CITY,
              location: item.city,
            });
        });

        return processedLocations;

      case FILTER_COLUMN.INDUSTRY:
        const industries: [{ name: String, legacyId: String }] =
          payload.data.viewer.savedsearches.searchFilterCriteria.industries;
        let processedIndustries = observable.map({});
        industries.forEach((industry) => {
          processedIndustries.set(String(industry.legacyId), {
            id: String(industry.legacyId),
            ...industry,
          });
        });

        return processedIndustries;

      case FILTER_COLUMN.CREATED_BY:
        const owners: [
          {
            userId: string,
            username: string,
          }
        ] = payload.data.viewer.authors.edges;
        let processedOwners = observable.map({});
        owners.forEach((owner) => {
          processedOwners.set(String(owner.node.userId), {
            id: String(owner.node.userId),
            ...owner,
          });
        });

        return processedOwners;

      case FILTER_COLUMN.TAGS:
        const tags: [
          {
            tag: string,
          }
        ] = payload.data.viewer.tagsFilterCriteria.tagnames;
        let processedTag = observable.map({});
        tags.forEach((tag, index) => {
          processedTag.set(String(index), {
            id: String(index),
            ...tag,
          });
        });
        return processedTag;

      default:
        return null;
    }
  }

  applyFilter(column: FilterColumn, filter: FilterObject) {
    this.appliedFilters[column] = filter;
    this.isFiltered = true;
  }

  applyDefaultFilter(column: FilterColumn, filter: FilterObject) {
    this.defaultFilters[column] = filter;
  }

  removeFilter(column: FilterColumn) {
    delete this.appliedFilters[column];

    let entries = Object.entries(this.appliedFilters);
    if (!entries.length) this.isFiltered = false;
  }

  applySort(column: FilterColumn, sort: Sort) {
    this.appliedSorts = observable({ [column]: sort });
    this.appliedSortsOrder = [column];

    if (
      column !== this.createdOnFilter.column ||
      (column === this.createdOnFilter.column &&
        this.createdOnFilter.sortState.direction !== SORT_DIRECTION.DESC)
    ) {
      this.isFiltered = true; // Only way to trigger "clear filter and sort"
    }
  }

  removeSort(column: FilterColumn) {
    // this.appliedSorts = observable({});
    // this.appliedSortsOrder = [];
    // this.applyDefaultSort();

    delete this.appliedSorts[column];

    const index = this.appliedSortsOrder.indexOf(column);
    if (index > -1) this.appliedSortsOrder.splice(index, 1);
  }

  clearFilters() {
    this.jobSearchFilter = new JobFilter(
      this,
      FILTER_COLUMN.JOB_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.rateTypeFilter = new RateTypeFilter(
      this,
      FILTER_COLUMN.RATE_TYPE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.removeFilter
    );
    this.jobLabelFilter = new JobLabelFilter(
      this,
      FILTER_COLUMN.JOB_LABEL,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.jobFilter = new JobTitleFilter(
      this,
      FILTER_COLUMN.JOB_TITLE,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.rateCardLabelFilter = new RateCardLabelFilter(
      this,
      FILTER_COLUMN.RATE_CARD_LABEL,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.locationFilter = new LocationFilter(
      this,
      FILTER_COLUMN.LOCATION,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.draftFilter = new DraftFilter(
      this,
      FILTER_COLUMN.DRAFT,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.createdOnFilter = new CreatedOnFilter(
      this,
      FILTER_COLUMN.DATE_RANGE,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.industryFilter = new IndustryFilter(
      this,
      FILTER_COLUMN.INDUSTRY,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );
    this.tagsFilter = new TagsFilter(
      this,
      FILTER_COLUMN.TAGS,
      this.getFilterCriteriaQuery,
      this.processFilterCriteria,
      this.applyFilter,
      this.applySort,
      this.removeFilter,
      this.removeSort
    );

    this.appliedFilters = observable({});
    this.appliedSorts = observable({});
    this.appliedSortsOrder.length = 0;
    this.applyDefaultSort();
    this.isFiltered = false;

    return this.pagination.goFetch(null);
  }

  filterRateCards() {
    return this.pagination.goFetch(null);
  }

  getSelectedRateCards(): Array<string> {
    const rateCards = this.rateCardsViewState;

    let selectedRateCards = [];

    rateCards.forEach((value, key) => {
      if (value.selected) {
        selectedRateCards.push(key);
      }
    });

    return selectedRateCards;
  }

  getFirstSelectedRateCard() {
    for (let j = 0; j < this.rateCardsView.length; j++) {
      if (this.rateCards[j].viewState.selected) return this.rateCards[j];
    }

    return null;
  }

  async getAllRateCardsList() {
    let variables;
    let filtersCriteria: string[] = [];

    consolidateAppliedFilters(this.appliedFilters, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, filtersCriteria, variables);
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const query = `
    query rateCardList {
        viewer {
          user{
            firstName
            lastName
            userId
            username
            email
          }
          allRateCards(filters: { ${queryFiltersCriteria} }){
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
            edges {
              node {
                tags{
                  name
                  tagId
                }
                ratecardId
                owner {
                  firstName
                  lastName
                  email
                  termsOfAgreement
                }
                name
                createdDate
                updateDate
              }
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      // this.network.handleError("Getting Rate Cards", e);
      // if (res !== null) {
      //   this.network.logGraphQLError("Get Rate Card query", res);
      // }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getAllRateCardsList--success", () => {
      // debugger
      // if (this.network.logGraphQLError("Get Rate Cards query", res)) {
      //   // TODO: Display user friendly error message
      //   return { totalCount: 0, startCursor: "", endCursor: "" };
      // }
      // // this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.allRateCards.edges;

      // // TODO: Deserialize this properly...
      this.currentUser = new CurrentUser(this, res.data.viewer.user);
      this.allRateCards = cards.map((card) => {
        const rateCard = new RateCard(this, card.node);

        // rateCard.searches = card.node.searches.totalCount;
        // rateCard.locations = card.node.locations;

        return rateCard;
      });

      return {
        totalCount: res.data.viewer.allRateCards.totalCount,
        startCursor: res.data.viewer.allRateCards.pageInfo.startCursor,
        endCursor: res.data.viewer.allRateCards.pageInfo.endCursor,
      };
    });
  }

  async getSavedSearch(pageQuery: PageQuery): Promise<PaginationInfo> {
    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = [];
    consolidateAppliedSorts(this.appliedSorts, sortCriteria);

    consolidateAppliedFilters(this.appliedFilters, params, filtersCriteria, variables);

    // NOTE: applied filters could override default filters, handle this if needed
    consolidateAppliedFilters(this.defaultFilters, params, filtersCriteria, variables);

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
    query savedSearches (${queryParams}) {
      viewer {
        user{
            firstName
            lastName
            userId
            username
            email
          }
        savedsearches(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
            edges {
              node {
                searchId
                isGlobalSupplierSearch
                workerTypeName
                tags{
                  name
                  tagId
                }
                job {
                  jobLabel
                  jobTitle
                }
                currency{
                  symbol
                }
              city
              country
              state
              region {
                id
                name
              }
              rateType
              marketRate {
                # these are level III AVG values
                payRate
                billRate
                markupPct
              }
              marketchanges {
                level {
                  legacyId
                  value
                }
                payRateAvg
                markupPctAvg
                billRateAvg
              }
              createdDate
              rates {
                rawBillRates
                perDiem
                rawMin
                rawAvg
                rawMid
                rawMax
              }
            }
          }
          totalCount
          }
        }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getSavedSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return {
          totalCount: 0,
        };
      }
      this.currentUser = new CurrentUser(this, res.data.viewer.user);

      // // $FlowFixMe: Type the res value once we abstract gql calls.
      const cards = res.data.viewer.savedsearches.edges;

      // // TODO: Deserialize this properly...
      this.savedSearchCount = res.data.viewer.savedsearches.totalCount;
      this.rateCards = cards.map((card) => {
        const rateCard = new SavedSearch(this, card.node);

        // rateCard.searches = card.node.searches.totalCount;
        // rateCard.locations = card.node.locations;
        if (!this.rateCardsViewState.has(rateCard.searchId)) {
          this.rateCardsViewState.set(rateCard.searchId, {
            selected: this.allSelected,
            editing: this.isEditing,
          });
        } else {
          const selectedValue = this.allSelected
            ? true
            : this.rateCardsViewState.get(rateCard.searchId).selected;

          this.rateCardsViewState.set(rateCard.searchId, {
            selected: selectedValue,
            editing: this.isEditing,
          });
        }

        rateCard.viewState = this.rateCardsViewState.get(rateCard.searchId);

        return rateCard;
      });

      return {
        totalCount: res.data.viewer.savedsearches.totalCount,
      };
    });
  }

  async savedSearchDetail(searchId) {
    let res = null;
    if (!/^\d+$/.test(searchId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }

    const variables = {
      searchId: searchId,
    };

    const query = `
      query saveSearchDetail ($searchId: Int!){
        viewer {
          savedsearch(id: $searchId) {
            searchId
            isDraft
            tags{
               name
               tagId
             }
            currency {
              symbol
            }
            job {
              jobLabel
              jobTitle
            }
            industry{
                legacyId
                value
            }
            city
            rateType
            country
            state
            region {
              id
              name
            }
            buyrates {
              buyrateId
              name
              notes
              punchouts {
                level{
                  legacyId
                  value
                }
                payRateMin
                payRateMax
                billRateMin
                billRateMax
                markupPct
              }
            }
            marketRate {
              payRate
              billRate
              markupPct
            }
            rates {
              markupPct {
                min
                max
                avg
                mid
              }
              billRate {
                min
                max
                avg
                mid
              }
              markupAmt {
                min
                max
                avg
                mid
              }
              payRate {
                min
                max
                avg
                mid
              }
            }
            ratesData
            createdDate
            createdBy{
                firstName,
                lastName,
                email
            }
          }
        }
      }
      `;

    this.networkSavedSearchDetail.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.networkSavedSearchDetail.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.networkSavedSearchDetail.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("savedSearchDetail--success", () => {
      // this.pagination.goFetch()
      this.networkSavedSearchDetail.loading = false;
      this.networkSavedSearchDetail.error = null;
      if (res.data.viewer && !res.data.viewer.savedsearch) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return;
      }
      if (
        this.networkSavedSearchDetail.logGraphQLError(
          "Get Saved Search Details query",
          res
        )
      ) {
        return {};
      }

      this.selectedTags = observable.map({});
      res.data.viewer.savedsearch.tags.forEach((item) => {
        this.selectedTags.set(item.tagId, item);
      });
      this.savedSearchDetailData = res.data.viewer.savedsearch;
      this.savedSearchTags = res.data.viewer.savedsearch.tags;
      this.draftSearch = res.data.viewer.savedsearch.isDraft;
      // const savedSearchDetailData = new SavedSearchDetailData(this, data);
      // debugger
      this.searches = new Search(this, res.data.viewer.savedsearch);
      this.searches.getSearchResults();
    });

    return {
      res: res.data.viewer.savedsearch,
    };
  }
}

export default class SavedSearchesListStore extends SavedSearchesListComponentStore {
  showHelpModal: boolean;
  newRateCardName: string;

  helpModal: ModalState;
  confirmUpdateRatesModal: ModalState;
  confirmUndoUpdateRatesModal: ModalState;
  confirmDeleteModal: ModalState;
  addToRateCardModal: ModalState;
  renameRateCardModal: ModalState;
  networkShareUsers: NetworkState;

  exportModalState: ExportOptionsState;
  textToLookFor: String;

  showHelp: () => void;
  hideHelp: () => void;
  showRenameRateCardModal: () => void;
  renameRateCard: () => void;
  onNewRateCardNameChange: (Event) => void;
  deleteRateCards: () => void;
  refreshRateCards: () => void;
  exportRateCards: (options: ExportOptions) => void;
  validateExportOptions: (modalState: ExportOptionsState) => void;
  viewInMap: () => void;
  addSavedSearchToRateCard: () => void;
  applyTags: () => void;
  deleteSingleTags: () => void;
  applyTagsToDetail: () => void;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  apiServerURL: string;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, apiServerURL: string) {
    super(fetchGraphQL);

    this.fetchAPI = fetchAPI;

    // Bound early to pass into export state
    this.exportRateCards = action(this.exportRateCards.bind(this));
    this.validateExportOptions = action(this.validateExportOptions.bind(this));

    extendObservable(this, {
      apiServerURL,
      newRateCardName: "",
      helpModal: new ModalState(),
      renameRateCardModal: new ModalState(),
      confirmUpdateRatesModal: new ModalState(),
      confirmUndoUpdateRatesModal: new ModalState(),
      confirmDeleteModal: new ModalState(),
      addToRateCardModal: new ModalState(),
      exportModal: new ExportOptionsState(
        this.exportRateCards,
        this.validateExportOptions
      ),
      textToLookFor: "",
    });

    this.showHelp = action(this.showHelp.bind(this));
    this.hideHelp = action(this.hideHelp.bind(this));
    this.showRenameRateCardModal = action(this.showRenameRateCardModal.bind(this));
    this.renameRateCard = action(this.renameRateCard.bind(this));
    this.onNewRateCardNameChange = action(this.onNewRateCardNameChange.bind(this));

    this.deleteRateCards = action(this.deleteRateCards.bind(this));
    this.refreshRateCards = action(this.refreshRateCards.bind(this));
    this.undoLastUpdateRateCards = action(this.undoLastUpdateRateCards.bind(this));
    this.viewInMap = action(this.viewInMap.bind(this));
    this.addSavedSearchToRateCard = action(this.addSavedSearchToRateCard.bind(this));
    this.onSearchApply = action(this.onSearchApply.bind(this));
    this.applyTags = action(this.applyTags.bind(this));
    this.deleteSingleTags = action(this.deleteSingleTags.bind(this));
    this.applyTagsToDetail = action(this.applyTagsToDetail.bind(this));
  }

  onSearchApply() {
    // console.log(this.allRateCards);
  }

  showHelp() {
    this.showHelpModal = true;
  }

  hideHelp() {
    this.showHelpModal = false;
  }

  showRenameRateCardModal() {
    const rateCard = this.getFirstSelectedRateCard();
    if (!rateCard) return;

    this.newRateCardName = rateCard.name;
    this.renameRateCardModal.showModal();
  }

  onNewRateCardNameChange(e: Event) {
    this.newRateCardName = e.target.value;
  }

  validateExportOptions(modalState: ExportOptionsState) {
    if (modalState.exportOptions.fileType === FILE_TYPE.CSV) {
      modalState.disableExportTypeOptions = true;
      modalState.exportOptions.exportType = EXPORT_TYPE.DETAILED;
      modalState.info = "CSV file format will always include rates data.";
    } else {
      modalState.disableExportTypeOptions = false;
      modalState.info = null;
    }
  }

  exportRateCards(options: ExportOptions) {
    const selectedRateCards = this.getSelectedRateCards();

    if (selectedRateCards.length === 0) {
      return;
    }

    let exportURL: string = "";

    let variables: Object = {
      fileName: options.fileName,
    };

    if (selectedRateCards.length > 1) {
      exportURL = "ratecards/list/export/excel/";
      variables.rateCardFilters = {
        only: selectedRateCards,
      };
    } else {
      // if selectedRateCards.length === 1
      exportURL = [
        "ratecards/",
        selectedRateCards[0],
        "/searches/",
        options.exportType,
        "/list/export/",
        options.fileType,
        "/",
      ].join("");

      variables.searchFilters = {
        exclude: [], // this will include all searches
      };
    }

    this.network.loading = true;

    this.fetchAPI(exportURL, variables)
      .then((res) => res.json())
      .then(
        (data) => {
          window.location = this.apiServerURL + data.relative_url;
          this.network.loading = false;
          this.handleStopEdit();
        },
        (err) => {
          this.network.handleError("Exporting Rate Cards", err);
          // TODO: Display user friendly error message
        }
      );
  }

  viewInMap() {
    throw new Error("viewInMap not implemented");
  }

  async renameRateCard() {
    if (this.network.loading) {
      return;
    }

    const selectedRateCards = this.getSelectedRateCards();
    if (selectedRateCards.length !== 1) {
      console.error("One and only one Rate Card must be selected");
      return;
    }

    const query = `
    mutation updateRateCard($rateCardId: ID!, $name: String!){
      updateRateCards(input: {name: $name, rateCardId: $rateCardId}){
      rateCard{
      ratecardId,
      name,
      createdDate,
      updateDate
      }
      }
      }
    `;
    const variables = {
      rateCardId: selectedRateCards[0],
      name: this.newRateCardName,
    };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      this.network.handleError("Renaming Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.renameRateCardModal.hideModal();
    this.handleStopEdit();

    runInAction("renameRateCard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("editRateCardName", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async refreshRateCards() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (this.allSelected) {
      params.push("$exclude: [ID]!");
      args.push("exclude: $exclude");
      vars.exclude = []; // no exclusions means all will be processed if there are no other filters

      // include current filters
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
    } else {
      const selectedRateCards = this.getSelectedRateCards();
      if (!selectedRateCards || !selectedRateCards.length) {
        console.error("Cannot update Rate Cards: No Rate Cards selected");
        return;
      }

      params.push("$only: [ID]!");
      args.push("only: $only");
      vars.only = selectedRateCards;
    }

    const query = `
      mutation UpdateRatesOnRateCards(${params.join(", ")}){
        updateRatesOnRateCards(input: {
            clientMutationId: "",
            rateCardFilters: {
              ${args.join(", ")}
            }
          }) {

          success {
            message
          }

          errors {
            __typename
            ...on RateCardNotFoundError {
              message
            }
          }
        }
      }
    `;
    this.confirmUpdateRatesModal.hideModal();
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Updating Rates on Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("refreshRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("updateRatesOnRateCards", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  toDateRange(date) {
    var val = true;
    if (this.createdOnFilter.fromDate) {
      val = !date.isBetween(
        this.createdOnFilter.fromDate,
        moment(new Date()),
        "days",
        "[]"
      );
    }
    return val;
  }

  async undoLastUpdateRateCards() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let args = [];
    let vars = {};

    if (this.allSelected) {
      params.push("$exclude: [ID]!");
      args.push("exclude: $exclude");
      vars.exclude = []; // no exclusions means all will be processed if there are no other filters

      // include current filters
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
    } else {
      const selectedRateCards = this.getSelectedRateCards();
      if (!selectedRateCards || !selectedRateCards.length) {
        console.error("Cannot update Rate Cards: No Rate Cards selected");
        return;
      }

      params.push("$only: [ID]!");
      args.push("only: $only");
      vars.only = selectedRateCards;
    }

    const query = `
      mutation UndoUpdateRatesOnRateCards(${params.join(", ")}){
        undoUpdateRatesOnRateCards(input: {
            clientMutationId: "",
            rateCardFilters: {
              ${args.join(", ")}
            }
          }) {

          success {
            message
          }

          errors {
            __typename
            ...on RateCardNotFoundError {
              message
            }
          }
        }
      }
    `;

    this.confirmUndoUpdateRatesModal.hideModal();
    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Undo last Update Rates on Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("undoLastUpdateRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("undoUpdateRatesOnRateCards", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async addSavedSearchToRateCard(savedSearchId) {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};
    let selectedRateCards = [];

    const selRateCards = this.rateCardsListStore.getSelectedRateCards();
    if (!selRateCards || !selRateCards.length) return;

    if (savedSearchId) {
      selectedRateCards.push(savedSearchId);
      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = selectedRateCards;
    } else {
      if (this.allSelectedfilter) {
        consolidateAppliedFilters(this.appliedFilters, params, args, vars);
        params.push("$exclude: [String]");
        filterargs.push("exclude: $exclude");
        vars.exclude = this.unSelectedSearches;
        vars.exclude = vars.exclude.filter(function (item, pos) {
          return vars.exclude.indexOf(item) === pos;
        });
      } else {
        selectedRateCards = this.getSelectedRateCards();
        if (!selectedRateCards || !selectedRateCards.length) {
          console.error("Cannot Add Searches: No Search selected");
          selectedRateCards = [];
          return;
        }
        params.push("$only: [String]!");
        filterargs.push("only: $only");
        vars.only = selectedRateCards;
      }
    }

    vars.existingRateCardId = selRateCards[0];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation saveSearchRateCardExisting($existingRateCardId: String, ${queryParams}) {
        saveSearchesToRateCard(input: {${filterargs}, filters: { ${queryArgs} }, existingRatecard: $existingRateCardId}) {
          searches {
            searchId
            state
            city
            createdDate
            industry {
              value
            }
          }
        }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Adding searches to Rate Card", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("addSavedSearchToRateCard--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.draftSearch = false;
      // window.location.reload();
      this.pagination.goFetch(null);
      // this.savedSearchDetail(res.data.saveSearchToRateCard.searches[0].searchId);
    });
  }

  async deleteRateCards() {
    if (this.network.loading) {
      return;
    }

    let params = [];
    let filterargs = [];
    let args = [];
    let vars = {};
    let selectedRateCards = [];

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [String]");
      filterargs.push("exclude: $exclude");
      vars.exclude = this.unSelectedSearches;
      vars.exclude = vars.exclude.filter(function (item, pos) {
        return vars.exclude.indexOf(item) === pos;
      });
    } else {
      selectedRateCards = this.getSelectedRateCards();
      if (!selectedRateCards) selectedRateCards = [];

      params.push("$only: [String]!");
      filterargs.push("only: $only");
      vars.only = selectedRateCards;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
    mutation deleteRateCardSearch(${queryParams}){
         deleteRateCardSearch(input: {${filterargs}, filters: { ${queryArgs} }}){
           ok
         }
       }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Deleting selected Rate Cards", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("deleteRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteRateCard", res)) {
        // TODO: Display user friendly error message
        return;
      }

      this.pagination.goFetch(null);
    });
  }

  async applyTags() {
    //var searchCriteria = this.selectWorksheets("delete");
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagState.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Saveed Search: No Saveed Search selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;

    if (this.allSelectedfilter) {
      consolidateAppliedFilters(this.appliedFilters, params, args, vars);
      params.push("$exclude: [ID]");
      args.push("exclude: $exclude");
      vars.exclude = [];
      // vars.exclude = vars.exclude.filter(function(item, pos) {
      //   return vars.exclude.indexOf(item) == pos;
      // })
    } else {
      const itemList = this.getSelectedRateCards();
      if (!itemList || !itemList.length) {
        console.error("Cannot apply tage Saveed Search: No Saveed Search selected");
        return;
      }
      params.push("$only: [ID]");
      args.push("only: $only");
      vars.only = itemList;
    }

    if (this.rateCardLabelFilter.textToLookFor) {
      params.push("$nameIContains: String");
      args.push("nameIContains:$nameIContains");
      vars.nameIContains = this.rateCardLabelFilter.textToLookFor;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToSearches(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Saveed Search", e);
      // TODO: Display user friendly error message
      return;
    }

    this.handleStopEdit();

    runInAction("applyTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("applyTags", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.applyTagState.tagModal.hideModal();
      this.pagination.goFetch(null);
    });
  }

  async applyTagsToDetail() {
    // let searchquery = '';
    // let parameters = {};

    let params = [];
    let filterargs = [];
    let searchargs = [];
    let args = [];
    let vars = {};

    const taglist = this.applyTagStateDetail.getSelectedTagList();
    if (!taglist || !taglist.length) {
      console.error("Cannot Apply tags to Worksheets: No Worksheet selected");
      return;
    }
    params.push("$tagIds: [Int]!");
    filterargs.push("tagIds: $tagIds");
    vars.tagIds = taglist;
    params.push("$only: [ID]");
    args.push("only: $only");
    vars.only = [this.searches.id];

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");

    const query = `
      mutation applyTags(${queryParams}){
       applyTagsToSearches(input:{${filterargs}, filters: { ${queryArgs}}, ${searchargs}}) {
         ok
          errors {
           __typename
           ... on TagIdRequiredError {
             message
           }
           ... on TagIdsNotExistsError {
             message
           }
           ... on InvalidInputError {
             message
           }
           ... on ContentDoesNotExistsError {
             message
           }
         }
       }

      }
    `;

    this.network.loading = true;

    try {
      await this.fetchGraphQL(query, vars);
    } catch (e) {
      this.network.handleError("Apply Tags to Selected Worksheets", e);
      // TODO: Display user friendly error message
      return;
    }

    runInAction("applyTagsToDetail--success", () => {
      this.applyTagStateDetail.tagModal.hideModal();
      this.savedSearchDetail(this.searches.id);
      //window.location.reload();
    });
  }

  async deleteSingleTags(content) {
    if (this.network.loading) {
      return;
    }

    const query = `
    mutation removeTagsFromContent{
      removeTagsFromContent(input: {tagIds : [${content.tagId}],contentType :SAVED_SEARCHES,contentId:${content.contentId}}){
        ok
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, {});
    } catch (e) {
      this.network.handleError("Deleting selected Tag", e);
      // TODO: Display user friendly error message
      return;
    }

    //this.handleStopEdit();

    runInAction("deleteSingleTags--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("deleteTag", res)) {
        // TODO: Display user friendly error message
        return;
      }

      //this.pagination.goFetch(null);
    });
    return {
      result: res.data.removeTagsFromContent.ok,
    };
  }
}
