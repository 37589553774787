// @flow

import React from "react";
import type { Element } from "react";
import { extendObservable, action, runInAction, computed, observable } from "mobx";
import debounce from "lodash.debounce";
import NetworkState from "../../models/NetworkState";
import { RATE_TYPE_OPTIONS_2 } from "../../constants/rateTypes";
import { WizardState } from "../../components/wizards/Wizard";
import JobLibrary from "../../models/JobLibrary";
import RawJobTitle, { BatchSearchCreateLabelData } from "../../models/RawJobTitle";
import Industry from "../../models/Industry";
import Location, { LOCATION_TYPE } from "../../models/LocationList";
import Region from "../../models/RegionList";
import RateCard from "../../models/RateCard";
import type { PageQuery, PaginationInfo } from "../../models/PaginationState";
import PaginationState from "../../models/PaginationState";
import ModalState from "../../models/ModalState";
import type { FetchAPI, FetchGraphQL } from "../../App";
import type MobXStore from "./MobXStore";
import { createTasteGraphQLWrapper } from "./SupportFunctions";
import { RATE_TYPE } from "../../constants/search";
// import { delayRandomly, throwRandomly } from "../../utils/random";
// import { DjangoApiPaginatedQueryArgs } from "../../models/Django";

// POST /_api/sara/batch-search/
// {
//   "rate_card_label": "00001 - Saved Search Test Blah Blah",
//   "rate_type": 1,
//   "jobs": [
//     {
//       "job_label": "3D Designer 2",
//       "job_title": "3D Designer",
//       "job_category": "10-1-13",
//       "original_title_id": 79466,
//       "job_description": "",
//       "industry_id": 1,
//       "location_ids": [
//         94044
//       ],
//       "region_ids": [],
//       "gss_location_ids": []
//     }
//   ]
// }

// DELETE /_api/sara/batch-search/70bfafe6-0988-4885-8d0b-bbd74a9a3461/

const INDIA_COUNTRY_ID = "103";
const INDIA_COUNTRY_ID_REGIONS_TABLE = "841";

const GSS_COUNTRY_IDS = [INDIA_COUNTRY_ID];
const GSS_COUNTRY_IDS_FOR_REGIONS = [INDIA_COUNTRY_ID_REGIONS_TABLE];

export const RATE_CARD_ACTION = {
  CREATE: 1,
  USE_EXISTING: 2,
  USE_PREVIOUS: 3, // previously used rate card
};

const _compareStrings = (s1: string, s2: string, insensitive: boolean = true): number => {
  const a = insensitive ? s1.toLowerCase() : s1;
  const b = insensitive ? s2.toLowerCase() : s2;
  return a < b ? -1 : a > b ? 1 : 0;
};

class DataError {
  message: string;
  data: Object;

  constructor(message: string, data: Object) {
    this.message = message;
    this.data = data;
  }
}

class RerunError {
  message: string;
  name: string;

  constructor(name: string, message: string) {
    this.message = message;
    this.name = name;
  }
}

class Tag {
  tagId: number;
  name: string;
  // selected: boolean;

  constructor(object: Object) {
    this.tagId = object.tagId;
    this.name = object.name;

    // extendObservable(this, {
    //   selected: object.selected
    // });
  }
}

export type Option = {
  label: string,
  value: number,
};

export type WorkerType = {
  id: number,
  name: string,
  description: string,
};

export class ItemFilter {
  id: string;
  properties: string[];
  value: any;
  name: string;

  constructor(properties: string[], value: any, name: string) {
    this.id = encodeURIComponent(value);
    this.properties = properties;
    this.value = value;
    this.name = name;
  }

  apply = (item: Object) => {
    if (!item) return false;

    for (let i = 0; i < this.properties.length; i++) {
      const currentProperty = this.properties[i];
      if (!item.hasOwnProperty(currentProperty)) continue;

      if (item[currentProperty] === this.value) return true;
    }

    // all properties tested false
    return false;
  };

  toString = () => {
    return this.name;
  };
}

export class ContainsItemFilter extends ItemFilter {
  apply = (item: Object) => {
    if (!item) return false;

    for (let i = 0; i < this.properties.length; i++) {
      const currentProperty = this.properties[i];
      if (!item.hasOwnProperty(currentProperty)) continue;

      const propValue = item[currentProperty].toLowerCase();
      const query = this.value.toLowerCase();
      const found = propValue.includes(query);

      if (found) return true;
    }

    // all properties tested false
    return false;
  };
}

export class BatchSearchJob {
  id: string;
  workerType: ?WorkerType;
  jobTitle: RawJobTitle;
  industry: Industry;
  locations: Location[];
  gssLocations: Location[]; // gss: Global Supplier Search
  regions: Region[];
  tags: Tag[];

  addLocation: (Location, boolean) => boolean;
  addRegion: (Region) => boolean;
  removeLocation: (Location, boolean) => void;
  removeRegion: (Region) => void;
  toJsonObject: () => Object;

  constructor(
    jobTitle: RawJobTitle,
    industry: Industry,
    locations: Location[],
    gssLocations: Location[],
    regions: Region[],
    workerType: ?WorkerType = null,
    tags: ?Array<Tag> = null
  ) {
    const isGSS = gssLocations && gssLocations.length > 0;
    const isRegion = regions && regions.length > 0;
    extendObservable(this, {
      id: BatchSearchJob.getId(jobTitle.id, industry.id, workerType?.id, isGSS, isRegion),
      jobTitle: jobTitle,
      industry: industry,
      locations: locations,
      gssLocations: gssLocations,
      regions: regions,
      workerType: workerType,
      tags: tags,
    });
  }

  static getId = (
    titleId: string | number,
    industryId: string | number,
    workerTypeId?: string | number | null,
    isGSS?: boolean,
    isRegion?: boolean
  ): string => {
    return encodeURIComponent(
      `t_${titleId}-i_${industryId}-wt_${workerTypeId || "none"}-gss_${
        isGSS ? "yes" : "no"
      }-region_${isRegion ? "yes" : "no"}`
    );
  };

  addLocation(location: Location, isGSS: boolean): boolean {
    const locations = isGSS ? this.gssLocations : this.locations;
    const exists = locations.find((item: Location) => item.id === location.id);
    if (!exists) {
      locations.push(location);
      const sortedLocations = locations.sort((loc1, loc2) => {
        return _compareStrings(loc1.toString(), loc2.toString());
      });

      if (isGSS) this.gssLocations = sortedLocations;
      else this.locations = sortedLocations;
    }

    return !exists;
  }

  addRegion(region: Region): boolean {
    const exists = this.regions.find((item: Region) => item.id === region.id);
    if (!exists) {
      this.regions.push(region);
      this.regions = this.regions.sort((reg1, reg2) => {
        return _compareStrings(reg1.toString(), reg2.toString());
      });
    }

    return !exists;
  }

  removeLocation(location: Location, isGSS: boolean): void {
    if (isGSS) {
      this.gssLocations = this.gssLocations.filter((item) => item.id !== location.id);
    } else {
      this.locations = this.locations.filter((item) => item.id !== location.id);
    }
  }

  removeRegion(region: Region): void {
    this.regions = this.regions.filter((item) => item.id !== region.id);
  }

  toJsonObject(isGSS = false): Object {
    const { labelData, libraryInfo, jobLabelCategory } = this.jobTitle;
    const jobLabel = labelData ? labelData.jobLabel : this.jobTitle.title;
    // NOTE: Hack for SARA. SARA requires a job description to run the batch search
    // so we set a space for empty descriptions.
    const jobDescription =
      (labelData ? labelData.description : this.jobTitle.description) || " ";
    const jobCategory =
      (libraryInfo ? libraryInfo.category : jobLabelCategory) || "Uncategorized";

    const json = {
      jobLabel: jobLabel,
      jobTitle: this.jobTitle.title,
      jobCategory: jobCategory,
      originalTitleId: this.jobTitle.id,
      jobDescription: jobDescription,
      industryId: this.industry.id,
      workerTypeId: this.workerType?.id,
      locationIds: this.locations.map((item) => item.id),
      gssLocationIds: this.gssLocations.map((item) => item.id),
      tagIds: this.tags?.map((tag) => tag.tagId),
      regionIds: [],
      gssRegionIds: [],
    };

    if (isGSS) json.gssRegionIds = this.regions.map((item) => String(item.id));
    else json.regionIds = this.regions.map((item) => item.id);

    return json;
  }
}

export class BatchSearchJobListItem {
  store: Object;
  id: string;
  title: string;
  titleId: number;
  searchedTitle: string;
  category: string;
  industry: string;
  countryCode: string;
  location: string;
  workerType: string;
  isGSS: boolean;
  tags: string;
  batchSearchJob: BatchSearchJob;
  batchSearchJobLocation: Location | Region;
  viewState: {
    selected: boolean,
  };

  constructor(
    store: Object,
    job: BatchSearchJob,
    location: Location | Region,
    isGSS: boolean
  ) {
    this.store = store;
    const workerTypeName: string = job.workerType ? job.workerType.name || "" : "";
    const workerTypeId: string | number = job.workerType ? job.workerType.id : "none";
    const itemId = BatchSearchJobListItem.getId(
      job.jobTitle.id,
      job.industry.id,
      location.id,
      isGSS,
      workerTypeId
    );
    const industry: string = job.industry.name ? job.industry.name : "";
    const countryCode: string = location.countryCode ? location.countryCode : "";

    if (!this.store.jobsToSearchItemsViewState.has(itemId)) {
      this.store.jobsToSearchItemsViewState.set(itemId, { selected: false });
    }

    extendObservable(this, {
      id: itemId,
      title: job.jobTitle.labelData
        ? job.jobTitle.labelData.jobLabel
        : job.jobTitle.title,
      titleId: job.jobTitle.id,
      searchedTitle: job.jobTitle.libraryInfo
        ? job.jobTitle.libraryInfo.mappedJobTitleName
        : job.jobTitle.title,
      category: job.jobTitle.libraryInfo
        ? job.jobTitle.libraryInfo.category
        : "Uncategorized",
      industry: industry,
      workerType: workerTypeName,
      countryCode: countryCode,
      location: location.toString(),
      isGSS: isGSS,
      tags: job.tags ? job.tags.map((t) => t.name).join(", ") : "",
      batchSearchJob: job,
      batchSearchJobLocation: location,
      viewState: this.store.jobsToSearchItemsViewState.get(itemId),
    });
  }

  static getId = (
    titleId: string | number,
    industryId: string | number,
    locationId: string | number,
    isGSS: boolean,
    workerTypeId: string | number | null = null
  ): string => {
    return encodeURIComponent(
      `t_${titleId}-i_${industryId}-l_${locationId}-gss_${isGSS.toString()}-wt_${
        workerTypeId || "none"
      }`
    );
  };

  setSelected = (value: boolean = true) => {
    this.viewState.selected = value;
  };
}

type Router = {
  push: (any) => void,
  goBack: () => void,
};

type InvalidJobData = {
  industryId: string,
  invalidCountryId: string,
  invalidLocationId: string,
  invalidWorkerTypeId?: string | null,
  jobOriginalTitleId: string,
};

export class BatchSearchJLCreateStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  network: NetworkState;
  wizard: WizardState;
  router: ?Router;
  errorModal: ModalState;
  errorMessage: ?Element<any>;
  invalidJobsErrorModal: ModalState;
  infoModal: ModalState;
  infoMessage: ?Element<any>;
  addTitleWizard: WizardState;

  // rate type
  selectedRateType: ?Option;
  rateTypes: Option[];
  onRateTypeChange: (Option) => void;

  // userCountries
  userCountriesNetwork: NetworkState; // need separate network state to load in parallel
  userCountries: Location[];
  selectedUserCountry: ?Location;
  userCountrySearch: string;
  onlyCountryIds: ?Array<number>;
  onUserCountrySearchChange: (string) => void;
  onSelectedUserCountryChange: (?Location) => void;
  getUserCountries: () => Promise<any>;
  resetUserCountries: () => void;

  // libraries
  librariesNetwork: NetworkState; // need separate network state to load in parallel
  clientLibraries: JobLibrary[];
  selectedLibrary: ?JobLibrary;
  librarySearch: string;
  onLibrarySearchChange: (string) => void;
  onSelectedLibraryChange: (?JobLibrary) => void;
  getLibraries: (
    only: ?Array<number>,
    includeSearchableCountries: ?boolean
  ) => Promise<any>;
  resetLibraries: () => void;

  // job titles
  jobTitlesNetwork: NetworkState; // need separate network state to load in parallel
  jobTitlesCountNetwork: NetworkState; // need separate network state to load in parallel
  jobTitles: RawJobTitle[];
  jobTitlesSelected: Object;
  jobTitlesViewState: Object;
  // jobTitlesView: RawJobTitle[];
  jobTitlesCurrentPage: number;
  jobTitlesNextPage: ?number;
  jobTitlesHasNextPage: () => boolean;
  jobTitlesLoadNextPage: () => void;
  jobTitlesLoadFirstPage: () => void;
  jobTitleSearch: string;
  jobTitlesAllSelected: boolean;
  jobTitlesSelectedCount: number;
  jobTitlesTotalMatchesCount: number;
  jobTitlesPagination: PaginationState;
  // selectedJobTitle: ?RawJobTitle;
  onJobTitleSearchChange: (string) => void;
  // onSelectedJobTitleChange: (?RawJobTitle) => void;
  resetJobTitles: () => void;
  getJobTitles: (PageQuery) => Promise<any>;
  getJobTitlesDebounced: () => void;
  getJobTitlesCount: () => Promise<any>;
  getSelectedJobTitlesDescription: () => Promise<any>;
  selectAllJobTitles: () => Promise<any>;
  onJobTitlesSelectAllPage: () => void;
  onJobTitlesSelectAllMatches: () => void;
  onJobTitlesClearSelection: () => void;
  onRequestJobTitle: () => void;

  // category
  selectedCategory: string;
  onSelectedCategoryChange: (string) => void;

  // industry
  industriesNetwork: NetworkState; // need separate network state to load in parallel
  industries: Industry[];
  selectedIndustry: ?Industry;
  industrySearch: string;
  onIndustrySearchChange: (string) => void;
  onSelectedIndustryChange: (?Industry) => void;
  getIndustries: () => Promise<any>;
  resetIndustries: () => void;

  // location
  locations: Location[];
  selectedLocation: ?Location;
  locationSearch: string;
  onLocationSearchChange: (string) => void;
  onSelectedLocationChange: (?Location) => Promise<void>;
  getLocations: () => Promise<any>;
  locationsCurrentPage: number;
  locationsNextPage: ?number;
  locationsHasNextPage: () => boolean;
  locationsLoadNextPage: () => void;
  locationsLoadFirstPage: () => void;
  isGSS: boolean; // GSS: Global Supplier Search
  onIsGSSChange: (boolean) => void;
  locationType: number; // LOCATION_TYPE from "../../constants/locations"
  onLocationTypeChange: (number) => void;
  showGSSOption: boolean;
  resetLocations: () => void;

  // regions
  regionsNetwork: NetworkState; // need separate network state to load in parallel
  regions: Region[];
  allRegions: Region[];
  selectedRegion: ?Region;
  regionSearch: string;
  onRegionSearchChange: (string) => void;
  onSelectedRegionChange: (?Region) => void;
  getRegions: () => Promise<any>;
  getAllUserRegions: () => Promise<any>;
  resetRegions: () => void;

  // worker types
  workerTypesNetwork: NetworkState;
  workerTypes: Array<WorkerType>;
  selectedWorkerType: ?WorkerType;
  onSelectedWorkerTypeChange: (?WorkerType) => void;
  getWorkerTypes: () => Promise<Array<WorkerType>>;
  resetWorkerTypes: () => void;
  showWorkerTypes: boolean;

  // rate cards
  rateCards: RateCard[];
  rateCardsPagination: PaginationState;
  rateCardsHasNextPage: () => boolean;
  rateCardsLoadNextPage: () => void;
  rateCardsLoadFirstPage: () => void;
  rateCardSearch: string;
  selectedRateCard: ?RateCard;
  onRateCardSearchChange: (string) => void;
  onSelectedRateCardChange: (?RateCard) => void;
  resetRateCards: () => void;
  getRateCards: () => Promise<any>;
  getSelectedRateCard: (string) => Promise<any>;
  rateCardAction: number;
  onRateCardActionChange: (number) => void;
  newRateCardName: string;
  onNewRateCardNameChange: (string) => void;

  // batch search
  jobsToSearch: BatchSearchJob[];
  jobsToSearchItems: BatchSearchJobListItem[]; // all items (sorted)
  jobsToSearchItemsView: BatchSearchJobListItem[]; // items to render (paginated and filtered)
  jobsToSearchItemsViewState: Object;
  jobsToSearchItemsSelectedCount: number;
  jobsToSearchItemsTotalCount: number;
  jobsToSearchItemsAllSelected: number;
  jobsToSearchItemsToggleAllSelected: () => void;
  jobsToSearchItemsAllInSameCountry: boolean;
  jobsToSearchItemsSelectedAllInSameCountry: boolean;
  jobsToSearchLimit: number;
  jobsToRemoveBeforeAdding: ?(BatchSearchJobListItem[]);
  invalidJobs: ?(BatchSearchJobListItem[]);
  addJobToBatchSearch: () => void;
  addMoreJobs: (?boolean) => void;
  removeJobFromSearch: (BatchSearchJobListItem) => void;
  onRemoveInvalidJobsFromSearch: () => void;
  findInvalidJobs: (InvalidJobData[]) => BatchSearchJobListItem[];
  editJobToSearch: (BatchSearchJobListItem) => void;
  addNewLocationForAllJobs: () => void;
  changeLocationForAllSelectedJobs: () => void;
  validateStep: (number) => boolean;
  onCurrentStepChange: (number) => void;
  addTitleValidateStep: (number) => boolean;
  addTitleOnCurrentStepChange: (number) => void;
  resetAll: () => void;
  createAndRunBatchSearch: () => Promise<any>;
  rerunBatchSearch: (string) => Promise<any>;
  setUpBatchSearchFromJobLibrary: (number, ?boolean) => Promise<any>;
  showAddJobTitlesWizard: boolean;
  showDescriptions: boolean;
  locationValid: () => boolean;
  cancel: () => void;
  showGSSColumn: boolean;
  creatingFromLibrary: boolean;

  // jobs to search filters
  jobsToSearchFiltersModal: ModalState;
  jobsToSearchAppliedFilters: ItemFilter[];
  jobsToSearchAvailableIndustryFilters: ItemFilter[];
  jobsToSearchAvailableCategoryFilters: ItemFilter[];
  jobsToSearchSelectedIndustryFilter: ?ItemFilter;
  jobsToSearchSelectedCategoryFilter: ?ItemFilter;
  jobsToSearchTitleFilter: string;
  onJobsToSearchSelectedIndustryFilterChange: (?ItemFilter) => void;
  onJobsToSearchSelectedCategoryFilterChange: (?ItemFilter) => void;
  onJobsToSearchTitleFilterChange: (string) => void;
  filterJobsToSearch: () => void;
  clearJobsToSearchFilters: () => void;

  // bulk change industry
  bulkChangeIndustryModal: ModalState;
  bulkChangeIndustryModalSelectedIndustry: ?Industry;
  bulkChangeIndustryModalOnSelectedIndustryChange: (?Industry) => void;
  bulkChangeIndustryModalOnCommitChange: () => void;
  bulkChangeIndustryModalOnCancel: () => void;

  // bulk remove jobs to search
  bulkRemoveJobsModal: ModalState;
  bulkRemoveJobsModalOnConfirm: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;
    this.router = null;

    // NOTE: Bound early to pass into pagination & filter state
    this.getRateCards = action(this.getRateCards.bind(this));
    this.getJobTitles = action(this.getJobTitles.bind(this));
    // this.getJobTitles = debounce(this.getJobTitles.bind(this), 400, { trailing: true, leading: false });

    this.jobsToSearchLimit = 2000;

    extendObservable(this, {
      network: new NetworkState(),
      wizard: new WizardState(),
      addTitleWizard: new WizardState(),
      errorModal: new ModalState(),
      errorMessage: null,
      invalidJobsErrorModal: new ModalState(),
      infoModal: new ModalState(),
      infoMessage: null,
      // rate type
      selectedRateType: null,
      rateTypes: [RATE_TYPE_OPTIONS_2.CONTRACT, RATE_TYPE_OPTIONS_2.FTE],
      // userCountries
      userCountriesNetwork: new NetworkState(),
      userCountries: [],
      userCountrySearch: "",
      selectedUserCountry: null,
      onlyCountryIds: null,
      // libraries
      librariesNetwork: new NetworkState(),
      clientLibraries: [],
      selectedLibrary: null,
      librarySearch: "",
      // jobTitles
      jobTitlesNetwork: new NetworkState(),
      jobTitlesCountNetwork: new NetworkState(),
      jobTitles: [],
      // selectedJobTitle: null,
      jobTitleSearch: "",
      jobTitlesCurrentPage: 0,
      jobTitlesNextPage: 1,
      jobTitlesAllSelected: false,
      jobTitlesTotalCount: 0,
      jobTitlesSelected: observable.map({}),
      jobTitlesViewState: observable.map({}),
      jobTitlesSelectedCount: computed(() => {
        if (this.jobTitlesAllSelected) {
          return this.jobTitlesTotalMatchesCount;
        }

        let count = 0;
        this.jobTitlesViewState.forEach((viewState) => {
          if (viewState.selected) {
            count += 1;
          }
        });
        return count;
      }),
      jobTitlesPagination: new PaginationState(this.getJobTitles, 8),

      // category
      selectedCategory: "",
      // industry
      industriesNetwork: new NetworkState(),
      industries: [],
      selectedIndustry: null,
      industrySearch: "",
      // locations
      locations: [],
      selectedLocation: null,
      locationSearch: "",
      locationsCurrentPage: 0,
      locationsNextPage: 1,
      isGSS: false,
      locationType: LOCATION_TYPE.LOCATION,
      showGSSOption: computed(() => {
        if (!this.selectedUserCountry) return false;
        if (!this.locationValid()) return false;
        const countryId = this.selectedUserCountry.countryId || "";
        return GSS_COUNTRY_IDS.includes(countryId.toString());
      }),
      // regions
      regionsNetwork: new NetworkState(),
      regions: [],
      allRegions: [],
      selectedRegion: null,
      regionSearch: "",
      // worker types
      workerTypesNetwork: new NetworkState(),
      workerTypes: [],
      selectedWorkerType: null,
      showWorkerTypes: computed(() => {
        return (
          this.selectedRateType?.value === RATE_TYPE.HOURLY &&
          this.locationType === LOCATION_TYPE.LOCATION &&
          this.selectedUserCountry &&
          this.selectedIndustry &&
          this.workerTypes.length > 0
        );
      }),
      // rate cards
      rateCards: [],
      rateCardsPagination: new PaginationState(this.getRateCards, 10),
      selectedRateCard: null,
      rateCardSearch: "",
      rateCardsCurrentPage: 0,
      rateCardsNextPage: 1,
      rateCardAction: RATE_CARD_ACTION.USE_EXISTING,
      newRateCardName: "",
      // batch search
      jobsToSearch: [],
      jobsToSearchItems: computed(() => {
        const result: BatchSearchJobListItem[] = [];
        this.jobsToSearch.forEach((job: BatchSearchJob) => {
          job.locations.forEach((location) =>
            result.push(new BatchSearchJobListItem(this, job, location, false))
          );
          job.gssLocations.forEach((location) =>
            result.push(new BatchSearchJobListItem(this, job, location, true))
          );
          job.regions.forEach((location) =>
            result.push(new BatchSearchJobListItem(this, job, location, false))
          );
        });

        // TODO: sort result list here

        return result;
      }),
      jobsToSearchItemsView: computed(() => {
        // TODO: paginate & filter result list here
        return this.jobsToSearchItems.filter((item) => {
          for (let i = 0; i < this.jobsToSearchAppliedFilters.length; i++) {
            const currentFilter = this.jobsToSearchAppliedFilters[i];
            if (!currentFilter.apply(item)) return false;
          }
          return true;
        });
      }),
      jobsToSearchItemsTotalCount: computed(() => {
        let result: number = 0;
        this.jobsToSearch.forEach((job: BatchSearchJob) => {
          result += job.locations.length;
          result += job.gssLocations.length;
          result += job.regions.length;
        });

        return result;
      }),
      jobsToSearchItemsSelectedCount: computed(() => {
        let count = 0;
        this.jobsToSearchItems.forEach((item) => {
          if (item.viewState.selected) {
            count += 1;
          }
        });
        return count;
      }),
      jobsToSearchItemsAllSelected: computed(() => {
        return this.jobsToSearchItemsSelectedCount === this.jobsToSearchItemsTotalCount;
      }),
      jobsToRemoveBeforeAdding: null,
      invalidJobs: null,
      showAddJobTitlesWizard: true,
      showDescriptions: false,
      jobsToSearchItemsAllInSameCountry: computed(() => {
        if (this.jobsToSearchItems.length === 0) return false;

        let country = this.jobsToSearchItems[0].countryCode;
        for (let i = 0; i < this.jobsToSearchItems.length; i++) {
          const job = this.jobsToSearchItems[i];

          if (job.countryCode !== country) return false;
        }

        return true;
      }),
      jobsToSearchItemsSelectedAllInSameCountry: computed(() => {
        const selectedJobs = this.jobsToSearchItems.filter(
          (item) => item.viewState.selected
        );
        if (selectedJobs.length === 0) return false;

        let country = selectedJobs[0].countryCode;
        for (let i = 0; i < selectedJobs.length; i++) {
          const job = selectedJobs[i];

          if (job.countryCode !== country) return false;
        }

        return true;
      }),
      showGSSColumn: computed(() => {
        for (let i = 0; i < this.jobsToSearchItems.length; i++) {
          if (this.jobsToSearchItems[i].isGSS) return true;
        }

        return false;
      }),
      creatingFromLibrary: false,
      // jobs to search filters
      jobsToSearchFiltersModal: new ModalState(),
      jobsToSearchItemsViewState: observable.map({}),
      jobsToSearchAppliedFilters: [],
      jobsToSearchAvailableIndustryFilters: computed(() => {
        const industryFilters: ItemFilter[] = [];
        const industryMap = {};
        this.jobsToSearchItems.forEach((job: BatchSearchJobListItem) => {
          if (job.industry in industryMap) return;

          const filter = new ItemFilter(["industry"], job.industry, job.industry);
          industryFilters.push(filter);
          industryMap[job.industry] = true;
        });

        return industryFilters;
      }),
      jobsToSearchSelectedIndustryFilter: null,
      jobsToSearchAvailableCategoryFilters: computed(() => {
        const categoryFilters: ItemFilter[] = [];
        const categoryMap = {};
        this.jobsToSearchItems.forEach((job: BatchSearchJobListItem) => {
          if (job.category in categoryMap) return;

          const filter = new ItemFilter(["category"], job.category, job.category);
          categoryFilters.push(filter);
          categoryMap[job.category] = true;
        });

        return categoryFilters;
      }),
      jobsToSearchSelectedCategoryFilter: null,
      jobsToSearchTitleFilter: "",
      // bulk change industry
      bulkChangeIndustryModal: new ModalState(),
      bulkChangeIndustryModalSelectedIndustry: null,
      // bulk remove jobs to search
      bulkRemoveJobsModal: new ModalState(),
    });

    // rate type
    this.onRateTypeChange = action(this.onRateTypeChange.bind(this));
    // user countries
    this.onUserCountrySearchChange = action(this.onUserCountrySearchChange.bind(this));
    this.onSelectedUserCountryChange = action(
      this.onSelectedUserCountryChange.bind(this)
    );
    this.getUserCountries = action(this.getUserCountries.bind(this));
    this.resetUserCountries = action(this.resetUserCountries.bind(this));
    // libraries
    this.onLibrarySearchChange = action(this.onLibrarySearchChange.bind(this));
    this.onSelectedLibraryChange = action(this.onSelectedLibraryChange.bind(this));
    this.getLibraries = action(this.getLibraries.bind(this));
    this.resetLibraries = action(this.resetLibraries.bind(this));
    // job titles
    this.onJobTitleSearchChange = action(this.onJobTitleSearchChange.bind(this));
    // this.onSelectedJobTitleChange = action(this.onSelectedJobTitleChange.bind(this));
    this.jobTitlesHasNextPage = action(this.jobTitlesHasNextPage.bind(this));
    this.jobTitlesLoadNextPage = action(this.jobTitlesLoadNextPage.bind(this));
    this.jobTitlesLoadFirstPage = action(this.jobTitlesLoadFirstPage.bind(this));
    this.resetJobTitles = action(this.resetJobTitles.bind(this));
    this.getJobTitlesCount = action(this.getJobTitlesCount.bind(this));
    this.getSelectedJobTitlesDescription = action(
      this.getSelectedJobTitlesDescription.bind(this)
    );
    this.selectAllJobTitles = action(this.selectAllJobTitles.bind(this));
    this.onJobTitlesSelectAllPage = action(this.onJobTitlesSelectAllPage.bind(this));
    this.onJobTitlesSelectAllMatches = action(
      this.onJobTitlesSelectAllMatches.bind(this)
    );
    this.onJobTitlesClearSelection = action(this.onJobTitlesClearSelection.bind(this));
    this.onRequestJobTitle = action(this.onRequestJobTitle.bind(this));
    // category
    this.onSelectedCategoryChange = action(this.onSelectedCategoryChange.bind(this));
    // industries
    this.onIndustrySearchChange = action(this.onIndustrySearchChange.bind(this));
    this.onSelectedIndustryChange = action(this.onSelectedIndustryChange.bind(this));
    this.getIndustries = action(this.getIndustries.bind(this));
    this.resetIndustries = action(this.resetIndustries.bind(this));
    // locations
    this.onLocationSearchChange = action(this.onLocationSearchChange.bind(this));
    this.onSelectedLocationChange = action(this.onSelectedLocationChange.bind(this));
    this.getLocations = action(this.getLocations.bind(this));
    this.locationsHasNextPage = action(this.locationsHasNextPage.bind(this));
    this.locationsLoadNextPage = action(this.locationsLoadNextPage.bind(this));
    this.locationsLoadFirstPage = action(this.locationsLoadFirstPage.bind(this));
    this.onIsGSSChange = action(this.onIsGSSChange.bind(this));
    this.onLocationTypeChange = action(this.onLocationTypeChange.bind(this));
    this.resetLocations = action(this.resetLocations.bind(this));
    // regions
    this.onRegionSearchChange = action(this.onRegionSearchChange.bind(this));
    this.onSelectedRegionChange = action(this.onSelectedRegionChange.bind(this));
    this.getRegions = action(this.getRegions.bind(this));
    this.getAllUserRegions = action(this.getAllUserRegions.bind(this));
    this.resetRegions = action(this.resetRegions.bind(this));
    // worker types
    this.onSelectedWorkerTypeChange = action(this.onSelectedWorkerTypeChange.bind(this));
    this.getWorkerTypes = action(this.getWorkerTypes.bind(this));
    this.resetWorkerTypes = action(this.resetWorkerTypes.bind(this));
    // rate cards
    this.onRateCardSearchChange = action(this.onRateCardSearchChange.bind(this));
    this.onSelectedRateCardChange = action(this.onSelectedRateCardChange.bind(this));
    this.rateCardsHasNextPage = action(this.rateCardsHasNextPage.bind(this));
    this.rateCardsLoadNextPage = action(this.rateCardsLoadNextPage.bind(this));
    this.rateCardsLoadFirstPage = action(this.rateCardsLoadFirstPage.bind(this));
    this.resetRateCards = action(this.resetRateCards.bind(this));
    this.onNewRateCardNameChange = action(this.onNewRateCardNameChange.bind(this));
    this.onRateCardActionChange = action(this.onRateCardActionChange.bind(this));
    this.getSelectedRateCard = action(this.getSelectedRateCard.bind(this));
    // batch search
    this.jobsToSearchItemsToggleAllSelected = action(
      this.jobsToSearchItemsToggleAllSelected.bind(this)
    );
    this.addJobToBatchSearch = action(this.addJobToBatchSearch.bind(this));
    this.addMoreJobs = action(this.addMoreJobs.bind(this));
    this.removeJobFromSearch = action(this.removeJobFromSearch.bind(this));
    this.onRemoveInvalidJobsFromSearch = action(
      this.onRemoveInvalidJobsFromSearch.bind(this)
    );
    this.findInvalidJobs = action(this.findInvalidJobs.bind(this));
    this.editJobToSearch = action(this.editJobToSearch.bind(this));
    this.addNewLocationForAllJobs = action(this.addNewLocationForAllJobs.bind(this));
    this.changeLocationForAllSelectedJobs = action(
      this.changeLocationForAllSelectedJobs.bind(this)
    );
    this.validateStep = action(this.validateStep.bind(this));
    this.onCurrentStepChange = action(this.onCurrentStepChange.bind(this));
    this.addTitleValidateStep = action(this.addTitleValidateStep.bind(this));
    this.addTitleOnCurrentStepChange = action(
      this.addTitleOnCurrentStepChange.bind(this)
    );
    this.createAndRunBatchSearch = action(this.createAndRunBatchSearch.bind(this));
    this.rerunBatchSearch = action(this.rerunBatchSearch.bind(this));
    this.setUpBatchSearchFromJobLibrary = action(
      this.setUpBatchSearchFromJobLibrary.bind(this)
    );
    this.resetAll = this.resetAll.bind(this);
    this.locationValid = this.locationValid.bind(this);
    this.cancel = this.cancel.bind(this);
    // jobs to search filters
    this.onJobsToSearchSelectedIndustryFilterChange =
      this.onJobsToSearchSelectedIndustryFilterChange.bind(this);
    this.onJobsToSearchSelectedCategoryFilterChange =
      this.onJobsToSearchSelectedCategoryFilterChange.bind(this);
    this.onJobsToSearchTitleFilterChange =
      this.onJobsToSearchTitleFilterChange.bind(this);
    this.filterJobsToSearch = this.filterJobsToSearch.bind(this);
    this.clearJobsToSearchFilters = this.clearJobsToSearchFilters.bind(this);
    // bulk change industry
    this.bulkChangeIndustryModalOnSelectedIndustryChange =
      this.bulkChangeIndustryModalOnSelectedIndustryChange.bind(this);
    this.bulkChangeIndustryModalOnCommitChange =
      this.bulkChangeIndustryModalOnCommitChange.bind(this);
    this.bulkChangeIndustryModalOnCancel =
      this.bulkChangeIndustryModalOnCancel.bind(this);
    // bulk remove jobs to search
    this.bulkRemoveJobsModalOnConfirm = this.bulkRemoveJobsModalOnConfirm.bind(this);
  }

  // ------------------------------------------------------------
  //
  //   Rate Types
  //
  // ------------------------------------------------------------

  onRateTypeChange(rateType: Option) {
    this.selectedRateType = rateType;
  }

  // ------------------------------------------------------------
  //
  //   UserCountries
  //
  // ------------------------------------------------------------

  onSelectedUserCountryChange(selectedUserCountry: ?Location): void {
    this.selectedUserCountry = selectedUserCountry;
    this.resetLocations();
    this.resetRegions();
    this.resetIndustries();
    this.resetWorkerTypes();

    // !important: Do not reset selected titles
    // this.resetJobTitles();

    if (this.selectedUserCountry) {
      // this.locationSearch = this.selectedUserCountry.toString();
      // this.selectedLocation = this.selectedUserCountry;

      this.getJobTitlesCount();
      Promise.all([this.getLocations(), this.getRegions()])
        .then(() => {
          this.addTitleWizard.goTo(2); // select location and industry
          if (this.industries.length > 0) {
            this.selectedIndustry = this.industries[0];

            if (this.selectedRateType?.value === RATE_TYPE.HOURLY) {
              this.getWorkerTypes();
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = (
            <p>
              Sorry! we could not load required data for the selected country. There was
              an error during the operation.
            </p>
          );
        });
    } else {
      this.addTitleWizard.goTo(1); // select country
    }
  }

  onUserCountrySearchChange(query: string): void {
    this.userCountrySearch = query;
    this.getUserCountries();
  }

  resetUserCountries(): void {
    this.userCountries = [];
    this.selectedUserCountry = null;
    this.userCountrySearch = "";
  }

  async getUserCountries(): Promise<any> {
    if (this.userCountriesNetwork.loading === true) {
      this.userCountriesNetwork.cancel();
    }

    const filterFields = [];

    if (this.userCountrySearch && this.userCountrySearch.trim() !== "") {
      filterFields.push(`anyPropertyIContains: "${this.userCountrySearch}"`);
    }

    if (this.onlyCountryIds) {
      filterFields.push(`apiLocationIdIn: [${this.onlyCountryIds.join(", ")}]`);
    }

    const filters = `filters: {${filterFields.join(", ")}},`;

    const query = `
      query getUserCountries {
        viewer {
          countries(${filters} order: [{field: NAME}]) {
            ...userCountry
          }
        }
      }

      fragment userCountry on CountryListNode {
        locationId
        countryId: apiLocationId
        fullTitle: name
        countryCode: isoCode
      }
    `;

    this.userCountriesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(
        query,
        {},
        this.userCountriesNetwork.getCancelToken()
      );
    } catch (e) {
      if (this.userCountriesNetwork.isCancelError(e)) {
        return e;
      }

      this.userCountriesNetwork.handleError("Getting Client UserCountries", e);
      if (res !== null) {
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getUserCountries--success", () => {
      this.userCountriesNetwork.loading = false;
      this.userCountriesNetwork.error = null;
      if (
        this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res)
      ) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const userCountries = res.data.viewer.countries;

      this.userCountries = userCountries.map((userCountry) => {
        return new Location(this, userCountry);
      });

      return this.userCountries;
    });
  }

  // ------------------------------------------------------------
  //
  //   Client Libraries
  //
  // ------------------------------------------------------------

  onSelectedLibraryChange(selectedLibrary: ?JobLibrary): void {
    this.selectedLibrary = selectedLibrary;

    this.jobTitlesPagination.goFetch();
  }

  onLibrarySearchChange(query: string): void {
    this.librarySearch = query;
    this.getLibraries();
  }

  resetLibraries(): void {
    this.selectedLibrary = null;
    this.librarySearch = "";
  }

  async getLibraries(
    only: ?Array<number> = null,
    includeSearchableCountries: boolean = false
  ): Promise<any> {
    if (this.librariesNetwork.loading === true) {
      this.librariesNetwork.cancel();
    }

    let filters = "";
    if (this.librarySearch && this.librarySearch.trim() !== "") {
      filters = `filters: {nameIContains: "${this.librarySearch}"},`;
    }
    if (only) {
      filters = `filters: {only: [${only.join(", ")}]},`;
    }

    const query = `
      query getClientLibraries {
        clientLibraries(${filters} order: [{field: NAME}]) {
          totalCount
          pageInfo {
            startCursor
            endCursor
          }
          edges {
            node {
              ...library
            }
          }
        }
      }

      fragment library on ClientLibraryNode {
        id
        databaseId
        name
        ${includeSearchableCountries ? "searchableCountries {databaseId}" : ""}
      }
    `;

    this.librariesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchTasteGraphQL(
        query,
        {},
        this.librariesNetwork.getCancelToken()
      );
    } catch (e) {
      if (this.librariesNetwork.isCancelError(e)) {
        return e;
      }

      this.librariesNetwork.handleError("Getting Client Libraries", e);
      if (res !== null) {
        this.librariesNetwork.logGraphQLError("Get Client Libraries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getLibraries--success", () => {
      this.librariesNetwork.loading = false;
      this.librariesNetwork.error = null;
      if (this.librariesNetwork.logGraphQLError("Get Client Libraries query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const libraries = res.data.clientLibraries.edges;

      this.clientLibraries = libraries.map((library) => {
        return new JobLibrary(this, library.node);
      });

      return this.clientLibraries;
    });
  }

  // ------------------------------------------------------------
  //
  //   Client JobTitles
  //
  // ------------------------------------------------------------

  // onSelectedJobTitleChange(selectedJobTitle: ?RawJobTitle): void {
  //   this.selectedJobTitle = selectedJobTitle;
  //
  //   if (this.selectedJobTitle) {
  //     this.selectedJobTitle.getDescription();
  //   }
  //   if (this.selectedJobTitle && this.selectedJobTitle.libraryInfo) {
  //     this.selectedCategory = this.selectedJobTitle.libraryInfo.category;
  //   }
  //
  //   // reset locations and regions
  //   this.resetLocations();
  //   this.resetRegions();
  // }

  onJobTitleSearchChange(query: string): void {
    this.jobTitleSearch = query;

    // load first page of titles
    this.getJobTitlesDebounced();
    // this.jobTitlesLoadFirstPage();
  }

  onJobTitlesSelectAllPage(): void {
    this.jobTitles.forEach((jobTitle) => jobTitle.toggleSelected(true));
  }

  onJobTitlesSelectAllMatches(): void {
    this.selectAllJobTitles();
  }

  onJobTitlesClearSelection(): void {
    this.jobTitlesViewState.values().forEach((viewState) => (viewState.selected = false));
    this.jobTitlesSelected.clear();
  }

  onRequestJobTitle(): void {
    if (!this.router) {
      console.error("Router has not been set");
      return;
    }

    if (this.selectedUserCountry) {
      const requestJobTitleStore = this.mobXStore.jobLibraryTitleRequestCreateStore;
      requestJobTitleStore.defaultSelectedCountries = [
        this.selectedUserCountry.id.toString(),
      ];
    }

    this.router.push(`/job-library/title-requests/create-title-request`);
  }

  resetJobTitles(): void {
    // this.selectedJobTitle = null;
    this.jobTitles = [];
    this.jobTitlesTotalMatchesCount = 0;
    this.jobTitlesCurrentPage = 0;
    this.jobTitlesNextPage = 1;
    this.jobTitleSearch = "";
    this.selectedCategory = "";
    this.jobTitlesSelected.clear();
    this.jobTitlesViewState.clear();
    this.jobTitlesAllSelected = false;

    // putting this here since its tied to the selected jobTitle
    // if needed in the future, move to a different method
    this.selectedCategory = "";
  }

  jobTitlesHasNextPage(): boolean {
    return Boolean(this.jobTitlesNextPage);
  }

  jobTitlesLoadNextPage() {
    if (!this.jobTitlesHasNextPage()) return;

    this.jobTitlesPagination.goFetch();
  }

  jobTitlesLoadFirstPage() {
    this.jobTitlesNextPage = 1;
    this.jobTitlesPagination.goFetch();
  }

  async getSelectedJobTitlesDescription(): Promise<any> {
    const ids = this.jobTitlesSelected
      .values()
      .filter((jobTitle: RawJobTitle) => !jobTitle.description)
      .map((jobTitle: RawJobTitle) => jobTitle.id);

    if (!ids || ids.length === 0) return;

    if (this.jobTitlesNetwork.loading === true) {
      this.jobTitlesNetwork.cancel();
    }

    const query = `
    query getRawJobTitlesDescriptions($ids: [ID]!) {
      clientRawJobTitles(filters: {only: $ids}) {
        edges {
          node {
            databaseId
            description
          }
        }
      }
    }
    `;

    const variables = {
      ids: ids,
    };

    this.jobTitlesNetwork.loading = true;

    let payload = null;
    try {
      payload = await this.fetchTasteGraphQL(
        query,
        variables,
        this.jobTitlesNetwork.getCancelToken()
      );
    } catch (e) {
      this.jobTitlesNetwork.loading = false;
      if (this.jobTitlesNetwork.isCancelError(e)) {
        // console.log('Request Canceled ClientJobTitleListStore');
        return e;
      }

      this.network.handleError("Getting Raw Job Titles Description", e);
      if (payload !== null) {
        this.network.logGraphQLError("Get Raw Job Titles Description query", payload);
      }

      this.errorMessage = (
        <p>
          Sorry! Couldn't get the description for some of the titles you're trying to add.
        </p>
      );
      this.errorModal.showModal();
      return Promise.reject("No description received for titles");
    }

    return runInAction("getSelectedJobTitlesDescription--success", () => {
      this.jobTitlesNetwork.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Raw Job Titles Description query", payload)) {
        // TODO: Display user friendly error message
        return null;
      }

      if (!payload || !payload.data || !payload.data.clientRawJobTitles) return;

      // create an id map to determine if the result has missing titles
      let toBeProcessed = ids.reduce((map, titleId) => {
        map[titleId] = true;
        return map;
      }, {});

      payload.data.clientRawJobTitles.edges.forEach((titleEdge) => {
        const titleData = titleEdge.node;
        if (!titleData) return;

        // skip if somehow we brought back a title that is not selected
        if (!toBeProcessed[titleData.databaseId]) return;

        // update description on title
        const jobTitle: RawJobTitle = this.jobTitlesSelected.get(titleData.databaseId);
        jobTitle.description = titleData.description;

        // remove current title id from processedIds
        delete toBeProcessed[titleData.databaseId];
      });

      // error if any title was missing from result
      const missingTitleDescriptions = Object.keys(toBeProcessed);
      if (missingTitleDescriptions.length > 0) {
        console.error("No description received for titles:", missingTitleDescriptions);
        this.errorMessage = (
          <p>
            Sorry! Couldn't get the description for some of the titles you're trying to
            add.
          </p>
        );
        this.errorModal.showModal();
        return Promise.reject("No description received for titles");
      }
    });
  }

  async selectAllJobTitles(): Promise<any> {
    // if (!this.jobTitlesHasNextPage()) return;

    if (this.jobTitlesNetwork.loading === true) {
      this.jobTitlesNetwork.cancel();
    }

    this.jobTitlesNetwork.loading = true;
    let res = null;

    const query = `
    query getRawJobTitlesFuzzySearch(
      $pageSize: Int,
      $page: Int,
      $search: String,
      $libraryId: ID,
      $countryId: ID,
      $libraryTitlesOnly: Boolean,
      $stockTitlesOnly: Boolean
    ) {
      viewer {
        jobTitles(
          pageSize: $pageSize,
          page: $page,
          search: $search,
          libraryId: $libraryId,
          countryId: $countryId,
          libraryTitlesOnly: $libraryTitlesOnly,
          stockTitlesOnly: $stockTitlesOnly
        ) {
          totalCount
          results {
            id
            title
            collection
            isJobLabel
            category
            shareInfo {
              jobLabelId
              searchOnly
              isMine
              sharedBy {
                userId
                firstName
                lastName
              }
            }
            clientJobLibraryInfo {
              created
              categoryName
              adhocCountries
              certifiedCountries
              searchableCountries
              mappedRawJobTitleTitle
              mappedRawJobTitleId
            }
          }
        }
      }
    }
    `;

    const variables = {
      pageSize: this.jobTitlesTotalMatchesCount,
      page: 1,
      search:
        this.jobTitleSearch && this.jobTitleSearch.trim() !== ""
          ? this.jobTitleSearch
          : null,
      libraryId: this.selectedLibrary ? this.selectedLibrary.id : null,
      countryId:
        this.selectedUserCountry && this.selectedUserCountry.countryId
          ? this.selectedUserCountry.countryId
          : null,
    };

    try {
      res = await this.fetchGraphQL(
        query,
        variables,
        this.jobTitlesNetwork.getCancelToken()
      );
    } catch (e) {
      if (this.jobTitlesNetwork.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Selecting all JobTitles", e);
      return e;
    }

    return runInAction("selectAllJobTitles--success", () => {
      this.jobTitlesNetwork.loading = false;
      this.network.error = null;

      if (!res) {
        return null;
      }

      const jobTitles = res.data.viewer.jobTitles.results;
      // this.jobTitlesAllSelected = true;

      jobTitles.forEach((jobTitleData) => {
        const jobTitle = new RawJobTitle(this, jobTitleData);

        // set the selected viewState for all titles
        if (!this.jobTitlesViewState.has(jobTitle.id)) {
          this.jobTitlesViewState.set(jobTitle.id, {
            selected: true,
            editing: false,
            expanded: false,
          });
        } else {
          this.jobTitlesViewState.get(jobTitle.id).selected = true;
        }

        jobTitle.viewState = this.jobTitlesViewState.get(jobTitle.id);

        // add title to selected titles map
        if (this.jobTitlesSelected.has(jobTitle.id)) {
          // if it's already selected, update it
          const existingTitle = this.jobTitlesSelected.get(jobTitle.id);
          Object.assign(existingTitle, jobTitle);
        } else {
          this.jobTitlesSelected.set(jobTitle.id, jobTitle);
        }
      });
    });
  }

  async getJobTitlesCount(): Promise<any> {
    // if (!this.jobTitlesHasNextPage()) return;

    if (this.jobTitlesCountNetwork.loading === true) {
      this.jobTitlesCountNetwork.cancel();
    }

    this.jobTitlesCountNetwork.loading = true;
    let res = null;

    const query = `
    query getRawJobTitlesFuzzySearch(
      $pageSize: Int,
      $page: Int,
      $search: String,
      $stockTitlesOnly: Boolean,
      $countryId: ID
    ) {
      viewer {
        jobTitles(
          pageSize: $pageSize,
          page: $page,
          search: $search,
          stockTitlesOnly: $stockTitlesOnly,
          countryId: $countryId
        ) {
          totalCount
        }
      }
    }
    `;

    const country = this.selectedUserCountry;
    const variables = {
      pageSize: 1,
      page: 1,
      countryId: country && country.countryId ? country.countryId : null,
    };

    try {
      // const itemsPerPage = pageQuery.page_size;
      // const page = pageQuery.page; // this.jobTitlesNextPage;
      // const search = this.jobTitleSearch && this.jobTitleSearch.trim() !== "" ? this.jobTitleSearch : null;

      res = await this.fetchGraphQL(
        query,
        variables,
        this.jobTitlesCountNetwork.getCancelToken()
      );
    } catch (e) {
      this.jobTitlesCountNetwork.loading = false;
      if (this.jobTitlesCountNetwork.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Client JobTitles", e);

      this.errorMessage = (
        <p>Sorry! there was an error retrieving Job Titles for searching.</p>
      );
      this.errorModal.showModal();
      return e;
    }

    return runInAction("getJobTitles--success", () => {
      this.jobTitlesCountNetwork.loading = false;
      this.network.error = null;

      if (!res) {
        return 0;
      }

      this.jobTitlesTotalMatchesCount = res.data.viewer.jobTitles.totalCount;

      return res.data.viewer.jobTitles.totalCount;
    });
  }

  getJobTitlesDebounced = debounce(
    () => {
      this.jobTitlesNextPage = 1;
      this.jobTitlesPagination.goFetch().catch(() => {});
    },
    400,
    { leading: false, trailing: true }
  );

  async getJobTitles(pageQuery: PageQuery): Promise<any> {
    // console.log("getJobTitles:", pageQuery);
    // if (!this.jobTitlesHasNextPage()) return;
    const abortController = this.jobTitlesNetwork.createAbortController();

    this.jobTitlesNetwork.loading = true;
    let res = null;

    const query = `
    query getRawJobTitlesFuzzySearch(
      $pageSize: Int,
      $page: Int,
      $search: String,
      $libraryId: ID,
      $countryId: ID,
      $libraryTitlesOnly: Boolean,
      $stockTitlesOnly: Boolean
    ) {
      viewer {
        jobTitles(
          pageSize: $pageSize,
          page: $page,
          search: $search,
          libraryId: $libraryId,
          countryId: $countryId,
          libraryTitlesOnly: $libraryTitlesOnly,
          stockTitlesOnly: $stockTitlesOnly
        ) {
          totalCount
          results {
            id
            title
            collection
            isJobLabel
            category
            shareInfo {
              jobLabelId
              searchOnly
              isMine
              sharedBy {
                userId
                firstName
                lastName
              }
            }
            clientJobLibraryInfo {
              created
              categoryName
              adhocCountries
              certifiedCountries
              searchableCountries
              mappedRawJobTitleTitle
              mappedRawJobTitleId
            }
          }
        }
      }
    }
    `;

    const variables = {
      pageSize: pageQuery.itemsPerPage,
      page: pageQuery.page,
      search:
        this.jobTitleSearch && this.jobTitleSearch.trim() !== ""
          ? this.jobTitleSearch
          : null,
      libraryId: this.selectedLibrary ? this.selectedLibrary.id : null,
      countryId:
        this.selectedUserCountry && this.selectedUserCountry.countryId
          ? this.selectedUserCountry.countryId
          : null,
    };

    try {
      // const itemsPerPage = pageQuery.page_size;
      // const page = pageQuery.page; // this.jobTitlesNextPage;
      // const search = this.jobTitleSearch && this.jobTitleSearch.trim() !== "" ? this.jobTitleSearch : null;
      // const libraryIds = this.selectedLibrary ? [this.selectedLibrary.id] : null;
      // const countryIds = this.selectedUserCountry ? [this.selectedUserCountry.countryId] : null;

      res = await this.fetchGraphQL(query, variables, abortController.source.token);
    } catch (e) {
      if (this.jobTitlesNetwork.isCancelError(e)) {
        return e;
      }

      this.jobTitlesNetwork.loading = false;
      this.network.handleError("Getting Client JobTitles", e);

      this.errorMessage = (
        <p>Sorry! there was an error retrieving Job Titles for searching.</p>
      );
      this.errorModal.showModal();
      return e;
    }

    if (abortController.aborted()) {
      return Promise.reject(abortController.source.token.reason);
    }

    return runInAction("getJobTitles--success", () => {
      this.jobTitlesNetwork.loading = false;
      this.network.error = null;

      if (!res) {
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      if (this.jobTitlesNextPage) {
        this.jobTitlesCurrentPage = this.jobTitlesNextPage;
        this.jobTitlesNextPage = res.next ? this.jobTitlesNextPage + 1 : null;
      }

      const jobTitlesData = res.data.viewer.jobTitles.results;

      this.jobTitles = jobTitlesData.map((jobTitleData) => {
        const jobTitle = new RawJobTitle(this, jobTitleData);

        if (!this.jobTitlesViewState.has(jobTitle.id)) {
          this.jobTitlesViewState.set(jobTitle.id, {
            selected: false,
            editing: false,
            expanded: false,
          });
        } else {
          const selectedValue = this.jobTitlesViewState.get(jobTitle.id).selected;
          this.jobTitlesViewState.set(jobTitle.id, {
            selected: selectedValue,
            editing: false,
            expanded: false,
          });
        }

        jobTitle.viewState = this.jobTitlesViewState.get(jobTitle.id);

        if (jobTitle.viewState.selected) {
          // this.jobTitles will be the source of truth, so if there was already an object
          // in this.jobTitlesSelected replace it by this jobTitle instance
          this.jobTitlesSelected.set(jobTitle.id, jobTitle);
        }

        if (!jobTitle.viewState.selected && this.jobTitlesSelected.has(jobTitle.id)) {
          this.jobTitlesSelected.delete(jobTitle.id);
        }

        return jobTitle;
      });

      this.jobTitlesTotalMatchesCount = res.data.viewer.jobTitles.totalCount;

      // console.log("jobTitles:", res.data.viewer.jobTitles);
      // console.log("totalCount:", parseInt(res.data?.viewer?.jobTitles?.totalCount || "0"));
      return Promise.resolve({
        totalCount: parseInt(res.data?.viewer?.jobTitles?.totalCount || "0"),
      });
    });
  }

  // ------------------------------------------------------------
  //
  //   category
  //
  // ------------------------------------------------------------

  onSelectedCategoryChange(value: string): void {
    this.selectedCategory = value;
  }

  // ------------------------------------------------------------
  //
  //   Client Industries
  //
  // ------------------------------------------------------------

  onSelectedIndustryChange(selectedIndustry: ?Industry): void {
    this.selectedIndustry = selectedIndustry;

    if (this.selectedIndustry && this.locationValid()) {
      this.addTitleWizard.goTo(3);
      this.getWorkerTypes();
    } else {
      this.addTitleWizard.goTo(this.selectedUserCountry ? 2 : 1);
    }
  }

  onIndustrySearchChange(query: string): void {
    this.industrySearch = query;
    this.getIndustries();
  }

  resetIndustries(): void {
    this.selectedIndustry = null;
    this.industrySearch = "";
  }

  async getIndustries(): Promise<any> {
    if (this.industriesNetwork.loading === true) {
      this.industriesNetwork.cancel();
    }

    let filters = "";
    if (this.industrySearch && this.industrySearch.trim() !== "") {
      filters = `filters: {nameIContains: "${this.industrySearch}"},`;
    }

    const query = `
      query getIndustries {
        viewer {
          industries(${filters} order: [{field: VALUE}]) {
            edges {
              node {
                ...industry
              }
            }
          }
        }
      }

      fragment industry on IndustryNode {
        id
        legacyId
        value
      }
    `;

    this.industriesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query);
    } catch (e) {
      if (this.industriesNetwork.isCancelError(e)) {
        return e;
      }

      this.industriesNetwork.handleError("Getting Client Industries", e);
      if (res !== null) {
        this.industriesNetwork.logGraphQLError("Get Client Industries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getIndustries--success", () => {
      this.industriesNetwork.loading = false;
      this.industriesNetwork.error = null;
      if (this.industriesNetwork.logGraphQLError("Get Client Industries query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const industries = res.data.viewer.industries.edges;

      this.industries = industries.map((industry) => {
        return new Industry(this, industry.node);
      });

      // move industry "All" to first element
      if (
        !!this.industries &&
        this.industries.length > 0 &&
        this.industries[0].id !== 1
      ) {
        let industryAll = null;
        this.industries = this.industries.filter((item: Industry) => {
          if (item.id === 1) {
            industryAll = item;
            return false;
          }
          return true;
        });
        if (industryAll) this.industries = [industryAll, ...this.industries];
      }

      return this.industries;
    });
  }

  // ------------------------------------------------------------
  //
  //   Locations
  //
  // ------------------------------------------------------------

  async onSelectedLocationChange(selectedLocation: ?Location): Promise<void> {
    this.selectedLocation = selectedLocation;
    // this.resetJobTitles();

    if (this.selectedUserCountry) {
      // get locations for selected country
      this.locationSearch = this.selectedUserCountry.toString();
      await this.getLocations();

      if (this.selectedIndustry && this.locationValid()) {
        if (this.jobTitlesSelectedCount === 0) this.addTitleWizard.goTo(3);
        // go to select titles
        else this.addTitleWizard.goTo(4); // go to add titles

        if (this.selectedRateType === RATE_TYPE.HOURLY) {
          await this.getWorkerTypes();
        }
        // load first page of titles
        if (this.selectedUserCountry && this.jobTitles.length === 0) {
          await this.jobTitlesPagination.goFetch();
        }
      } else {
        this.addTitleWizard.goTo(2);
      }

      // this.getLocations().then(() => {
      //   if (this.selectedIndustry && this.locationValid()) {
      //     if (this.jobTitlesSelectedCount === 0) this.addTitleWizard.goTo(3);
      //     // go to select titles
      //     else this.addTitleWizard.goTo(4); // go to add titles
      //
      //     // load first page of titles
      //     this.getWorkerTypes();
      //     if (this.selectedUserCountry && this.jobTitles.length === 0) this.jobTitlesPagination.goFetch();
      //   } else {
      //     this.addTitleWizard.goTo(2);
      //   }
      // });
    } else {
      this.locationSearch = "";
      this.locations = [];
    }
    // this.isGSS = false;
  }

  onLocationSearchChange(query: string): void {
    this.locationSearch = query;

    // load first page of locations
    this.locationsLoadFirstPage();
  }

  resetLocations(): void {
    this.selectedLocation = null;
    this.locations = [];
    this.locationsCurrentPage = 0;
    this.locationsNextPage = 1;
    this.locationSearch = "";
    this.isGSS = false;
  }

  locationsHasNextPage(): boolean {
    return Boolean(this.locationsNextPage);
  }

  locationsLoadNextPage() {
    if (!this.locationSearch || this.locationSearch.trim().length < 2) {
      this.locations = [];
      return;
    }
    if (!this.locationsHasNextPage()) return;

    this.getLocations();
  }

  locationsLoadFirstPage() {
    // if (!this.locationSearch || this.locationSearch.trim().length < 2) {
    //   this.locations = [];
    //   this.locationsNextPage = null; // no next page until locationSearch is 2 chars long
    //   return;
    // }
    this.locationsNextPage = 1;
    this.getLocations();
  }

  onIsGSSChange(value: boolean): void {
    this.isGSS = !this.isGSS;
  }

  onLocationTypeChange(value: number): void {
    this.locationType = value;
    this.getWorkerTypes().catch(() => {});
  }

  locationValid(): boolean {
    return Boolean(
      (this.locationType === LOCATION_TYPE.LOCATION && this.selectedLocation) ||
        (this.locationType === LOCATION_TYPE.REGION && this.selectedRegion)
    );
  }

  async getLocations(): Promise<any> {
    if (!this.selectedUserCountry) return;

    if (!this.locationsHasNextPage()) return;

    if (this.network.loading === true) {
      this.network.cancel();
    }

    this.network.loading = true;
    let res = null;

    let search =
      this.locationSearch && this.locationSearch.trim() !== ""
        ? this.locationSearch
        : null;

    if (!search && this.selectedUserCountry) search = this.selectedUserCountry.toString();

    let countryIds = null;
    if (this.selectedUserCountry) {
      countryIds = [this.selectedUserCountry.id];
    }

    const query = `
      query getLocations($search: String!, $countryIds: [Int]){
        viewer {
          locations(search: $search, countryIds: $countryIds) {
            fullSubtitle
            subtitle
            title
            type
            fullTitle
            highlight {
              subtitle
              title
            }
            locationId
            countryCode
          }
        }
      }
    `;

    const variables = { search, countryIds };

    try {
      res = await this.fetchGraphQL(query, variables, this.network.getCancelToken());
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Client Locations", e);

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getLocations--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        return;
      }

      if (this.locationsNextPage) {
        this.locationsCurrentPage = this.locationsNextPage;
        this.locationsNextPage = res.next ? this.locationsNextPage + 1 : null;
      }

      const locations = res.data.viewer.locations;

      const mappedLocations = locations.map((locationData) => {
        return new Location(this, locationData);
      });

      if (this.locationsCurrentPage === 1) {
        this.locations = mappedLocations;
      } else {
        this.locations = this.locations.concat(mappedLocations);
      }

      return this.locations;
    });
  }

  // ------------------------------------------------------------
  //
  //   Regions
  //
  // ------------------------------------------------------------

  onSelectedRegionChange(selectedRegion: ?Region): void {
    this.selectedRegion = selectedRegion;

    if (this.selectedIndustry && this.locationValid()) {
      this.addTitleWizard.goTo(3);
      this.getWorkerTypes();
      if (this.selectedUserCountry && this.jobTitles.length === 0)
        this.jobTitlesPagination.goFetch();
    } else {
      this.addTitleWizard.goTo(2);
    }
  }

  onRegionSearchChange(query: string): void {
    this.regionSearch = query;
    this.getRegions();
  }

  resetRegions(): void {
    this.selectedRegion = null;
    this.regions = [];
    this.regionSearch = "";
    this.allRegions = [];
  }

  async getAllUserRegions(): Promise<any> {
    if (this.regionsNetwork.loading === true) {
      this.regionsNetwork.cancel();
    }

    const query = `
      query getRegions {
        viewer {
          regions(order: [{field: NAME}]) {
            edges {
              node {
                ...region
              }
            }
          }
        }
      }

      fragment region on NewRegionNode {
        regionId
        name
        country {
          locationId
          isoCode
        }
      }
    `;

    this.regionsNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {}, this.regionsNetwork.getCancelToken());
    } catch (e) {
      if (this.regionsNetwork.isCancelError(e)) {
        return e;
      }

      this.regionsNetwork.handleError("Getting Client Regions", e);
      if (res !== null) {
        this.regionsNetwork.logGraphQLError("Get Client Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getAllUserRegions--success", () => {
      this.regionsNetwork.loading = false;
      this.regionsNetwork.error = null;
      if (this.regionsNetwork.logGraphQLError("Get Client Regions query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const regions = res.data.viewer.regions.edges;

      this.allRegions = regions.map((region) => {
        return new Region(this, region.node);
      });

      return this.allRegions;
    });
  }

  async getRegions(): Promise<any> {
    if (!this.selectedUserCountry) return;

    if (this.regionsNetwork.loading === true) {
      this.regionsNetwork.cancel();
    }

    const filterFields = [];
    let certifiedCountries = [];
    if (this.selectedUserCountry) {
      certifiedCountries.push(this.selectedUserCountry.countryId);
    }
    filterFields.push(`countryIdIn: [${certifiedCountries.join(", ")}]`);

    if (this.regionSearch && this.regionSearch.trim() !== "") {
      filterFields.push(`nameIContains: "${this.regionSearch}"`);
    }

    const filters = `filters: {${filterFields.join(", ")}},`;

    const query = `
      query getRegions {
        viewer {
          regions(${filters} order: [{field: NAME}]) {
            edges {
              node {
                ...region
              }
            }
          }
        }
      }

      fragment region on NewRegionNode {
        regionId
        name
        country {
          locationId
          isoCode
        }
      }
    `;

    this.regionsNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {}, this.regionsNetwork.getCancelToken());
    } catch (e) {
      if (this.regionsNetwork.isCancelError(e)) {
        return e;
      }

      this.regionsNetwork.handleError("Getting Client Regions", e);
      if (res !== null) {
        this.regionsNetwork.logGraphQLError("Get Client Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRegions--success", () => {
      this.regionsNetwork.loading = false;
      this.regionsNetwork.error = null;
      if (this.regionsNetwork.logGraphQLError("Get Client Regions query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const regions = res.data.viewer.regions.edges;

      this.regions = regions.map((region) => {
        return new Region(this, region.node);
      });

      return this.regions;
    });
  }

  // ------------------------------------------------------------
  //
  //   Worker Types
  //
  // ------------------------------------------------------------

  onSelectedWorkerTypeChange(selectedWorkerType: ?WorkerType): void {
    this.selectedWorkerType = selectedWorkerType;
  }

  resetWorkerTypes(): void {
    this.selectedWorkerType = null;
    this.workerTypes = [];
  }

  async getWorkerTypes(): Promise<Array<WorkerType>> {
    if (!this.selectedUserCountry) return [];
    if (this.locationType !== LOCATION_TYPE.LOCATION) return [];
    if (!this.selectedIndustry) return [];

    if (this.workerTypesNetwork.loading === true) {
      this.workerTypesNetwork.cancel();
    }

    const countryId = this.selectedUserCountry.countryId;
    const industryId = this.selectedIndustry.id;

    const variables = { countryId, industryId };

    const query = `
      query WorkerTypes($countryId: Int!, $industryId: Int) {
        viewer {
          workerTypes(countryId: $countryId, industryId: $industryId) {
            id
            name
            description
          }
        }
      }
    `;

    let res = null;
    this.workerTypesNetwork.loading = true;

    try {
      res = await this.fetchGraphQL(
        query,
        variables,
        this.workerTypesNetwork.getCancelToken()
      );
      // should hit get request
    } catch (e) {
      console.error("Error in getting worker types", e);
      this.workerTypesNetwork.loading = false;
      throw e; // Prevent success action from running
    }

    this.workerTypesNetwork.loading = false;

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    runInAction("getWorkerTypes--success", () => {
      this.workerTypes = res?.data?.viewer?.workerTypes || [];
    });

    return this.workerTypes;
  }

  // ------------------------------------------------------------
  //
  //   Rate Cards
  //
  // ------------------------------------------------------------

  onRateCardActionChange(value: number) {
    this.rateCardAction = value;
  }

  onNewRateCardNameChange(name: string): void {
    this.newRateCardName = name;
  }

  onSelectedRateCardChange(selectedRateCard: ?RateCard): void {
    this.selectedRateCard = selectedRateCard;
  }

  onRateCardSearchChange(query: string): void {
    if (this.rateCardSearch === query) return;
    this.rateCardSearch = query;

    // load first page of rate cards
    if (this.network.loading === true) {
      this.network.cancel();
      this.network.loading = false;
    }

    this.rateCards = [];
    this.rateCardsPagination = new PaginationState(this.getRateCards, 10);
    this.rateCardsLoadFirstPage();
  }

  resetRateCards(): void {
    this.selectedRateCard = null;
    this.rateCards = [];
    this.rateCardsPagination = new PaginationState(this.getRateCards, 10);
    this.rateCardSearch = "";
    this.selectedCategory = "";
  }

  rateCardsHasNextPage(): boolean {
    if (!this.rateCardsPagination.hasLoaded) return true;
    return this.rateCardsPagination.currentPage < this.rateCardsPagination.pageCount;
  }

  rateCardsLoadNextPage() {
    if (!this.rateCardsHasNextPage()) return;

    if (!this.rateCards.length) {
      this.rateCardsPagination.goFetch();
    } else {
      this.rateCardsPagination.goFetch();
    }
  }

  rateCardsLoadFirstPage() {
    this.rateCardsPagination.goFetch();
  }

  async getSelectedRateCard(rateCardId: string): Promise<any> {
    if (this.network.loading === true) {
      this.network.cancel();
      // this.rateCardsPagination = new PaginationState(this.getRateCards, {itemsPerPage: 10});
    }

    const query = `
      query getRateCard($rateCardId: Int!) {
        viewer {
          rateCardDetail(id: $rateCardId) {
            ratecardId
            name
            shared
            searchCount
            owner {
              userId
              firstName
              lastName
            }
          }
        }
      }
    `;
    const variables = { rateCardId };

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables, this.network.getCancelToken());
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Card", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return null;
      }

      // NOTE: flow refinement
      if (res === null) {
        return null;
      }

      const rateCardData = res.data.viewer.rateCardDetail;
      if (!rateCardData) {
        this.selectedRateCard = null;
        return null;
      }

      const rateCard = new RateCard(this, rateCardData);
      this.selectedRateCard = rateCard;

      return rateCard;
    });
  }

  async getRateCards(pageQuery: PageQuery): Promise<PaginationInfo> {
    if (!this.rateCardsHasNextPage())
      return { totalCount: 0, startCursor: "", endCursor: "" };

    if (this.network.loading === true) {
      this.network.cancel();
      // this.rateCardsPagination = new PaginationState(this.getRateCards, {itemsPerPage: 10});
    }

    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = ["{ field: NAME, direction: ASC }"];

    if (this.rateCardSearch && this.rateCardSearch.trim() !== "") {
      params.push("$rateCardNameSearch: String!");
      filtersCriteria.push(`nameIContains: $rateCardNameSearch`);
      variables.rateCardNameSearch = this.rateCardSearch;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
      query getRateCards(${queryParams}) {
        viewer {
          allRateCards(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
            edges {
              node {
                ratecardId
                name
                shared
                searchCount
                owner {
                  userId
                  firstName
                  lastName
                }
              }
              cursor
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
          }
        }
      }
    `;

    this.network.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables, this.network.getCancelToken());
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      // NOTE: flow refinement
      if (res === null) {
        return;
      }

      const rateCards = res.data.viewer.allRateCards;

      const mappedRateCards = rateCards.edges.map((rateCard) => {
        // console.log("creating", rateCard);
        return new RateCard(this, rateCard.node);
      });

      if (this.rateCardsPagination.currentPage === 0) {
        this.rateCards = mappedRateCards;
      } else {
        this.rateCards = this.rateCards.concat(mappedRateCards);
      }

      return {
        totalCount: rateCards.totalCount,
        startCursor: rateCards.pageInfo.startCursor,
        endCursor: rateCards.pageInfo.endCursor,
      };
    });
  }

  // ------------------------------------------------------------
  //
  //   Batch Search
  //
  // ------------------------------------------------------------

  jobsToSearchItemsToggleAllSelected(): void {
    const selected = !this.jobsToSearchItemsAllSelected;
    this.jobsToSearchItemsView.forEach((jobItem) => {
      jobItem.setSelected(selected);
    });
  }

  addJobToBatchSearch(): void {
    // const title = this.jobTitles;
    const country = this.selectedUserCountry;
    const industry = this.selectedIndustry;
    const location = this.selectedLocation;
    const region = this.selectedRegion;
    const isGSS = this.isGSS;
    const workerType =
      this.locationType === LOCATION_TYPE.LOCATION ? this.selectedWorkerType : null;

    if (location && country) location.countryCode = country.countryCode;

    this.infoMessage = null;
    if (this.jobTitlesSelectedCount === 0) {
      this.infoMessage = <p>You must select at least one Job Title.</p>;
    } else if (
      this.jobTitlesSelectedCount + this.jobsToSearchItemsTotalCount >
      this.jobsToSearchLimit
    ) {
      this.infoMessage = (
        <p>
          Batch Search has a limit of {`${this.jobsToSearchLimit}`} searches. Please
          remove some of your searches before continuing.
        </p>
      );
    } else if (!industry) {
      this.infoMessage = <p>You must select an Industry.</p>;
    } else if (!location && !region) {
      this.infoMessage = <p>You must select a Location or a Region.</p>;
    }

    if (this.infoMessage) {
      this.infoModal.showModal();
      return;
    }

    this.getSelectedJobTitlesDescription().then(() => {
      this.jobTitlesSelected.values().forEach((title: RawJobTitle) => {
        // flow check, this will never happen at this point
        // but flow does not detect that
        if (!title || !industry || (!location && !region)) return;

        const jobId = BatchSearchJob.getId(title.id, industry.id, workerType?.id);
        let job: ?BatchSearchJob = this.jobsToSearch.find((job) => job.id === jobId);

        if (!job) {
          job = new BatchSearchJob(title, industry, [], [], [], workerType);
          this.jobsToSearch.push(job);

          this.jobsToSearch = this.jobsToSearch.sort((job1, job2) => {
            const title1 = job1.jobTitle.title.toLowerCase();
            const title2 = job2.jobTitle.title.toLowerCase();
            return _compareStrings(title1, title2);
          });
        }

        if (this.locationType === LOCATION_TYPE.LOCATION && location)
          job.addLocation(location, isGSS);
        if (this.locationType === LOCATION_TYPE.REGION && region) job.addRegion(region);

        if (this.jobsToRemoveBeforeAdding && this.jobsToRemoveBeforeAdding.length > 0) {
          this.jobsToRemoveBeforeAdding.forEach((jobToRemove) => {
            this.removeJobFromSearch(jobToRemove);
          });
          this.jobsToRemoveBeforeAdding = [];
        }
      });

      this.showAddJobTitlesWizard = false;
    });
  }

  addMoreJobs(clear: boolean = false) {
    if (clear) {
      this.selectedUserCountry = null;
      this.resetLocations();
      this.resetRegions();
      this.resetIndustries();
      this.resetJobTitles();
      this.resetWorkerTypes();
      this.addTitleWizard.goTo(1);
    }

    this.showAddJobTitlesWizard = true;
  }

  removeJobFromSearch(jobListItem: BatchSearchJobListItem): void {
    const job = jobListItem.batchSearchJob;
    const location = jobListItem.batchSearchJobLocation;
    const isGSS = jobListItem.isGSS;

    if (location instanceof Region) {
      job.removeRegion(location);
    } else {
      job.removeLocation(location, isGSS);
    }

    const noRegionsLeft = job.regions.length === 0;
    const noLocationsLeft = job.locations.length === 0;
    const noGSSLocationsLeft = job.gssLocations.length === 0;

    if (noRegionsLeft && noLocationsLeft && noGSSLocationsLeft) {
      // filter out this job to remove it from the list
      this.jobsToSearch = this.jobsToSearch.filter((item: BatchSearchJob) => {
        return item.id !== job.id;
      });

      this.showAddJobTitlesWizard = this.jobsToSearch.length === 0;
    }

    // remove item view state
    if (this.jobsToSearchItemsViewState.has(jobListItem.id)) {
      this.jobsToSearchItemsViewState.delete(jobListItem.id);
    }
  }

  onRemoveInvalidJobsFromSearch(): void {
    if (!this.invalidJobs) return;

    this.invalidJobs.forEach((invalidJob) => {
      this.removeJobFromSearch(invalidJob);
    });

    this.invalidJobsErrorModal.hideModal();
    this.invalidJobs = null;

    if (this.jobsToSearch.length === 0) {
      this.wizard.goTo(2);
      this.showAddJobTitlesWizard = true;
    }
  }

  findInvalidJobs(invalidJobsData: InvalidJobData[]): BatchSearchJobListItem[] {
    return invalidJobsData.map((jobData) => {
      let foundLocation = null;
      let isGss = false;

      // find the batch search job
      const jobId = BatchSearchJob.getId(
        jobData.jobOriginalTitleId,
        jobData.industryId,
        jobData?.invalidWorkerTypeId
      );
      const foundJob = this.jobsToSearch.find((batchSearchJob) => {
        if (batchSearchJob.id !== jobId) return false;

        foundLocation = batchSearchJob.locations.find(
          (loc) => loc.id.toString() === jobData.invalidLocationId
        );
        if (foundLocation) return true;
        foundLocation = batchSearchJob.regions.find(
          (reg) => reg.id.toString() === jobData.invalidLocationId
        );
        if (foundLocation) return true;
        foundLocation = batchSearchJob.gssLocations.find(
          (loc) => loc.id.toString() === jobData.invalidLocationId
        );
        if (foundLocation) {
          isGss = true;
          return true;
        }

        return false;
      });

      // batch search job must exist
      if (!foundJob || !foundLocation) {
        throw new DataError(
          "Invalid Job received but not found on batch search.",
          jobData
        );
      }

      // find the batch search job list item
      const jobItemId = BatchSearchJobListItem.getId(
        foundJob.jobTitle.id,
        foundJob.industry.id,
        foundLocation.id,
        isGss,
        foundJob.workerType?.id || "none"
      );
      const foundItem = this.jobsToSearchItems.find(
        (jobItem) => jobItem.id === jobItemId
      );

      // list item must exist
      if (!foundItem) {
        throw new DataError(
          "Invalid Job was found but not the view item that job.",
          jobData
        );
      }

      return foundItem;
    });
  }

  editJobToSearch(jobListItem: BatchSearchJobListItem): void {
    const job = jobListItem.batchSearchJob;
    const location = jobListItem.batchSearchJobLocation;
    const isGSS = jobListItem.isGSS;

    const country = this.userCountries.find((c: Location) => {
      return c.countryId === location.countryId || c.countryCode === location.countryCode;
    });
    if (!country) {
      console.error("country not found!");
      return;
    }
    this.selectedUserCountry = country;
    this.selectedIndustry = job.industry;

    this.resetJobTitles();
    this.jobTitleSearch = job.jobTitle.title;
    this.jobTitlesPagination.goFetch().then(() => {
      job.jobTitle.store = this;
      job.jobTitle.getDescription();
      job.jobTitle.toggleSelected(true);
    });

    Promise.all([this.getLocations(), this.getRegions()]).then(() => {
      if (location instanceof Region) {
        this.locationType = LOCATION_TYPE.REGION;
        this.selectedRegion = location;
      } else {
        this.locationType = LOCATION_TYPE.LOCATION;
        this.selectedLocation = location;
        this.isGSS = isGSS;
      }

      // job.jobTitle.getDescription();
      // job.jobTitle.toggleSelected(true)
    });

    this.resetWorkerTypes();
    if (job.workerType) {
      this.getWorkerTypes().then(() => {
        this.selectedWorkerType = this.workerTypes.find(
          (wt) => wt.id === job.workerType?.id
        );
      });
    }

    this.removeJobFromSearch(jobListItem);
    this.addTitleWizard.goTo(4);
    this.showAddJobTitlesWizard = true;
  }

  addNewLocationForAllJobs(): void {
    if (!this.jobsToSearchItemsAllInSameCountry) return;

    const selectedJobs = this.jobsToSearchItems;
    this.jobsToRemoveBeforeAdding = [];

    // select country
    let selectedCountryCode = selectedJobs[0].batchSearchJobLocation.countryCode;
    this.selectedUserCountry = this.userCountries.find(
      (c: Location) => c.countryCode === selectedCountryCode
    );

    // select location
    this.resetLocations();
    this.resetRegions();
    this.locationType = LOCATION_TYPE.LOCATION;

    this.getLocations();
    this.getRegions();

    // select industry
    this.selectedIndustry = selectedJobs[0].batchSearchJob.industry;

    // select titles
    this.resetJobTitles();
    const seenTitles = [];
    selectedJobs.forEach((job) => {
      if (seenTitles.includes(job.batchSearchJob.jobTitle.id)) return;

      if (!this.jobTitlesViewState.has(job.batchSearchJob.jobTitle.id)) {
        this.jobTitlesViewState.set(job.batchSearchJob.jobTitle.id, {
          selected: false,
          editing: false,
          expanded: false,
        });
      }

      job.batchSearchJob.jobTitle.viewState = this.jobTitlesViewState.get(
        job.batchSearchJob.jobTitle.id
      );
      job.batchSearchJob.jobTitle.toggleSelected(true);
      seenTitles.push(job.batchSearchJob.jobTitle.id);

      // reset industries if they're not the same across all selected jobs
      if (
        this.selectedIndustry &&
        this.selectedIndustry.id !== job.batchSearchJob.industry.id
      )
        this.resetIndustries();
    });
    this.jobTitlesPagination.goFetch().then(() => {
      this.addTitleWizard.goTo(2);
    });

    // show add titles form
    this.showAddJobTitlesWizard = true;
  }

  changeLocationForAllSelectedJobs(): void {
    if (!this.jobsToSearchItemsSelectedAllInSameCountry) return;

    const selectedJobs = this.jobsToSearchItems.filter((job) => job.viewState.selected);
    this.jobsToRemoveBeforeAdding = selectedJobs;

    // select country
    let selectedCountryCode = selectedJobs[0].batchSearchJobLocation.countryCode;
    this.selectedUserCountry = this.userCountries.find(
      (c: Location) => c.countryCode === selectedCountryCode
    );

    // select location
    this.resetLocations();
    this.resetRegions();
    this.locationType = LOCATION_TYPE.LOCATION;

    this.getLocations();
    this.getRegions();

    // select industry
    this.selectedIndustry = selectedJobs[0].batchSearchJob.industry;

    // select titles
    this.resetJobTitles();
    const seenTitles = [];
    selectedJobs.forEach((job) => {
      if (seenTitles.includes(job.batchSearchJob.jobTitle.id)) return;

      if (!this.jobTitlesViewState.has(job.batchSearchJob.jobTitle.id)) {
        this.jobTitlesViewState.set(job.batchSearchJob.jobTitle.id, {
          selected: false,
          editing: false,
          expanded: false,
        });
      }

      job.batchSearchJob.jobTitle.viewState = this.jobTitlesViewState.get(
        job.batchSearchJob.jobTitle.id
      );
      job.batchSearchJob.jobTitle.toggleSelected(true);
      seenTitles.push(job.batchSearchJob.jobTitle.id);

      // reset industries if they're not the same across all selected jobs
      if (
        this.selectedIndustry &&
        this.selectedIndustry.id !== job.batchSearchJob.industry.id
      )
        this.resetIndustries();
    });
    this.jobTitlesPagination.goFetch().then(() => {
      this.addTitleWizard.goTo(2);
    });

    // show add titles form
    this.showAddJobTitlesWizard = true;
  }

  validateStep(step: number): boolean {
    switch (step) {
      case 1:
        if (!Boolean(this.selectedRateType)) {
          this.infoMessage = <div>Select a Rate Type to continue</div>;
          this.infoModal.showModal();
          return false;
        }
        return true;
      case 2:
        if (this.jobsToSearch.length === 0) {
          this.infoMessage = (
            <div>
              Add at least one title in a specific location to the batch search in order
              to continue
            </div>
          );
          this.infoModal.showModal();
          return false;
        }
        return true;
      case 3:
        if (
          this.rateCardAction === RATE_CARD_ACTION.USE_EXISTING &&
          !Boolean(this.selectedRateCard)
        ) {
          this.infoMessage = <div>Select a Rate Card to continue</div>;
          this.infoModal.showModal();
          return false;
        } else if (
          this.rateCardAction === RATE_CARD_ACTION.CREATE &&
          !Boolean(this.newRateCardName)
        ) {
          this.infoMessage = <div>Enter Rate Card name to continue</div>;
          this.infoModal.showModal();
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  onCurrentStepChange(currentStep: number): void {
    switch (currentStep) {
      case 2:
        this.resetJobTitles();
        this.resetIndustries();
        this.resetLocations();
        this.resetRegions();
        this.locationType = LOCATION_TYPE.LOCATION;
        break;
      default:
        break;
    }
  }

  addTitleValidateStep(step: number): boolean {
    switch (step) {
      // case 1:
      //   if (!Boolean(this.selectedRateType)) {
      //     this.infoMessage = <p>Select a Rate Type to continue</p>;
      //     this.infoModal.showModal();
      //     return false;
      //   }
      //   return true;
      // case 2:
      //   if (this.jobsToSearch.length === 0) {
      //     this.infoMessage = (
      //       <p>Add at least one title in a specific location to the batch search in order to continue</p>
      //     );
      //     this.infoModal.showModal();
      //     return false;
      //   }
      //   return true;
      // case 3:
      //   if (this.rateCardAction === RATE_CARD_ACTION.USE_EXISTING && !Boolean(this.selectedRateCard)) {
      //     this.infoMessage = <p>Select a Rate Card to continue</p>;
      //     this.infoModal.showModal();
      //     return false;
      //   } else if (this.rateCardAction === RATE_CARD_ACTION.CREATE && !Boolean(this.newRateCardName)) {
      //     this.infoMessage = <p>Enter Rate Card name to continue</p>;
      //     this.infoModal.showModal();
      //     return false;
      //   }
      //   return true;
      default:
        return true;
    }
  }

  addTitleOnCurrentStepChange(currentStep: number): void {
    switch (currentStep) {
      // case 2:
      //   this.resetJobTitles();
      //   this.resetIndustries();
      //   this.resetLocations();
      //   this.resetRegions();
      //   this.locationType = LOCATION_TYPE.LOCATION;
      //   break;
      default:
        break;
    }
  }

  resetAll() {
    this.selectedRateType = null;
    this.onlyCountryIds = null;
    this.resetUserCountries();
    this.resetLibraries();
    this.resetJobTitles();
    this.resetIndustries();
    this.resetLocations();
    this.resetRegions();
    this.resetWorkerTypes();
    this.resetRateCards();
    this.jobsToSearch = [];
    this.jobsToSearchItemsViewState = observable.map({});
    this.jobsToRemoveBeforeAdding = null;
    this.invalidJobs = null;
    this.clearJobsToSearchFilters();
    this.creatingFromLibrary = false;
  }

  cancel() {
    if (!this.router) return;
    const router = this.router;

    this.showAddJobTitlesWizard = true;
    this.addTitleWizard.goTo(1);
    this.wizard.goTo(1);
    this.resetAll();
    router.goBack();
  }

  setUpBatchSearchFromJobLibrary(
    libraryId: number,
    isCountryLibrary: boolean = false
  ): Promise<any> {
    if (!libraryId) return Promise.reject("LibraryId param was not specified.");

    this.network.loading = true;
    this.wizard.goTo(1); // stay on first step until finish loading

    this.creatingFromLibrary = true;

    // for country libraries
    if (isCountryLibrary) {
      this.onlyCountryIds = [libraryId];
      return this.getUserCountries()
        .then(() => {
          this.selectedUserCountry = this.userCountries.find(
            (country) => country.id.toString() === libraryId.toString()
          );
          this.selectedLocation = this.selectedUserCountry;
          this.jobTitlesPagination.goFetch().then(() => {
            this.selectAllJobTitles().then(() => {
              this.wizard.goTo(2);
              this.addTitleWizard.goTo(4);
              this.showAddJobTitlesWizard = true;
              this.network.loading = false;
            });
          });
          return true;
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = (
            <p>
              Sorry! we could not setup the Batch Search. There was an error during the
              operation.
            </p>
          );
          this.errorModal.showModal();
          this.wizard.goTo(1);
          this.network.loading = false;
        });
    }

    // for a regular library
    return Promise.all([this.getLibraries([libraryId], true)])
      .then(() => {
        this.selectedLibrary = this.clientLibraries.find(
          (lib) => lib.id.toString() === libraryId.toString()
        );

        if (this.selectedLibrary && this.selectedLibrary.searchableCountries) {
          this.onlyCountryIds = this.selectedLibrary.searchableCountries;
        }

        this.wizard.goTo(2);
        this.showAddJobTitlesWizard = true;
        this.addTitleWizard.goTo(1);
        this.network.loading = false;
        return true;
      })
      .catch((error) => {
        console.error(error);
        this.errorMessage = (
          <p>
            Sorry! we could not setup the Batch Search. There was an error during the
            operation.
          </p>
        );
        this.errorModal.showModal();
        this.wizard.goTo(1);
        this.network.loading = false;
      });
  }

  async createAndRunBatchSearch(): Promise<any> {
    if (this.network.loading) return; // I don't wanna call it twice

    // if for some reason we got here without going through the whole process
    // check that all steps are valid.
    for (let i = 1; i < 4; i++) {
      if (!this.validateStep(1)) return;
    }

    const jobs = this.jobsToSearch.map((item) => item.toJsonObject(this.isGSS));
    const rateType = this.selectedRateType ? this.selectedRateType.value : null;
    const rateCardName =
      this.rateCardAction === RATE_CARD_ACTION.CREATE
        ? this.newRateCardName
        : this.selectedRateCard
        ? this.selectedRateCard.name
        : null;

    this.network.loading = true;
    let res = null;

    const variables = {
      input: {
        rateCardLabel: rateCardName,
        rateType: rateType,
        jobs: jobs,
      },
    };
    const query = `
      mutation createBatchSearch($input : CreateBatchSearchInput!){
        createBatchSearch(input:$input){
          batchSearchUuid

          errors {
            __typename
            ...on InvalidJobsError {
              jobs {
                jobOriginalTitleId
                industryId
                invalidLocationId
                invalidCountryId
              }
            }
          }
        }
      }
    `;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      if (e.response && e.response.status === 400 && e.response.data.jobs) {
        this.invalidJobs = this.findInvalidJobs(e.response.data.jobs);
        this.invalidJobsErrorModal.showModal();
      } else {
        this.network.handleError("Creating Batch Search", e);
        this.errorMessage = (
          <p>
            Sorry! Batch Search could not run. There was an error during the operation.
          </p>
        );
        this.errorModal.showModal();
      }

      this.network.loading = false;
      return e;
    }

    return runInAction("createAndRunBatchSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        this.errorMessage = (
          <p>
            Sorry! Batch Search could not run. There was an error during the operation.
          </p>
        );
        this.errorModal.showModal();
        return;
      }

      if (res.data.createBatchSearch.errors) {
        const errors = res.data.createBatchSearch.errors;
        const invalidJobsError = errors.find(
          (err) => err.__typename === "InvalidJobsError"
        );
        if (invalidJobsError) {
          this.invalidJobs = this.findInvalidJobs(invalidJobsError.jobs);
          this.invalidJobsErrorModal.showModal();
        } else {
          this.errorMessage = (
            <p>
              Sorry! Batch Search could not run. There was an error during the operation.
            </p>
          );
          this.errorModal.showModal();
          return;
        }
      }

      const batchSearchUUID = res.data.createBatchSearch.batchSearchUuid;
      if (!batchSearchUUID) {
        console.error("no search id received.");
        this.errorMessage = (
          <p>
            Sorry! Batch Search could not be created. There was an error during the
            operation.
          </p>
        );
        this.errorModal.showModal();
        return null;
      }

      this.resetAll();
      this.wizard.goTo(1);

      if (!this.router) return batchSearchUUID;

      this.router.push(`/batch-searches/${batchSearchUUID}/`);

      return batchSearchUUID;
    });
  }

  async rerunBatchSearch(searchUUID: string): Promise<any> {
    if (!searchUUID) return;
    if (this.network.loading) return;

    this.wizard.goTo(1);
    this.addTitleWizard.goTo(1);
    this.showAddJobTitlesWizard = false;
    this.network.loading = true;
    let res = null;

    const query = `
      query getBatchSearch($batchSearchId: String!) {
        viewer {
          batchSearch(batchSearchUuid: $batchSearchId) {
            uuid
            rateCardId
            rateCardLabel
            rateType
            finished
            statusDisplay
            searchesTotal
            rateCardLabel
            status
            updated
            created
            jobs {
              originalTitleId
              industryId
              jobDescription
              jobLabel
              jobCategory
              jobTitle
              regionIds
              workerType {
                id
                name
              }
              locations {
                locationId
                apiLocationId
                title
                subtitle
                fullTitle
                fullSubtitle
                countryId
                isoCode
              }
              gssLocations {
                locationId
                apiLocationId
                title
                subtitle
                fullTitle
                fullSubtitle
                countryId
                isoCode
              }
            }
          }
        }
      }
    `;

    const variables = {
      batchSearchId: searchUUID,
    };

    try {
      res = await this.fetchGraphQL(query, variables);
      // res = await this.saraApi.getBatchSearchDetailsFake(searchUUID);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Rerunning Batch Search", e);

      this.errorMessage = (
        <p>
          Sorry! we could not recreate the Batch Search. There was an error during the
          operation.
        </p>
      );
      this.errorModal.showModal();
      return e;
    }

    return runInAction("rerunBatchSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        this.errorMessage = (
          <p>
            Sorry! we could not recreate the Batch Search. There was an error during the
            operation.
          </p>
        );
        this.errorModal.showModal();
        this.wizard.goTo(1);
        return;
      }

      const batchSearchData = res.data.viewer.batchSearch;

      this.network.loading = true;
      const rateCardId = batchSearchData.rateCardId;
      Promise.all([
        this.getUserCountries(),
        this.getIndustries(),
        this.getAllUserRegions(),
        this.getSelectedRateCard(rateCardId),
      ])
        .then(() => {
          this.network.loading = false;

          // setup rate type
          const rateType = batchSearchData.rateType;
          this.selectedRateType = this.rateTypes.find((item) => item.value === rateType);

          // setup jobs & locations
          try {
            this.jobsToSearch = batchSearchData.jobs.map((jobData) => {
              // job_label: "",
              // job_title: "",
              // job_category: "",
              // original_title_id: 1,
              // industry_id: 1,
              // workerType: {id, name},
              // location: [{}, {}, {}],  # locations will retrieved from locations api
              // gss_location_ids: [{}, {}, {}],  # locations will retrieved from locations api
              // region_ids: [1, 2, 3],

              const rawJobTitleData = {
                id: jobData.originalTitleId,
                title: jobData.jobTitle,
                description: jobData.jobDescription,
                clientJobLibraryInfo: {
                  categoryName: jobData.jobCategory,
                  mappedRawJobTitleTitle: jobData.jobTitle,
                },
              };

              const findCountryCode = function (countryId, store) {
                const country = store.userCountries.find((item) => {
                  if (!item || !item.countryId || !countryId) return false;
                  return item.countryId.toString() === countryId.toString();
                });
                if (country) return country.countryCode;
                else return "";
              };

              const rawJobTitle = new RawJobTitle(this, rawJobTitleData);
              const industry = this.industries.find(
                (item) => item.id === jobData.industryId
              );
              const workerType = jobData.workerType;

              const regions = jobData.regionIds.map((regionId) => {
                const found = this.allRegions.find((item) => {
                  if (item.id !== regionId) return false;
                  if (!item.countryCode)
                    item.countryCode = findCountryCode(item.countryId, this);
                  return true;
                });
                if (!found) {
                  throw new RerunError(
                    "RerunBatchSearchError",
                    "Could not recreate the Batch Search. " +
                      "A region was used on the batch search that is no longer available."
                  );
                }

                return found;
              });

              let locations = jobData.locations.map((locationData) => {
                const location = new Location(this, locationData);
                if (!location.countryCode)
                  location.countryCode = findCountryCode(location.countryId, this);
                return location;
              });
              // Silently filter out locations that have been disabled in ccc
              locations = locations.filter(
                (location) => location.toString() && location.toString() !== ""
              );

              let gssLocations = jobData.gssLocations.map((locationData) => {
                const location = new Location(this, locationData);
                if (!location.countryCode)
                  location.countryCode = findCountryCode(location.countryId, this);
                return location;
              });
              // Silently filter out locations that have been disabled in ccc
              gssLocations = gssLocations.filter(
                (location) => location.toString() && location.toString() !== ""
              );

              if (!industry) {
                throw new RerunError(
                  "RerunBatchSearchError",
                  "Could not recreate the Batch Search. " +
                    "An industry was used on the batch search that is no longer available."
                );
              }

              return new BatchSearchJob(
                rawJobTitle,
                industry,
                locations,
                gssLocations,
                regions,
                workerType
              );
            });

            // Silently filter out jobs with no locations. This can be caused
            // when we silently filter out locations that have been disable in ccc
            this.jobsToSearch = this.jobsToSearch.filter(
              (job) =>
                job.locations.length > 0 ||
                job.gssLocations.length > 0 ||
                job.regions.length > 0
            );

            this.wizard.goTo(4);
          } catch (error) {
            this.network.loading = false;
            if (error.name === "RerunBatchSearchError") {
              this.errorMessage = <p>{error.message}</p>;
            } else {
              this.errorMessage = (
                <p>
                  Sorry! we could not recreate the Batch Search. There was an error during
                  the operation.
                </p>
              );
            }
            this.errorModal.showModal();
            this.wizard.goTo(1);
            this.resetAll();
            console.error(error);
          }

          // setup rate card
          // already done in this.getSelectedRateCard(rateCardId) above
          this.rateCardAction = RATE_CARD_ACTION.USE_EXISTING;

          // check if ratecard exists
          if (!this.selectedRateCard) {
            this.infoMessage = (
              <p>
                The Rate Card used for the original Batch Search was not found. Please
                indicate which Rate Card to use for this Batch Search.
              </p>
            );
            this.infoModal.showModal();
            this.wizard.goTo(3);
          }

          // check if jobs where ignored
          if (this.jobsToSearch.length < batchSearchData.jobs.length) {
            this.errorMessage = (
              <p>
                {this.jobsToSearch.length > 0 ? "Some" : "All"} jobs in this Batch Search
                were removed due to unavailable locations.
              </p>
            );
            this.errorModal.showModal();
            this.showAddJobTitlesWizard = this.jobsToSearch.length === 0;
            this.wizard.goTo(2);
          }
        })
        .catch((reason) => {
          console.error(reason);
          this.network.loading = false;
          this.errorMessage = (
            <p>
              Sorry! we could not recreate the Batch Search. There was an error during the
              operation.
            </p>
          );
          this.errorModal.showModal();
          this.wizard.goTo(1);
          this.resetAll();
        });

      return res;
    });
  }

  // ------------------------------------------------------------
  //
  //   Jobs to Search filters
  //
  // ------------------------------------------------------------

  onJobsToSearchSelectedIndustryFilterChange(filter: ?ItemFilter) {
    this.jobsToSearchSelectedIndustryFilter = filter;
  }

  onJobsToSearchSelectedCategoryFilterChange(filter: ?ItemFilter) {
    this.jobsToSearchSelectedCategoryFilter = filter;
  }

  onJobsToSearchTitleFilterChange(query: string) {
    this.jobsToSearchTitleFilter = query;
  }

  filterJobsToSearch() {
    this.jobsToSearchFiltersModal.hideModal();
    const appliedFilters: ItemFilter[] = [];

    if (this.jobsToSearchTitleFilter) {
      appliedFilters.push(
        new ContainsItemFilter(
          ["title", "searchedTitle"],
          this.jobsToSearchTitleFilter,
          "titleFilter"
        )
      );
    }

    if (this.jobsToSearchSelectedIndustryFilter) {
      appliedFilters.push(this.jobsToSearchSelectedIndustryFilter);
    }

    if (this.jobsToSearchSelectedCategoryFilter) {
      appliedFilters.push(this.jobsToSearchSelectedCategoryFilter);
    }

    this.jobsToSearchAppliedFilters = appliedFilters; // this should trigger filtering
  }

  clearJobsToSearchFilters() {
    this.jobsToSearchFiltersModal.hideModal();
    this.jobsToSearchSelectedIndustryFilter = null;
    this.jobsToSearchSelectedCategoryFilter = null;
    this.jobsToSearchTitleFilter = "";
    this.jobsToSearchAppliedFilters = [];
  }

  // ------------------------------------------------------------
  //
  //   Bulk Change Industry
  //
  // ------------------------------------------------------------

  bulkChangeIndustryModalOnSelectedIndustryChange(industry: ?Industry) {
    this.bulkChangeIndustryModalSelectedIndustry = industry;
  }

  bulkChangeIndustryModalOnCommitChange() {
    const selectedIndustry = this.bulkChangeIndustryModalSelectedIndustry;
    const selectedJobs = this.jobsToSearchItems.filter((job) => job.viewState.selected);
    const hashTable = {};

    if (!selectedIndustry) {
      return;
    }

    // loop through selected items to changes
    selectedJobs.forEach((jobItem) => {
      // hash item (without industry), we want to avoid duplicates that would be
      // created by bulk changing the industry of two or more items that are
      // the same title, and location but searched on a different industry.
      const job = jobItem.batchSearchJob.jobTitle;
      const industry = "";
      const location = jobItem.batchSearchJobLocation;
      const isGSS = jobItem.isGSS;
      const workerTypeId = jobItem.batchSearchJob.workerType?.id || "none";
      const itemHash = BatchSearchJobListItem.getId(
        job.id,
        industry,
        location.id,
        isGSS,
        workerTypeId
      );

      // if hash exists
      if (hashTable[itemHash]) {
        // remove item from list
        jobItem.setSelected(false);
        this.removeJobFromSearch(jobItem);
      } else {
        // change item
        jobItem.id = BatchSearchJobListItem.getId(
          job.id,
          selectedIndustry.id,
          location.id,
          isGSS,
          workerTypeId
        );
        jobItem.batchSearchJob.industry = selectedIndustry;
        jobItem.industry = selectedIndustry.name || "";
        jobItem.setSelected(true);

        hashTable[itemHash] = true;
      }
    });

    // clean up
    this.industrySearch = "";
    this.bulkChangeIndustryModalSelectedIndustry = null;
    this.bulkChangeIndustryModal.hideModal();
  }

  bulkChangeIndustryModalOnCancel() {
    this.industrySearch = "";
    this.bulkChangeIndustryModalSelectedIndustry = null;
    this.bulkChangeIndustryModal.hideModal();
  }

  // ------------------------------------------------------------
  //
  //   Bulk Remove Jobs To Search Items
  //
  // ------------------------------------------------------------

  bulkRemoveJobsModalOnConfirm() {
    const selectedJobs = this.jobsToSearchItems.filter((job) => job.viewState.selected);
    // if (selectedJobs.length === this.jobsToSearchItemsView.length) {
    //   this.clearJobsToSearchFilters();
    // }

    selectedJobs.forEach((jobItem) => this.removeJobFromSearch(jobItem));
    this.bulkRemoveJobsModal.hideModal();
  }
}

export class BatchSearchPSPCreateStore {
  fetchTasteGraphQL: FetchGraphQL;
  fetchGraphQL: FetchGraphQL;
  fetchAPI: FetchAPI;
  mobXStore: MobXStore;
  network: NetworkState;
  wizard: WizardState;
  router: ?Router;
  errorModal: ModalState;
  errorMessage: ?Element<any>;
  invalidJobsErrorModal: ModalState;
  infoModal: ModalState;
  infoMessage: ?Element<any>;
  addTitleWizard: WizardState;

  // rate type
  selectedRateType: ?Option;
  rateTypes: Option[];
  onRateTypeChange: (Option) => void;

  // userCountries
  // userCountriesNetwork: NetworkState; // need separate network state to load in parallel
  // userCountries: Location[];
  // selectedUserCountry: ?Location;
  // userCountrySearch: string;
  // onUserCountrySearchChange: string => void;
  // onSelectedUserCountryChange: (?Location) => void;
  // getUserCountries: () => Promise<any>;
  // resetUserCountries: () => void;
  // skipResetJobsOnChange: boolean;

  // job titles
  jobTitlesNetwork: NetworkState; // need separate network state to load in parallel
  jobTitlesPagination: PaginationState;
  jobTitles: RawJobTitle[];
  selectedJobTitle: ?RawJobTitle;
  onSelectedJobTitleChange: (?RawJobTitle) => void;
  jobTitleSearch: string;
  onJobTitleSearchChange: (string) => void;
  resetJobTitles: () => void;
  getJobTitles: (PageQuery) => Promise<any>;
  jobLabelValue: string;
  jobDescriptionValue: string;
  onJobLabelChange: (string) => void;
  onJobDescriptionChange: (string) => void;
  debouncedRefetchJobTitles: () => void;
  debouncedJobTitlesNextPage: () => void;
  checkLabelNetwork: NetworkState;
  jobLabelHelperText: string;
  jobLabelIsUnique: boolean;
  jobDescriptionNetwork: NetworkState;
  // jobTitleSelectionEnabled: boolean;
  // jobTitlesCountNetwork: NetworkState; // need separate network state to load in parallel
  // jobTitlesSelected: Object;
  // jobTitlesViewState: Object;
  // jobTitlesView: RawJobTitle[];
  // jobTitlesCurrentPage: number;
  // jobTitlesNextPage: ?number;
  // jobTitlesHasNextPage: () => boolean;
  // jobTitlesLoadNextPage: () => void;
  // jobTitlesLoadFirstPage: () => void;
  // jobTitlesAllSelected: boolean;
  // jobTitlesSelectedCount: number;
  // jobTitlesTotalMatchesCount: number;
  // getSelectedJobTitlesDescription: () => Promise<any>;
  // getJobTitlesCount: () => Promise<any>;
  // selectAllJobTitles: () => Promise<any>;
  // onJobTitlesSelectAllPage: () => void;
  // onJobTitlesSelectAllMatches: () => void;
  // onJobTitlesClearSelection: () => void;

  // tags
  tagsNetwork: NetworkState;
  tags: Array<Tag>;
  selectedTags: Array<Tag>;
  onSelectedTagsChange: (Array<Tag>) => void;
  tagSearch: string;
  getTagsWithSearch: (search: string) => Promise<Array<Tag>>;
  getSelectedJobLabelTags: () => Promise<Array<Tag>>;
  createTag: (name: string) => Promise<Tag>;
  resetTags: () => void;

  // industry
  industriesNetwork: NetworkState; // need separate network state to load in parallel
  industries: Industry[];
  selectedIndustry: ?Industry;
  industrySearch: string;
  onIndustrySearchChange: (string) => void;
  onSelectedIndustryChange: (?Industry) => void;
  getIndustries: () => Promise<any>;
  resetIndustries: () => void;

  // location
  locations: Location[];
  selectedLocation: ?Location;
  selectedLocations: Array<Location>;
  locationSearch: string;
  onLocationSearchChange: (string) => void;
  onSelectedLocationChange: (?Location) => Promise<void>;
  onSelectedLocationsChange: (?Array<Location>) => Promise<void>;
  getLocations: () => Promise<any>;
  locationsCurrentPage: number;
  locationsNextPage: ?number;
  locationsHasNextPage: () => boolean;
  locationsLoadNextPage: () => void;
  locationsLoadFirstPage: () => void;
  isGSS: boolean; // GSS: Global Supplier Search
  onIsGSSChange: (boolean) => void;
  locationType: number; // LOCATION_TYPE from "../../constants/locations"
  onLocationTypeChange: (number) => void;
  showGSSOption: boolean;
  resetLocations: () => void;

  // regions
  regionsNetwork: NetworkState; // need separate network state to load in parallel
  regions: Region[];
  allRegions: Region[];
  selectedRegion: ?Region;
  regionSearch: string;
  onRegionSearchChange: (string) => void;
  onSelectedRegionChange: (?Region) => void;
  getRegions: () => Promise<any>;
  getAllUserRegions: () => Promise<any>;
  resetRegions: () => void;

  // worker types
  workerTypesNetwork: NetworkState;
  workerTypes: Array<WorkerType>;
  selectedWorkerType: ?WorkerType;
  onSelectedWorkerTypeChange: (?WorkerType) => void;
  getWorkerTypes: () => Promise<Array<WorkerType>>;
  resetWorkerTypes: () => void;
  showWorkerTypes: boolean;

  // rate cards
  rateCards: RateCard[];
  rateCardsPagination: PaginationState;
  rateCardsHasNextPage: () => boolean;
  rateCardsLoadNextPage: () => void;
  rateCardsLoadFirstPage: () => void;
  rateCardSearch: string;
  selectedRateCard: ?RateCard;
  onRateCardSearchChange: (string) => void;
  onSelectedRateCardChange: (?RateCard) => void;
  resetRateCards: () => void;
  getRateCards: () => Promise<any>;
  getSelectedRateCard: (string) => Promise<any>;
  rateCardAction: number;
  onRateCardActionChange: (number) => void;
  newRateCardName: string;
  onNewRateCardNameChange: (string) => void;

  // remaining searches
  remainingSearchesNetwork: NetworkState;
  remainingSearches: number; // searches allocated
  remainingSearchesAfterRun: number; // calculated (remainingSearches - searchesToRun)
  isUnlimited: boolean;
  getRemainingSearches: () => Promise<any>;

  // batch search
  jobsToSearch: BatchSearchJob[];
  jobsToSearchItems: BatchSearchJobListItem[]; // all items (sorted)
  jobsToSearchItemsView: BatchSearchJobListItem[]; // items to render (paginated and filtered)
  jobsToSearchItemsViewState: Object;
  jobsToSearchItemsSelectedCount: number;
  jobsToSearchItemsTotalCount: number;
  jobsToSearchItemsAllSelected: number;
  jobsToSearchItemsToggleAllSelected: () => void;
  jobsToSearchItemsAllInSameCountry: boolean;
  jobsToSearchItemsSelectedAllInSameCountry: boolean;
  jobsToSearchLimit: number;
  jobsToRemoveBeforeAdding: ?(BatchSearchJobListItem[]);
  invalidJobs: ?(BatchSearchJobListItem[]);
  addJobToBatchSearch: () => void;
  addMoreJobs: (?boolean) => void;
  removeJobFromSearch: (BatchSearchJobListItem) => void;
  onRemoveInvalidJobsFromSearch: () => void;
  findInvalidJobs: (InvalidJobData[]) => BatchSearchJobListItem[];
  editJobToSearch: (BatchSearchJobListItem) => void;
  addNewLocationForAllJobs: () => void;
  changeLocationForAllSelectedJobs: () => void;
  validateStep: (number) => boolean;
  onCurrentStepChange: (number) => void;
  addTitleValidateStep: (number) => boolean;
  addTitleOnCurrentStepChange: (number) => void;
  resetAll: () => void;
  createAndRunBatchSearch: () => Promise<any>;
  rerunBatchSearch: (string) => Promise<any>;
  showAddJobTitlesWizard: boolean;
  showDescriptions: boolean;
  locationValid: () => boolean;
  allSelectedLocationsInSameCountry: () => boolean;
  cancel: () => void;
  showGSSColumn: boolean;
  showWorkerTypeColumn: boolean;
  showTagsColumn: boolean;
  showItemAdded: boolean;
  itemsAdded: number;
  showItemExists: boolean;
  itemsExist: number;
  loadingForEdit: boolean;

  // jobs to search filters
  jobsToSearchFiltersModal: ModalState;
  jobsToSearchAppliedFilters: ItemFilter[];
  jobsToSearchAvailableIndustryFilters: ItemFilter[];
  jobsToSearchAvailableCategoryFilters: ItemFilter[];
  jobsToSearchSelectedIndustryFilter: ?ItemFilter;
  jobsToSearchSelectedCategoryFilter: ?ItemFilter;
  jobsToSearchTitleFilter: string;
  onJobsToSearchSelectedIndustryFilterChange: (?ItemFilter) => void;
  onJobsToSearchSelectedCategoryFilterChange: (?ItemFilter) => void;
  onJobsToSearchTitleFilterChange: (string) => void;
  filterJobsToSearch: () => void;
  clearJobsToSearchFilters: () => void;

  // bulk change industry
  bulkChangeIndustryModal: ModalState;
  bulkChangeIndustryModalSelectedIndustry: ?Industry;
  bulkChangeIndustryModalOnSelectedIndustryChange: (?Industry) => void;
  bulkChangeIndustryModalOnCommitChange: () => void;
  bulkChangeIndustryModalOnCancel: () => void;

  // bulk remove jobs to search
  bulkRemoveJobsModal: ModalState;
  bulkRemoveJobsModalOnConfirm: () => void;

  constructor(fetchGraphQL: FetchGraphQL, fetchAPI: FetchAPI, mobXStore: MobXStore) {
    this.fetchTasteGraphQL = createTasteGraphQLWrapper(fetchAPI);
    this.fetchGraphQL = fetchGraphQL;
    this.fetchAPI = fetchAPI;
    this.mobXStore = mobXStore;
    this.router = null;

    // NOTE: Bound early to pass into pagination & filter state
    this.getRateCards = action(this.getRateCards.bind(this));
    this.getJobTitles = action(this.getJobTitles.bind(this));

    this.jobsToSearchLimit = 2000;

    extendObservable(this, {
      network: new NetworkState(),
      wizard: new WizardState(),
      addTitleWizard: new WizardState(),
      errorModal: new ModalState(),
      errorMessage: null,
      invalidJobsErrorModal: new ModalState(),
      infoModal: new ModalState(),
      infoMessage: null,
      // rate type
      selectedRateType: null,
      rateTypes: [RATE_TYPE_OPTIONS_2.CONTRACT, RATE_TYPE_OPTIONS_2.FTE],
      // userCountries
      // userCountriesNetwork: new NetworkState(),
      // userCountries: [],
      // userCountrySearch: "",
      // selectedUserCountry: null,
      // skipResetJobsOnChange: false,
      // jobTitles
      jobTitlesNetwork: new NetworkState(),
      jobTitlesPagination: new PaginationState(this.getJobTitles, 50),
      jobTitles: [],
      selectedJobTitle: null,
      jobTitleSearch: "",
      jobLabelValue: "",
      jobDescriptionValue: "",
      checkLabelNetwork: new NetworkState(),
      jobLabelHelperText: "",
      jobLabelIsUnique: false,
      jobDescriptionNetwork: new NetworkState(),
      // jobTitleSelectionEnabled: true,
      // jobTitlesCurrentPage: 0,
      // jobTitlesNextPage: 1,
      // jobTitlesAllSelected: false,
      // jobTitlesTotalCount: 0,
      // jobTitlesCountNetwork: new NetworkState(),
      // jobTitlesSelected: observable.map({}),
      // jobTitlesViewState: observable.map({}),
      // jobTitlesSelectedCount: computed(() => {
      //   if (this.jobTitlesAllSelected) {
      //     return this.jobTitlesTotalMatchesCount;
      //   }
      //
      //   let count = 0;
      //   this.jobTitlesViewState.forEach(viewState => {
      //     if (viewState.selected) {
      //       count += 1;
      //     }
      //   });
      //   return count;
      // }),

      // category
      tagsNetwork: new NetworkState(),
      tags: [],
      selectedTags: [],
      tagSearch: "",
      // industry
      industriesNetwork: new NetworkState(),
      industries: [],
      selectedIndustry: null,
      industrySearch: "",
      // locations
      locations: [],
      selectedLocation: null,
      selectedLocations: [],
      locationSearch: "",
      locationsCurrentPage: 0,
      locationsNextPage: 1,
      isGSS: false,
      locationType: LOCATION_TYPE.LOCATION,
      showGSSOption: computed(() => {
        if (!this.locationValid()) return false;
        if (this.locationType === LOCATION_TYPE.REGION) {
          return GSS_COUNTRY_IDS_FOR_REGIONS.includes(
            this.selectedRegion?.countryId?.toString()
          );
        }

        const nonGSSLocationSelected = this.selectedLocations.find((l) => {
          return !GSS_COUNTRY_IDS.includes(l.countryId?.toString());
        });

        return !nonGSSLocationSelected;
      }),
      // regions
      regionsNetwork: new NetworkState(),
      regions: [],
      allRegions: [],
      selectedRegion: null,
      regionSearch: "",
      // worker types
      workerTypesNetwork: new NetworkState(),
      workerTypes: [],
      selectedWorkerType: null,
      showWorkerTypes: computed(() => {
        return (
          this.selectedRateType?.value === RATE_TYPE.HOURLY &&
          this.locationType === LOCATION_TYPE.LOCATION &&
          this.selectedLocations.length > 0 &&
          this.allSelectedLocationsInSameCountry() &&
          this.selectedIndustry &&
          this.workerTypes.length > 0
        );
      }),
      // rate cards
      rateCards: [],
      rateCardsPagination: new PaginationState(this.getRateCards, 10),
      selectedRateCard: null,
      rateCardSearch: "",
      rateCardsCurrentPage: 0,
      rateCardsNextPage: 1,
      rateCardAction: RATE_CARD_ACTION.USE_EXISTING,
      newRateCardName: "",
      // remaining searches,
      remainingSearchesNetwork: new NetworkState(),
      remainingSearches: 0,
      isUnlimited: false,
      remainingSearchesAfterRun: computed(() => {
        if (!this.remainingSearches) return 0;
        return this.remainingSearches - this.jobsToSearchItemsTotalCount;
      }),
      // batch search
      jobsToSearch: [],
      jobsToSearchItems: computed(() => {
        const result: BatchSearchJobListItem[] = [];
        this.jobsToSearch.forEach((job: BatchSearchJob) => {
          job.locations.forEach((location) =>
            result.push(new BatchSearchJobListItem(this, job, location, false))
          );
          job.gssLocations.forEach((location) =>
            result.push(new BatchSearchJobListItem(this, job, location, true))
          );
          job.regions.forEach((location) =>
            result.push(new BatchSearchJobListItem(this, job, location, false))
          );
        });

        // TODO: sort result list here

        return result;
      }),
      jobsToSearchItemsView: computed(() => {
        // TODO: paginate & filter result list here
        return this.jobsToSearchItems.filter((item) => {
          for (let i = 0; i < this.jobsToSearchAppliedFilters.length; i++) {
            const currentFilter = this.jobsToSearchAppliedFilters[i];
            if (!currentFilter.apply(item)) return false;
          }
          return true;
        });
      }),
      jobsToSearchItemsTotalCount: computed(() => {
        let result: number = 0;
        this.jobsToSearch.forEach((job: BatchSearchJob) => {
          result += job.locations.length;
          result += job.gssLocations.length;
          result += job.regions.length;
        });

        return result;
      }),
      jobsToSearchItemsSelectedCount: computed(() => {
        let count = 0;
        this.jobsToSearchItems.forEach((item) => {
          if (item.viewState.selected) {
            count += 1;
          }
        });
        return count;
      }),
      jobsToSearchItemsAllSelected: computed(() => {
        return this.jobsToSearchItemsSelectedCount === this.jobsToSearchItemsTotalCount;
      }),
      jobsToRemoveBeforeAdding: null,
      invalidJobs: null,
      showAddJobTitlesWizard: true,
      showDescriptions: false,
      jobsToSearchItemsAllInSameCountry: computed(() => {
        if (this.jobsToSearchItems.length === 0) return false;

        let country = this.jobsToSearchItems[0].countryCode;
        for (let i = 0; i < this.jobsToSearchItems.length; i++) {
          const job = this.jobsToSearchItems[i];

          if (job.countryCode !== country) return false;
        }

        return true;
      }),
      jobsToSearchItemsSelectedAllInSameCountry: computed(() => {
        const selectedJobs = this.jobsToSearchItems.filter(
          (item) => item.viewState.selected
        );
        if (selectedJobs.length === 0) return false;

        let country = selectedJobs[0].countryCode;
        for (let i = 0; i < selectedJobs.length; i++) {
          const job = selectedJobs[i];

          if (job.countryCode !== country) return false;
        }

        return true;
      }),
      showGSSColumn: computed(() => {
        for (let i = 0; i < this.jobsToSearchItems.length; i++) {
          if (this.jobsToSearchItems[i].isGSS) return true;
        }

        return false;
      }),
      showWorkerTypeColumn: computed(() => {
        for (let i = 0; i < this.jobsToSearchItems.length; i++) {
          if (this.jobsToSearchItems[i].workerType) return true;
        }

        return false;
      }),
      showTagsColumn: computed(() => {
        for (let i = 0; i < this.jobsToSearchItems.length; i++) {
          const tags = this.jobsToSearchItems[i].tags || [];
          if (tags.length > 0) return true;
        }

        return false;
      }),
      showItemAdded: false,
      itemsAdded: 0,
      showItemExists: false,
      itemsExist: 0,
      loadingForEdit: false,
      // jobs to search filters
      jobsToSearchFiltersModal: new ModalState(),
      jobsToSearchItemsViewState: observable.map({}),
      jobsToSearchAppliedFilters: [],
      jobsToSearchAvailableIndustryFilters: computed(() => {
        const industryFilters: ItemFilter[] = [];
        const industryMap = {};
        this.jobsToSearchItems.forEach((job: BatchSearchJobListItem) => {
          if (job.industry in industryMap) return;

          const filter = new ItemFilter(["industry"], job.industry, job.industry);
          industryFilters.push(filter);
          industryMap[job.industry] = true;
        });

        return industryFilters;
      }),
      jobsToSearchSelectedIndustryFilter: null,
      jobsToSearchAvailableCategoryFilters: computed(() => {
        const categoryFilters: ItemFilter[] = [];
        const categoryMap = {};
        this.jobsToSearchItems.forEach((job: BatchSearchJobListItem) => {
          if (job.category in categoryMap) return;

          const filter = new ItemFilter(["category"], job.category, job.category);
          categoryFilters.push(filter);
          categoryMap[job.category] = true;
        });

        return categoryFilters;
      }),
      jobsToSearchSelectedCategoryFilter: null,
      jobsToSearchTitleFilter: "",
      // bulk change industry
      bulkChangeIndustryModal: new ModalState(),
      bulkChangeIndustryModalSelectedIndustry: null,
      // bulk remove jobs to search
      bulkRemoveJobsModal: new ModalState(),
    });

    // rate type
    this.onRateTypeChange = action(this.onRateTypeChange.bind(this));
    // user countries
    // this.onUserCountrySearchChange = action(this.onUserCountrySearchChange.bind(this));
    // this.onSelectedUserCountryChange = action(this.onSelectedUserCountryChange.bind(this));
    // this.getUserCountries = action(this.getUserCountries.bind(this));
    // this.resetUserCountries = action(this.resetUserCountries.bind(this));
    // job titles
    this.onJobTitleSearchChange = action(this.onJobTitleSearchChange.bind(this));
    this.onSelectedJobTitleChange = action(this.onSelectedJobTitleChange.bind(this));
    this.resetJobTitles = action(this.resetJobTitles.bind(this));
    this.onJobLabelChange = action(this.onJobLabelChange.bind(this));
    this.onJobDescriptionChange = action(this.onJobDescriptionChange.bind(this));
    // this.jobTitlesHasNextPage = action(this.jobTitlesHasNextPage.bind(this));
    // this.jobTitlesLoadNextPage = action(this.jobTitlesLoadNextPage.bind(this));
    // this.jobTitlesLoadFirstPage = action(this.jobTitlesLoadFirstPage.bind(this));
    // this.getJobTitlesCount = action(this.getJobTitlesCount.bind(this));
    // this.getSelectedJobTitlesDescription = action(this.getSelectedJobTitlesDescription.bind(this));
    // this.selectAllJobTitles = action(this.selectAllJobTitles.bind(this));
    // this.onJobTitlesSelectAllPage = action(this.onJobTitlesSelectAllPage.bind(this));
    // this.onJobTitlesSelectAllMatches = action(this.onJobTitlesSelectAllMatches.bind(this));
    // this.onJobTitlesClearSelection = action(this.onJobTitlesClearSelection.bind(this));
    // category
    this.onSelectedTagsChange = action(this.onSelectedTagsChange.bind(this));
    this.getTagsWithSearch = action(this.getTagsWithSearch.bind(this));
    this.getSelectedJobLabelTags = action(this.getSelectedJobLabelTags.bind(this));
    this.createTag = action(this.createTag.bind(this));
    this.resetTags = action(this.resetTags.bind(this));
    // industries
    this.onIndustrySearchChange = action(this.onIndustrySearchChange.bind(this));
    this.onSelectedIndustryChange = action(this.onSelectedIndustryChange.bind(this));
    this.getIndustries = action(this.getIndustries.bind(this));
    this.resetIndustries = action(this.resetIndustries.bind(this));
    // locations
    this.onLocationSearchChange = action(this.onLocationSearchChange.bind(this));
    this.onSelectedLocationChange = action(this.onSelectedLocationChange.bind(this));
    this.onSelectedLocationsChange = action(this.onSelectedLocationsChange.bind(this));
    this.getLocations = action(this.getLocations.bind(this));
    this.locationsHasNextPage = action(this.locationsHasNextPage.bind(this));
    this.locationsLoadNextPage = action(this.locationsLoadNextPage.bind(this));
    this.locationsLoadFirstPage = action(this.locationsLoadFirstPage.bind(this));
    this.onIsGSSChange = action(this.onIsGSSChange.bind(this));
    this.onLocationTypeChange = action(this.onLocationTypeChange.bind(this));
    this.resetLocations = action(this.resetLocations.bind(this));
    // regions
    this.onRegionSearchChange = action(this.onRegionSearchChange.bind(this));
    this.onSelectedRegionChange = action(this.onSelectedRegionChange.bind(this));
    this.getRegions = action(this.getRegions.bind(this));
    this.getAllUserRegions = action(this.getAllUserRegions.bind(this));
    this.resetRegions = action(this.resetRegions.bind(this));
    // worker types
    this.onSelectedWorkerTypeChange = action(this.onSelectedWorkerTypeChange.bind(this));
    this.getWorkerTypes = action(this.getWorkerTypes.bind(this));
    this.resetWorkerTypes = action(this.resetWorkerTypes.bind(this));
    // rate cards
    this.onRateCardSearchChange = action(this.onRateCardSearchChange.bind(this));
    this.onSelectedRateCardChange = action(this.onSelectedRateCardChange.bind(this));
    this.rateCardsHasNextPage = action(this.rateCardsHasNextPage.bind(this));
    this.rateCardsLoadNextPage = action(this.rateCardsLoadNextPage.bind(this));
    this.rateCardsLoadFirstPage = action(this.rateCardsLoadFirstPage.bind(this));
    this.resetRateCards = action(this.resetRateCards.bind(this));
    this.onNewRateCardNameChange = action(this.onNewRateCardNameChange.bind(this));
    this.onRateCardActionChange = action(this.onRateCardActionChange.bind(this));
    this.getSelectedRateCard = action(this.getSelectedRateCard.bind(this));
    // remaining searches
    this.getRemainingSearches = action(this.getRemainingSearches.bind(this));
    // batch search
    this.jobsToSearchItemsToggleAllSelected = action(
      this.jobsToSearchItemsToggleAllSelected.bind(this)
    );
    this.addJobToBatchSearch = action(this.addJobToBatchSearch.bind(this));
    this.addMoreJobs = action(this.addMoreJobs.bind(this));
    this.removeJobFromSearch = action(this.removeJobFromSearch.bind(this));
    this.onRemoveInvalidJobsFromSearch = action(
      this.onRemoveInvalidJobsFromSearch.bind(this)
    );
    this.findInvalidJobs = action(this.findInvalidJobs.bind(this));
    this.editJobToSearch = action(this.editJobToSearch.bind(this));
    this.addNewLocationForAllJobs = action(this.addNewLocationForAllJobs.bind(this));
    this.changeLocationForAllSelectedJobs = action(
      this.changeLocationForAllSelectedJobs.bind(this)
    );
    this.validateStep = action(this.validateStep.bind(this));
    this.onCurrentStepChange = action(this.onCurrentStepChange.bind(this));
    this.addTitleValidateStep = action(this.addTitleValidateStep.bind(this));
    this.addTitleOnCurrentStepChange = action(
      this.addTitleOnCurrentStepChange.bind(this)
    );
    this.createAndRunBatchSearch = action(this.createAndRunBatchSearch.bind(this));
    this.rerunBatchSearch = action(this.rerunBatchSearch.bind(this));
    this.resetAll = this.resetAll.bind(this);
    this.locationValid = this.locationValid.bind(this);
    this.allSelectedLocationsInSameCountry =
      this.allSelectedLocationsInSameCountry.bind(this);
    this.cancel = this.cancel.bind(this);
    // jobs to search filters
    this.onJobsToSearchSelectedIndustryFilterChange =
      this.onJobsToSearchSelectedIndustryFilterChange.bind(this);
    this.onJobsToSearchSelectedCategoryFilterChange =
      this.onJobsToSearchSelectedCategoryFilterChange.bind(this);
    this.onJobsToSearchTitleFilterChange =
      this.onJobsToSearchTitleFilterChange.bind(this);
    this.filterJobsToSearch = this.filterJobsToSearch.bind(this);
    this.clearJobsToSearchFilters = this.clearJobsToSearchFilters.bind(this);
    // bulk change industry
    this.bulkChangeIndustryModalOnSelectedIndustryChange =
      this.bulkChangeIndustryModalOnSelectedIndustryChange.bind(this);
    this.bulkChangeIndustryModalOnCommitChange =
      this.bulkChangeIndustryModalOnCommitChange.bind(this);
    this.bulkChangeIndustryModalOnCancel =
      this.bulkChangeIndustryModalOnCancel.bind(this);
    // bulk remove jobs to search
    this.bulkRemoveJobsModalOnConfirm = this.bulkRemoveJobsModalOnConfirm.bind(this);
  }

  // ------------------------------------------------------------
  //
  //   Rate Types
  //
  // ------------------------------------------------------------

  onRateTypeChange(rateType: Option) {
    this.selectedRateType = rateType;
    this.wizard.next();
  }

  // ------------------------------------------------------------
  //
  //   UserCountries
  //
  // ------------------------------------------------------------

  // onSelectedUserCountryChange(selectedUserCountry: ?Location): void {
  //   this.selectedUserCountry = selectedUserCountry;
  //   this.resetLocations();
  //   this.resetRegions();
  //   this.resetWorkerTypes();
  //   // if (this.skipResetJobsOnChange) {
  //   //   this.skipResetJobsOnChange = false;
  //   // } else {
  //   //   this.resetIndustries();
  //   //   this.resetJobTitles();
  //   // }
  //
  //   if (this.selectedUserCountry) {
  //     // this.locationSearch = this.selectedUserCountry.toString();
  //     // this.selectedLocation = this.selectedUserCountry;
  //
  //     // this.getJobTitlesCount();
  //     Promise.all([this.getLocations(), this.getRegions()])
  //       .then(() => {
  //         this.addTitleWizard.goTo(2); // select location and industry
  //
  //         if (this.locations.length > 0 && this.selectedUserCountry?.id.toString() === this.locations[0].id) {
  //           this.selectedLocation = this.locations[0];
  //         }
  //
  //         if (this.industries.length > 0) {
  //           if (!this.selectedIndustry) this.selectedIndustry = this.industries[0];
  //
  //           if (this.selectedRateType?.value === RATE_TYPE.HOURLY) {
  //             this.getWorkerTypes();
  //           }
  //         }
  //
  //         if ((this.selectedLocation || this.selectedRegion) && this.selectedIndustry) {
  //           this.addTitleWizard.goTo(3); // add title
  //           this.jobTitlesPagination.goFetch().catch(() => {});
  //         }
  //       })
  //       .catch(error => {
  //         console.error(error);
  //         this.errorMessage = (
  //           <p>
  //             Sorry! we could not load required data for the selected country. There was an error during the operation.
  //           </p>
  //         );
  //       });
  //   } else {
  //     this.addTitleWizard.goTo(1); // select country
  //   }
  // }
  //
  // onUserCountrySearchChange(query: string): void {
  //   this.userCountrySearch = query;
  //   this.getUserCountries();
  // }
  //
  // resetUserCountries(): void {
  //   this.userCountries = [];
  //   this.selectedUserCountry = null;
  //   this.userCountrySearch = "";
  // }
  //
  // async getUserCountries(): Promise<any> {
  //   if (this.userCountriesNetwork.loading === true) {
  //     this.userCountriesNetwork.cancel();
  //   }
  //
  //   const filterFields = [];
  //
  //   if (this.userCountrySearch && this.userCountrySearch.trim() !== "") {
  //     filterFields.push(`anyPropertyIContains: "${this.userCountrySearch}"`);
  //   }
  //
  //   const filters = `filters: {${filterFields.join(", ")}},`;
  //
  //   const query = `
  //     query getUserCountries {
  //       viewer {
  //         countries(${filters} order: [{field: NAME}]) {
  //           ...userCountry
  //         }
  //       }
  //     }
  //
  //     fragment userCountry on CountryListNode {
  //       locationId
  //       countryId: apiLocationId
  //       fullTitle: name
  //       countryCode: isoCode
  //     }
  //   `;
  //
  //   this.userCountriesNetwork.loading = true;
  //   let res = null;
  //
  //   try {
  //     res = await this.fetchGraphQL(query, {}, this.userCountriesNetwork.getCancelToken());
  //   } catch (e) {
  //     if (this.userCountriesNetwork.isCancelError(e)) {
  //       return e;
  //     }
  //
  //     this.userCountriesNetwork.handleError("Getting Client UserCountries", e);
  //     if (res !== null) {
  //       this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res);
  //     }
  //
  //     // TODO: Display user friendly error message
  //     return e;
  //   }
  //
  //   return runInAction("getUserCountries--success", () => {
  //     this.userCountriesNetwork.loading = false;
  //     this.userCountriesNetwork.error = null;
  //     if (this.userCountriesNetwork.logGraphQLError("Get Client UserCountries query", res)) {
  //       // TODO: Display user friendly error message
  //       return;
  //     }
  //
  //     if (!res) {
  //       return;
  //     }
  //
  //     const userCountries = res.data.viewer.countries;
  //
  //     this.userCountries = userCountries.map(userCountry => {
  //       return new Location(this, userCountry);
  //     });
  //
  //     return this.userCountries;
  //   });
  // }

  // ------------------------------------------------------------
  //
  //   Client JobTitles
  //
  // ------------------------------------------------------------

  onSelectedJobTitleChange(selectedJobTitle: ?RawJobTitle): void {
    // console.log("selected");
    this.selectedJobTitle = selectedJobTitle;
    this.jobLabelHelperText = "";

    if (this.selectedJobTitle) {
      this.jobLabelValue = this.selectedJobTitle?.title || "";
      if (!this.selectedJobTitle.isJobLabel) {
        this.isJobLabelUnique().catch(() => {});
      }

      this.jobDescriptionValue = "";
      this.getJobDescription().then(() => {
        this.jobDescriptionValue = this.selectedJobTitle?.description || "";
      });
      // if (this.titleFormIsValid()) {
      //   this.addTitleWizard.goTo(2);
      // }

      this.getSelectedJobLabelTags()
        .then((tags) => {
          this.selectedTags = tags;
        })
        .catch((e) => console.error(e));
    } else {
      this.jobLabelValue = "";
      this.jobDescriptionValue = "";
      // this.addTitleWizard.goTo(1);
    }

    this.showItemAdded = false;
    this.showItemExists = false;
    // if (this.selectedJobTitle && this.selectedJobTitle.libraryInfo) {
    //   this.selectedCategory = this.selectedJobTitle.libraryInfo.category;
    // }

    // reset locations and regions
    // this.resetLocations();
    // this.resetRegions();
  }

  onJobTitleSearchChange(query: string): void {
    // console.log("search changed");
    this.jobTitleSearch = query;

    // load first page of titles
    // debounce(() => {
    //   this.jobTitlesPagination.goFetch();
    // }, 250);
    this.debouncedRefetchJobTitles();
  }

  debouncedRefetchJobTitles = debounce(
    () => {
      this.jobTitlesPagination.goFetch().catch(() => {});
    },
    200,
    { trailing: true, leading: false }
  );

  debouncedJobTitlesNextPage = debounce(
    () => {
      if (this.jobTitlesNetwork.loading) return;
      this.jobTitlesPagination.nextPage().catch(() => {});
    },
    200,
    { trailing: true, leading: false }
  );

  onJobLabelChange(value: string): void {
    this.jobLabelValue = value;
    this.checkLabelNetwork.loading = true;
    this.debouncedIsJobLabelUnique();

    this.showItemAdded = false;
    this.showItemExists = false;
  }

  onJobDescriptionChange(value: string): void {
    this.jobDescriptionValue = value;

    this.showItemAdded = false;
    this.showItemExists = false;
  }

  titleFormIsValid = (): boolean => {
    return (
      this.selectedJobTitle &&
      (this.selectedJobTitle?.isJobLabel || (this.jobLabelValue && this.jobLabelIsUnique))
    );
  };

  debouncedIsJobLabelUnique = debounce(
    () => {
      this.isJobLabelUnique().catch(() => {});
    },
    250,
    { trailing: true, leading: false }
  );

  isJobLabelUnique = action(async (): Promise<boolean> => {
    const abortController = this.checkLabelNetwork.createAbortController();
    this.checkLabelNetwork.loading = true;
    this.jobLabelHelperText = "";

    const query = `
    query checkJobLabel($jobLabel: String!) {
      viewer {
        jobs(first: 1, filters: { jobLabelIExact: $jobLabel}) {
          totalCount
        }
      }
    }
    `;
    const variables = { jobLabel: this.jobLabelValue.trim() };
    let res = null;
    try {
      res = await this.fetchGraphQL(query, variables, abortController.source.token);
    } catch (e) {
      if (this.jobTitlesNetwork.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.jobTitlesNetwork.loading = false;
      this.jobTitlesNetwork.handleError("Checking Job Label", e);

      this.jobLabelHelperText = "Sorry! there was an error checking the job label.";
      return false;
    }

    if (abortController.aborted()) {
      return Promise.reject(abortController.source.token.reason);
    }

    return runInAction("isJobLabelUnique--success", () => {
      this.checkLabelNetwork.loading = false;
      const count = res?.data?.viewer?.jobs?.totalCount;
      if (count === null || count === undefined) {
        this.jobLabelHelperText = "Sorry! there was an error checking the job label.";
        this.jobLabelIsUnique = false;
      } else if (count > 0) {
        this.jobLabelHelperText = "You must choose a unique Job Label.";
        this.jobLabelIsUnique = false;
      } else if (count === 0) {
        this.jobLabelHelperText = "";
        this.jobLabelIsUnique = true;
      }

      return this.jobLabelIsUnique;
    });
  });

  getJobDescription = action(async (): Promise<any> => {
    const selectedTitle = this.selectedJobTitle;

    if (!selectedTitle) return;

    const abortController = this.jobDescriptionNetwork.createAbortController();
    this.jobDescriptionNetwork.loading = true;

    const query = `
    query getRawJobTitleDescription($databaseId: ID!) {
      rawJobTitle(databaseId: $databaseId) {
        description
      }
    }
    `;
    const variables = {
      databaseId: selectedTitle.id,
    };

    let payload = null;
    try {
      payload = await this.fetchTasteGraphQL(
        query,
        variables,
        abortController.source.token
      );
    } catch (e) {
      if (this.jobDescriptionNetwork.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.jobDescriptionNetwork.loading = false;
      this.jobDescriptionNetwork.handleError("Getting Raw Job Title Description", e);
      if (payload !== null) {
        this.jobDescriptionNetwork.logGraphQLError(
          "Get Raw Job Title Description query",
          payload
        );
      }

      // TODO: Display user friendly error message
      return e;
    }

    if (abortController.aborted()) {
      return Promise.reject(abortController.source.token.reason);
    }

    return runInAction("getJobDescription--success", () => {
      this.jobDescriptionNetwork.loading = false;
      this.jobDescriptionNetwork.error = null;
      if (
        this.jobDescriptionNetwork.logGraphQLError(
          "Get Raw Job Title Description query",
          payload
        )
      ) {
        // TODO: Display user friendly error message
        return null;
      }

      if (!payload || !payload.data || !payload.data.rawJobTitle) return;

      selectedTitle.description = payload.data.rawJobTitle.description;
      return selectedTitle.description;
    });
  });

  resetJobTitles(): void {
    this.jobTitles = [];
    this.selectedJobTitle = null;
    this.jobTitleSearch = "";
    this.jobLabelValue = "";
    this.jobDescriptionValue = "";
    this.jobLabelHelperText = "";
    // this.selectedCategory = "";
    // this.jobTitlesTotalMatchesCount = 0;
    // this.jobTitlesCurrentPage = 0;
    // this.jobTitlesNextPage = 1;
    // this.jobTitlesSelected.clear();
    // this.jobTitlesViewState.clear();
    // this.jobTitlesAllSelected = false;
  }

  // onJobTitlesSelectAllPage(): void {
  //   this.jobTitles.forEach(jobTitle => jobTitle.toggleSelected(true));
  // }
  //
  // onJobTitlesSelectAllMatches(): void {
  //   this.selectAllJobTitles();
  // }
  //
  // onJobTitlesClearSelection(): void {
  //   this.jobTitlesViewState.values().forEach(viewState => (viewState.selected = false));
  //   this.jobTitlesSelected.clear();
  // }
  //
  // jobTitlesHasNextPage(): boolean {
  //   return Boolean(this.jobTitlesNextPage);
  // }
  //
  // jobTitlesLoadNextPage() {
  //   if (!this.jobTitlesHasNextPage()) return;
  //
  //   this.jobTitlesPagination.goFetch();
  // }
  //
  // jobTitlesLoadFirstPage() {
  //   this.jobTitlesNextPage = 1;
  //   this.jobTitlesPagination.goFetch();
  // }
  //
  // async getSelectedJobTitlesDescription(): Promise<any> {
  //   const ids = this.jobTitlesSelected
  //     .values()
  //     .filter((jobTitle: RawJobTitle) => !jobTitle.description)
  //     .map((jobTitle: RawJobTitle) => jobTitle.id);
  //
  //   if (!ids || ids.length === 0) return;
  //
  //   if (this.jobTitlesNetwork.loading === true) {
  //     this.jobTitlesNetwork.cancel();
  //   }
  //
  //   const query = `
  //   query getRawJobTitlesDescriptions($ids: [ID]!) {
  //     rawJobTitles(filters: {only: $ids}) {
  //       edges {
  //         node {
  //           databaseId
  //           description
  //         }
  //       }
  //     }
  //   }
  //   `;
  //
  //   const variables = {
  //     ids: ids
  //   };
  //
  //   this.jobTitlesNetwork.loading = true;
  //
  //   let payload = null;
  //   try {
  //     payload = await this.fetchTasteGraphQL(query, variables, this.jobTitlesNetwork.getCancelToken());
  //   } catch (e) {
  //     this.jobTitlesNetwork.loading = false;
  //     if (this.jobTitlesNetwork.isCancelError(e)) {
  //       // console.log('Request Canceled ClientJobTitleListStore');
  //       return e;
  //     }
  //
  //     this.network.handleError("Getting Raw Job Titles Description", e);
  //     if (payload !== null) {
  //       this.network.logGraphQLError("Get Raw Job Titles Description query", payload);
  //     }
  //
  //     this.errorMessage = <p>Sorry! Couldn't get the description for some of the titles you're trying to add.</p>;
  //     this.errorModal.showModal();
  //     return Promise.reject("No description received for titles");
  //   }
  //
  //   return runInAction("getSelectedJobTitlesDescription--success", () => {
  //     this.jobTitlesNetwork.loading = false;
  //     this.network.error = null;
  //     if (this.network.logGraphQLError("Get Raw Job Titles Description query", payload)) {
  //       // TODO: Display user friendly error message
  //       return null;
  //     }
  //
  //     if (!payload || !payload.data || !payload.data.rawJobTitles) return;
  //
  //     // create an id map to determine if the result has missing titles
  //     let toBeProcessed = ids.reduce((map, titleId) => {
  //       map[titleId] = true;
  //       return map;
  //     }, {});
  //
  //     payload.data.rawJobTitles.edges.forEach(titleEdge => {
  //       const titleData = titleEdge.node;
  //       if (!titleData) return;
  //
  //       // skip if somehow we brought back a title that is not selected
  //       if (!toBeProcessed[titleData.databaseId]) return;
  //
  //       // update description on title
  //       const jobTitle: RawJobTitle = this.jobTitlesSelected.get(titleData.databaseId);
  //       jobTitle.description = titleData.description;
  //
  //       // remove current title id from processedIds
  //       delete toBeProcessed[titleData.databaseId];
  //     });
  //
  //     // error if any title was missing from result
  //     const missingTitleDescriptions = Object.keys(toBeProcessed);
  //     if (missingTitleDescriptions.length > 0) {
  //       console.error("No description received for titles:", missingTitleDescriptions);
  //       this.errorMessage = <p>Sorry! Couldn't get the description for some of the titles you're trying to add.</p>;
  //       this.errorModal.showModal();
  //       return Promise.reject("No description received for titles");
  //     }
  //   });
  // }
  //
  // async selectAllJobTitles(): Promise<any> {
  //   // if (!this.jobTitlesHasNextPage()) return;
  //
  //   if (this.jobTitlesNetwork.loading === true) {
  //     this.jobTitlesNetwork.cancel();
  //   }
  //
  //   this.jobTitlesNetwork.loading = true;
  //   let res = null;
  //
  //   const query = `
  //   query getRawJobTitlesFuzzySearch(
  //     $pageSize: Int,
  //     $page: Int,
  //     $search: String,
  //     $stockTitlesOnly: Boolean
  //   ) {
  //     viewer {
  //       jobTitles(
  //         pageSize: $pageSize,
  //         page: $page,
  //         search: $search,
  //         countryId: $countryId,
  //         libraryTitlesOnly: $libraryTitlesOnly,
  //         stockTitlesOnly: $stockTitlesOnly
  //       ) {
  //         totalCount
  //         results {
  //           id
  //           title
  //           collection
  //           isJobLabel
  //           category
  //           shareInfo {
  //             jobLabelId
  //             searchOnly
  //             isMine
  //             sharedBy {
  //               userId
  //               firstName
  //               lastName
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   `;
  //
  //   const variables = {
  //     pageSize: this.jobTitlesTotalMatchesCount,
  //     page: 1,
  //     search: this.jobTitleSearch && this.jobTitleSearch.trim() !== "" ? this.jobTitleSearch : null
  //   };
  //
  //   try {
  //     res = await this.fetchGraphQL(query, variables, this.jobTitlesNetwork.getCancelToken());
  //   } catch (e) {
  //     if (this.jobTitlesNetwork.isCancelError(e)) {
  //       return e;
  //     }
  //
  //     this.network.handleError("Selecting all JobTitles", e);
  //     return e;
  //   }
  //
  //   return runInAction("selectAllJobTitles--success", () => {
  //     this.jobTitlesNetwork.loading = false;
  //     this.network.error = null;
  //
  //     if (!res) {
  //       return null;
  //     }
  //
  //     const jobTitles = res.data.viewer.jobTitles.results;
  //     // this.jobTitlesAllSelected = true;
  //
  //     jobTitles.forEach(jobTitleData => {
  //       const jobTitle = new RawJobTitle(this, jobTitleData);
  //
  //       // set the selected viewState for all titles
  //       if (!this.jobTitlesViewState.has(jobTitle.id)) {
  //         this.jobTitlesViewState.set(jobTitle.id, {
  //           selected: true,
  //           editing: false,
  //           expanded: false
  //         });
  //       } else {
  //         this.jobTitlesViewState.get(jobTitle.id).selected = true;
  //       }
  //
  //       jobTitle.viewState = this.jobTitlesViewState.get(jobTitle.id);
  //
  //       // add title to selected titles map
  //       if (this.jobTitlesSelected.has(jobTitle.id)) {
  //         // if it's already selected, update it
  //         const existingTitle = this.jobTitlesSelected.get(jobTitle.id);
  //         Object.assign(existingTitle, jobTitle);
  //       } else {
  //         this.jobTitlesSelected.set(jobTitle.id, jobTitle);
  //       }
  //     });
  //   });
  // }
  //
  // async getJobTitlesCount(): Promise<any> {
  //   // if (!this.jobTitlesHasNextPage()) return;
  //
  //   if (this.jobTitlesCountNetwork.loading === true) {
  //     this.jobTitlesCountNetwork.cancel();
  //   }
  //
  //   this.jobTitlesCountNetwork.loading = true;
  //   let res = null;
  //
  //   const query = `
  //   query getRawJobTitlesFuzzySearch(
  //     $pageSize: Int,
  //     $page: Int,
  //     $search: String,
  //     $stockTitlesOnly: Boolean
  //   ) {
  //     viewer {
  //       jobTitles(
  //         pageSize: $pageSize,
  //         page: $page,
  //         search: $search,
  //         stockTitlesOnly: $stockTitlesOnly
  //       ) {
  //         totalCount
  //       }
  //     }
  //   }
  //   `;
  //
  //   const variables = { pageSize: 1, page: 1 };
  //
  //   try {
  //     // const itemsPerPage = pageQuery.page_size;
  //     // const page = pageQuery.page; // this.jobTitlesNextPage;
  //     // const search = this.jobTitleSearch && this.jobTitleSearch.trim() !== "" ? this.jobTitleSearch : null;
  //
  //     res = await this.fetchGraphQL(query, variables, this.jobTitlesCountNetwork.getCancelToken());
  //   } catch (e) {
  //     this.jobTitlesCountNetwork.loading = false;
  //     if (this.jobTitlesCountNetwork.isCancelError(e)) {
  //       return e;
  //     }
  //
  //     this.network.handleError("Getting Client JobTitles", e);
  //
  //     this.errorMessage = <p>Sorry! there was an error retrieving Job Titles for searching.</p>;
  //     this.errorModal.showModal();
  //     return e;
  //   }
  //
  //   return runInAction("getJobTitles--success", () => {
  //     this.jobTitlesCountNetwork.loading = false;
  //     this.network.error = null;
  //
  //     if (!res) {
  //       return 0;
  //     }
  //
  //     this.jobTitlesTotalMatchesCount = res.data.viewer.jobTitles.totalCount;
  //
  //     return res.data.viewer.jobTitles.totalCount;
  //   });
  // }

  async getJobTitles(pageQuery: PageQuery): Promise<any> {
    const abortController = this.jobTitlesNetwork.createAbortController();
    this.jobTitlesNetwork.loading = true;
    let res = null;

    const query = `
    query getRawJobTitlesFuzzySearch(
      $pageSize: Int,
      $page: Int,
      $search: String,
      $stockTitlesOnly: Boolean
    ) {
      viewer {
        jobTitles(
          pageSize: $pageSize,
          page: $page,
          search: $search,
          stockTitlesOnly: $stockTitlesOnly
        ) {
          totalCount
          results {
            id
            title
            collection
            isJobLabel
            category
            showDescription
            shareInfo {
              jobLabelId
              searchOnly
              isMine
              sharedBy {
                userId
                firstName
                lastName
              }
            }
          }
        }
      }
    }
    `;

    const variables = {
      pageSize: pageQuery.itemsPerPage,
      page: pageQuery.page,
      search:
        this.jobTitleSearch && this.jobTitleSearch.trim() !== ""
          ? this.jobTitleSearch
          : null,
    };

    try {
      // const itemsPerPage = pageQuery.page_size;
      // const page = pageQuery.page; // this.jobTitlesNextPage;
      // const search = this.jobTitleSearch && this.jobTitleSearch.trim() !== "" ? this.jobTitleSearch : null;

      res = await this.fetchGraphQL(query, variables, abortController.source.token);
      // await delayRandomly();
      // throwRandomly();
    } catch (e) {
      if (this.jobTitlesNetwork.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.jobTitlesNetwork.loading = false;
      this.jobTitlesNetwork.handleError("Getting Client JobTitles", e);

      this.errorMessage = (
        <p>Sorry! there was an error retrieving Job Titles for searching.</p>
      );
      this.errorModal.showModal();
      return e;
    }

    if (abortController.aborted()) {
      return Promise.reject(abortController.source.token.reason);
    }

    return runInAction("getJobTitles--success", () => {
      this.jobTitlesNetwork.loading = false;
      this.jobTitlesNetwork.error = null;

      if (!res) {
        return {
          totalCount: 0,
          startCursor: "",
          endCursor: "",
        };
      }

      // if (this.jobTitlesNextPage) {
      //   this.jobTitlesCurrentPage = this.jobTitlesNextPage;
      //   this.jobTitlesNextPage = res.next ? this.jobTitlesNextPage + 1 : null;
      // }

      const jobTitlesData = res.data.viewer.jobTitles.results;

      const rawJobTitles = jobTitlesData.map((jobTitleData) => {
        const jobTitle = new RawJobTitle(this, jobTitleData);

        // if (!this.jobTitlesViewState.has(jobTitle.id)) {
        //   this.jobTitlesViewState.set(jobTitle.id, {
        //     selected: false,
        //     editing: false,
        //     expanded: false
        //   });
        // } else {
        //   const selectedValue = this.jobTitlesViewState.get(jobTitle.id).selected;
        //   this.jobTitlesViewState.set(jobTitle.id, {
        //     selected: selectedValue,
        //     editing: false,
        //     expanded: false
        //   });
        // }
        //
        // jobTitle.viewState = this.jobTitlesViewState.get(jobTitle.id);
        //
        // if (jobTitle.viewState.selected) {
        //   // this.jobTitles will be the source of truth, so if there was already an object
        //   // in this.jobTitlesSelected replace it by this jobTitle instance
        //   this.jobTitlesSelected.set(jobTitle.id, jobTitle);
        // }
        //
        // if (!jobTitle.viewState.selected && this.jobTitlesSelected.has(jobTitle.id)) {
        //   this.jobTitlesSelected.delete(jobTitle.id);
        // }

        return jobTitle;
      });
      if (pageQuery.page === 1) {
        this.jobTitles = rawJobTitles;
      } else {
        this.jobTitles = [...this.jobTitles, ...rawJobTitles];
      }
      // this.jobTitlesTotalMatchesCount = res.data.viewer.jobTitles.totalCount;

      return { totalCount: res.data.viewer.jobTitles.totalCount };
    });
  }

  // ------------------------------------------------------------
  //
  //   category
  //
  // ------------------------------------------------------------

  onSelectedTagsChange(tags: Array<Tag>): void {
    this.selectedTags = tags;
  }

  resetTags() {
    this.tags = [];
    this.selectedTags = [];
    this.tagSearch = "";
  }

  async getSelectedJobLabelTags(): Promise<Array<Tag>> {
    const selectedJobTitle = this.selectedJobTitle;
    if (!selectedJobTitle?.isJobLabel || !selectedJobTitle?.sharedInfo?.jobLabelId) {
      return [];
    }

    const abortController = this.tagsNetwork.createAbortController();
    this.tagsNetwork.loading = true;

    const query = `
      query getJobTags($jobId: Int!) {
        viewer {
          job(id: $jobId) {
            jobId
            tags {
              tagId
              name
            }
          }
        }
      }
    `;
    const variables = {
      jobId: selectedJobTitle?.sharedInfo?.jobLabelId,
    };

    let res = null;
    try {
      res = await this.fetchGraphQL(query, variables, abortController.source.token);
    } catch (e) {
      if (this.tagsNetwork.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.tagsNetwork.loading = false;
      this.tagsNetwork.handleError("getJobTags", e);
      // TODO: Display user friendly error message
      return Promise.reject(e);
    }

    this.tagsNetwork.loading = false;
    this.tagsNetwork.error = null;
    if (abortController.aborted()) {
      return Promise.reject(abortController.source.token.reason);
    }

    return runInAction("getSelectedJobLabelTags--success", () => {
      if (this.tagsNetwork.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        // 'There was an error retrieving the data for this page. Please reload and try again later.',
        return Promise.reject(
          new DataError("There was an error retrieving the tags data.")
        );
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const job = res.data.viewer.job;
      if (!job) {
        return [];
      }

      const tagsData = job?.tags || [];

      return tagsData.map((data) => new Tag(data));
    });
  }

  async getTagsWithSearch(search: string): Promise<Array<Tag>> {
    if (!search) {
      this.tags = [];
      return this.tags;
    }

    const abortController = this.tagsNetwork.createAbortController();
    this.tagsNetwork.loading = true;

    const query = `
      query getTags($search: String) {
        viewer {
          tags(search: $search, first: 100) {
            edges {
              node {
                name
                tagId
              }
            }
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, { search }, abortController.source.token);
    } catch (e) {
      if (this.tagsNetwork.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.tagsNetwork.loading = false;
      this.tagsNetwork.handleError("Getting tags for sharing", e);
      // TODO: Display user friendly error message
      return Promise.reject(e);
    }

    this.tagsNetwork.loading = false;
    this.tagsNetwork.error = null;
    if (abortController.aborted()) {
      return Promise.reject(abortController.source.token.reason);
    }

    return runInAction("getTagsWithSearch--success", () => {
      if (this.tagsNetwork.logGraphQLError("Filter criteria query", res)) {
        // TODO: Display user friendly error message
        // 'There was an error retrieving the data for this page. Please reload and try again later.',
        return Promise.reject(
          new DataError("There was an error retrieving the tags data.")
        );
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const tagEdges = res.data.viewer.tags.edges;

      this.tags = tagEdges.map((edge) => new Tag(edge.node));
      return this.tags;
    });
  }

  async createTag(name: string): Promise<Tag> {
    if (!name) {
      return Promise.reject(new DataError("Name value required."));
    }

    const abortController = this.tagsNetwork.createAbortController();
    this.tagsNetwork.loading = true;

    const query = `
      mutation createTag($names: String!) {
        createTag(input: { name: $names }) {
          tags {
            tagId
            name
          }

          errors {
            __typename
            ...on TagNameEmptyError {
              message
            }
            ...on TagNameAlreadyExistError {
              message
            }
          }
        }
      }
    `;

    const variables = { names: name }; // can be comma separated "tag1, tag2"

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables, abortController.source.token);
    } catch (e) {
      if (this.tagsNetwork.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.tagsNetwork.loading = false;
      this.tagsNetwork.handleError("createTag", e);
      // TODO: Display user friendly error message
      return Promise.reject(e);
    }

    this.tagsNetwork.loading = false;
    this.tagsNetwork.error = null;
    if (abortController.aborted()) {
      return Promise.reject(abortController.source.token.reason);
    }

    return runInAction("createTag--success", () => {
      if (this.tagsNetwork.logGraphQLError("Create tags", res)) {
        // TODO: Display user friendly error message
        // 'There was an error retrieving the data for this page. Please reload and try again later.',
        return Promise.reject(new DataError("There was an error creating tags."));
      }

      // $FlowFixMe: Type the res value once we abstract gql calls.
      const tagsData = res.data.createTag.tags;

      this.selectedTags = [
        ...this.selectedTags,
        ...tagsData.map((data) => new Tag(data)),
      ];
      return this.selectedTags;
    });
  }

  // ------------------------------------------------------------
  //
  //   Client Industries
  //
  // ------------------------------------------------------------

  onSelectedIndustryChange(selectedIndustry: ?Industry): void {
    this.selectedIndustry = selectedIndustry;

    if (
      this.selectedRateType?.value === RATE_TYPE.HOURLY &&
      this.selectedIndustry &&
      (this.selectedLocations.length > 0 || this.selectedRegion)
    ) {
      this.addTitleWizard.goTo(2);
      this.getWorkerTypes().catch(() => {});
    } else {
      this.addTitleWizard.goTo(1);
    }

    this.showItemAdded = false;
    this.showItemExists = false;
  }

  onIndustrySearchChange(query: string): void {
    this.industrySearch = query;
    this.getIndustries();
  }

  resetIndustries(): void {
    this.selectedIndustry = null;
    this.industrySearch = "";
  }

  async getIndustries(): Promise<any> {
    if (this.industriesNetwork.loading === true) {
      this.industriesNetwork.cancel();
    }

    let filters = "";
    if (this.industrySearch && this.industrySearch.trim() !== "") {
      filters = `filters: {nameIContains: "${this.industrySearch}"},`;
    }

    const query = `
      query getIndustries {
        viewer {
          industries(${filters} order: [{field: VALUE}]) {
            edges {
              node {
                ...industry
              }
            }
          }
        }
      }

      fragment industry on IndustryNode {
        id
        legacyId
        value
      }
    `;

    this.industriesNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query);
    } catch (e) {
      if (this.industriesNetwork.isCancelError(e)) {
        return e;
      }

      this.industriesNetwork.handleError("Getting Client Industries", e);
      if (res !== null) {
        this.industriesNetwork.logGraphQLError("Get Client Industries query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getIndustries--success", () => {
      this.industriesNetwork.loading = false;
      this.industriesNetwork.error = null;
      if (this.industriesNetwork.logGraphQLError("Get Client Industries query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const industries = res.data.viewer.industries.edges;

      this.industries = industries.map((industry) => {
        return new Industry(this, industry.node);
      });

      // move industry "All" to first element
      if (
        !!this.industries &&
        this.industries.length > 0 &&
        this.industries[0].id !== 1
      ) {
        let industryAll = null;
        this.industries = this.industries.filter((item: Industry) => {
          if (item.id === 1) {
            industryAll = item;
            return false;
          }
          return true;
        });
        if (industryAll) this.industries = [industryAll, ...this.industries];
      }

      return this.industries;
    });
  }

  // ------------------------------------------------------------
  //
  //   Locations
  //
  // ------------------------------------------------------------

  async onSelectedLocationChange(selectedLocation: ?Location): Promise<void> {
    this.selectedLocation = selectedLocation;
    // this.resetJobTitles();

    if (this.selectedIndustry && this.locationValid()) {
      if (!this.selectedJobTitle) this.addTitleWizard.goTo(2);
      // go to select titles
      else this.addTitleWizard.goTo(3); // go to add titles

      if (this.selectedRateType?.value === RATE_TYPE.HOURLY) {
        await this.getWorkerTypes();
      }
      // load first page of titles
      // if (this.selectedUserCountry && this.jobTitles.length === 0 && !this.jobTitlesNetwork.loading) {
      //   await this.jobTitlesPagination.goFetch();
      // }
    } else {
      this.addTitleWizard.goTo(1);
    }

    this.showItemAdded = false;
    this.showItemExists = false;

    // if (this.selectedUserCountry) {
    //   // get locations for selected country
    //   this.locationSearch = this.selectedUserCountry.toString();
    //   await this.getLocations();
    //
    //   if (this.selectedIndustry && this.locationValid()) {
    //     if (!this.selectedJobTitle) this.addTitleWizard.goTo(3);
    //     // go to select titles
    //     else this.addTitleWizard.goTo(4); // go to add titles
    //
    //     if (this.selectedRateType?.value === RATE_TYPE.HOURLY) {
    //       await this.getWorkerTypes();
    //     }
    //     // load first page of titles
    //     if (this.selectedUserCountry && this.jobTitles.length === 0 && !this.jobTitlesNetwork.loading) {
    //       await this.jobTitlesPagination.goFetch();
    //     }
    //   } else {
    //     this.addTitleWizard.goTo(2);
    //   }
    //
    //   // this.getLocations().then(() => {
    //   //   if (this.selectedIndustry && this.locationValid()) {
    //   //     if (this.jobTitlesSelectedCount === 0) this.addTitleWizard.goTo(3);
    //   //     // go to select titles
    //   //     else this.addTitleWizard.goTo(4); // go to add titles
    //   //
    //   //     // load first page of titles
    //   //     this.getWorkerTypes();
    //   //     if (this.selectedUserCountry && this.jobTitles.length === 0) this.jobTitlesPagination.goFetch();
    //   //   } else {
    //   //     this.addTitleWizard.goTo(2);
    //   //   }
    //   // });
    // } else {
    //   this.locationSearch = "";
    //   this.locations = [];
    // }
    // // this.isGSS = false;
  }

  onLocationSearchChange(query: string): void {
    this.locationSearch = query;

    // load first page of locations
    this.locationsLoadFirstPage();
  }

  resetLocations(): void {
    this.selectedLocation = null;
    this.selectedLocations = [];
    this.locations = [];
    this.locationsCurrentPage = 0;
    this.locationsNextPage = 1;
    this.locationSearch = "";
    this.isGSS = false;
  }

  locationsHasNextPage(): boolean {
    return Boolean(this.locationsNextPage);
  }

  locationsLoadNextPage() {
    if (!this.locationSearch || this.locationSearch.trim().length < 2) {
      this.locations = [];
      return;
    }
    if (!this.locationsHasNextPage()) return;

    this.getLocations();
  }

  locationsLoadFirstPage() {
    // if (!this.locationSearch || this.locationSearch.trim().length < 2) {
    //   this.locations = [];
    //   this.locationsNextPage = null; // no next page until locationSearch is 2 chars long
    //   return;
    // }
    this.locationsNextPage = 1;
    this.getLocations();
  }

  onIsGSSChange(value: boolean): void {
    this.isGSS = !this.isGSS;
  }

  onLocationTypeChange(value: number): void {
    this.locationType = value;

    if (value === LOCATION_TYPE.REGION) {
      this.getRegions();
    }
  }

  locationValid(): boolean {
    return Boolean(
      (this.locationType === LOCATION_TYPE.LOCATION &&
        this.selectedLocations.length > 0) ||
        (this.locationType === LOCATION_TYPE.REGION && this.selectedRegion)
    );
  }

  allSelectedLocationsInSameCountry(): boolean {
    if (this.selectedLocations.length === 0) return false;
    const firstCountryId = this.selectedLocations[0].countryId;
    return !this.selectedLocations.find((l) => l.countryId !== firstCountryId);
  }

  onSelectedLocationsChange(locations: ?Array<Location>) {
    this.selectedLocations = locations || [];

    if (
      this.selectedRateType?.value === RATE_TYPE.HOURLY &&
      this.selectedIndustry &&
      this.selectedLocations.length > 0
    ) {
      this.getWorkerTypes().catch(() => {});
    }

    this.showItemAdded = false;
    this.showItemExists = false;
    this.isGSS = false;
  }

  getLocationsWithSearch = async (searchQuery: string): Promise<Array<Location>> => {
    this.locationSearch = searchQuery;
    return await this.getLocations();
  };

  async getLocations(): Promise<any> {
    // if (!this.selectedUserCountry) return;

    // if (!this.locationsHasNextPage()) return;

    let search =
      this.locationSearch && this.locationSearch.trim() !== ""
        ? this.locationSearch
        : null;

    if (!search) {
      this.locations = [];
      return this.locations;
    }

    const abortController = this.network.createAbortController();
    this.network.loading = true;

    // if (!search && this.selectedUserCountry) search = this.selectedUserCountry.toString();

    // let countryIds = null;
    // if (this.selectedUserCountry) {
    //   countryIds = [this.selectedUserCountry.id];
    // }

    const query = `
      query getLocations($search: String!){
        viewer {
          locations(search: $search) {
            fullSubtitle
            subtitle
            title
            type
            fullTitle
            highlight {
              subtitle
              title
            }
            locationId
            countryCode
            countryId
          }
        }
      }
    `;

    const variables = { search };

    let res = null;
    try {
      res = await this.fetchGraphQL(query, variables, abortController.source.token);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return Promise.reject(e);
      }

      this.network.loading = false;
      this.network.handleError("Getting Client Locations", e);

      // TODO: Display user friendly error message
      return Promise.reject(e);
    }

    if (abortController.aborted()) {
      return Promise.reject("aborted");
    }

    return runInAction("getLocations--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        return Promise.reject("No results");
      }

      // if (this.locationsNextPage) {
      //   this.locationsCurrentPage = this.locationsNextPage;
      //   this.locationsNextPage = res.next ? this.locationsNextPage + 1 : null;
      // }

      const locations = res.data.viewer.locations;

      // const mappedLocations = locations.map(locationData => {
      //   return new Location(this, locationData);
      // });
      //
      // if (this.locationsCurrentPage === 1) {
      //   this.locations = mappedLocations;
      // } else {
      //   this.locations = this.locations.concat(mappedLocations);
      // }
      //
      // return this.locations;

      this.locations = locations.map((locationData) => {
        return new Location(this, locationData);
      });

      return this.locations;
    });
  }

  // ------------------------------------------------------------
  //
  //   Regions
  //
  // ------------------------------------------------------------

  onSelectedRegionChange(selectedRegion: ?Region): void {
    this.selectedRegion = selectedRegion;

    if (
      this.selectedRateType?.value === RATE_TYPE.HOURLY &&
      this.selectedIndustry &&
      this.selectedRegion
    ) {
      this.addTitleWizard.goTo(2);
      this.getWorkerTypes().catch(() => {});
      // if (this.selectedUserCountry && this.jobTitles.length === 0) this.jobTitlesPagination.goFetch();
    } else {
      this.addTitleWizard.goTo(1);
    }
    this.showItemAdded = false;
    this.showItemExists = false;
  }

  onRegionSearchChange(query: string): void {
    this.regionSearch = query;
    this.getRegions();
  }

  resetRegions(): void {
    this.selectedRegion = null;
    this.regions = [];
    this.regionSearch = "";
    this.allRegions = [];
  }

  async getAllUserRegions(): Promise<any> {
    if (this.regionsNetwork.loading === true) {
      this.regionsNetwork.cancel();
    }

    const query = `
      query getRegions {
        viewer {
          regions(order: [{field: NAME}]) {
            edges {
              node {
                ...region
              }
            }
          }
        }
      }

      fragment region on NewRegionNode {
        regionId
        name
        country {
          locationId
          isoCode
        }
      }
    `;

    this.regionsNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {}, this.regionsNetwork.getCancelToken());
    } catch (e) {
      if (this.regionsNetwork.isCancelError(e)) {
        return e;
      }

      this.regionsNetwork.handleError("Getting Client Regions", e);
      if (res !== null) {
        this.regionsNetwork.logGraphQLError("Get Client Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getAllUserRegions--success", () => {
      this.regionsNetwork.loading = false;
      this.regionsNetwork.error = null;
      if (this.regionsNetwork.logGraphQLError("Get Client Regions query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const regions = res.data.viewer.regions.edges;

      this.allRegions = regions.map((region) => {
        return new Region(this, region.node);
      });

      return this.allRegions;
    });
  }

  async getRegions(): Promise<any> {
    // if (!this.selectedUserCountry) return;

    if (this.regionsNetwork.loading === true) {
      this.regionsNetwork.cancel();
    }

    const filterFields = [];
    // let certifiedCountries = [];
    // if (this.selectedUserCountry) {
    //   certifiedCountries.push(this.selectedUserCountry.countryId);
    // }
    // filterFields.push(`countryIdIn: [${certifiedCountries.join(", ")}]`);

    if (this.regionSearch && this.regionSearch.trim() !== "") {
      filterFields.push(`nameIContains: "${this.regionSearch}"`);
    }

    const filters = `filters: {${filterFields.join(", ")}},`;

    const query = `
      query getRegions {
        viewer {
          regions(${filters} order: [{field: NAME}]) {
            edges {
              node {
                ...region
              }
            }
          }
        }
      }

      fragment region on NewRegionNode {
        regionId
        name
        country {
          locationId
          isoCode
        }
      }
    `;

    this.regionsNetwork.loading = true;
    let res = null;

    try {
      res = await this.fetchGraphQL(query, {}, this.regionsNetwork.getCancelToken());
    } catch (e) {
      if (this.regionsNetwork.isCancelError(e)) {
        return e;
      }

      this.regionsNetwork.handleError("Getting Client Regions", e);
      if (res !== null) {
        this.regionsNetwork.logGraphQLError("Get Client Regions query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    return runInAction("getRegions--success", () => {
      this.regionsNetwork.loading = false;
      this.regionsNetwork.error = null;
      if (this.regionsNetwork.logGraphQLError("Get Client Regions query", res)) {
        // TODO: Display user friendly error message
        return;
      }

      if (!res) {
        return;
      }

      const regions = res.data.viewer.regions.edges;

      this.regions = regions.map((region) => {
        return new Region(this, region.node);
      });

      return this.regions;
    });
  }

  // ------------------------------------------------------------
  //
  //   Worker Types
  //
  // ------------------------------------------------------------

  onSelectedWorkerTypeChange(selectedWorkerType: ?WorkerType): void {
    this.selectedWorkerType = selectedWorkerType;

    this.showItemAdded = false;
    this.showItemExists = false;
  }

  resetWorkerTypes(): void {
    this.selectedWorkerType = null;
    this.workerTypes = [];
  }

  async getWorkerTypes(): Promise<Array<WorkerType>> {
    if (this.selectedRateType?.value !== RATE_TYPE.HOURLY) return [];
    if (!this.selectedIndustry) return [];
    if (this.selectedLocations.length === 0 && !this.selectedRegion) return [];

    if (this.workerTypesNetwork.loading === true) {
      this.workerTypesNetwork.cancel();
    }

    const industryId = this.selectedIndustry.id;
    let countryId = this.selectedLocation?.countryId || this.selectedRegion?.countryId;
    if (this.selectedRegion) {
      countryId = this.selectedRegion.countryId;
    } else if (this.allSelectedLocationsInSameCountry()) {
      countryId = this.selectedLocations[0].countryId;
    }

    if (!countryId) return [];

    const variables = { countryId, industryId };

    const query = `
      query WorkerTypes($countryId: Int!, $industryId: Int) {
        viewer {
          workerTypes(countryId: $countryId, industryId: $industryId) {
            id
            name
            description
          }
        }
      }
    `;

    let res = null;
    this.workerTypesNetwork.loading = true;

    try {
      res = await this.fetchGraphQL(
        query,
        variables,
        this.workerTypesNetwork.getCancelToken()
      );
      // should hit get request
    } catch (e) {
      console.error("Error in getting worker types", e);
      this.workerTypesNetwork.loading = false;
      throw e; // Prevent success action from running
    }

    this.workerTypesNetwork.loading = false;

    if (res.errors) {
      console.error("Errors", res.errors);
      throw res.errors;
    }

    runInAction("getWorkerTypes--success", () => {
      this.workerTypes = res?.data?.viewer?.workerTypes || [];
    });

    return this.workerTypes;
  }

  // ------------------------------------------------------------
  //
  //   Rate Cards
  //
  // ------------------------------------------------------------

  onRateCardActionChange(value: number) {
    this.rateCardAction = value;
  }

  onNewRateCardNameChange(name: string): void {
    this.newRateCardName = name;
  }

  onSelectedRateCardChange(selectedRateCard: ?RateCard): void {
    this.selectedRateCard = selectedRateCard;
  }

  onRateCardSearchChange(query: string): void {
    if (this.rateCardSearch === query) return;
    this.rateCardSearch = query;

    this.rateCards = [];
    this.rateCardsPagination = new PaginationState(this.getRateCards, 10);
    this.rateCardsLoadFirstPage();
  }

  resetRateCards(): void {
    this.selectedRateCard = null;
    this.rateCards = [];
    this.rateCardsPagination = new PaginationState(this.getRateCards, 10);
    this.rateCardSearch = "";
    // this.selectedCategory = "";
  }

  rateCardsHasNextPage(): boolean {
    if (!this.rateCardsPagination.hasLoaded) return true;
    return this.rateCardsPagination.currentPage < this.rateCardsPagination.pageCount;
  }

  rateCardsLoadNextPage() {
    if (!this.rateCardsHasNextPage()) return;

    if (!this.rateCards.length) {
      this.rateCardsPagination.goFetch();
    } else {
      this.rateCardsPagination.goFetch();
    }
  }

  rateCardsLoadFirstPage() {
    console.log("load rc first page");
    this.rateCardsPagination.goFetch();
  }

  async getSelectedRateCard(rateCardId: string): Promise<any> {
    const abortController = this.network.createAbortController();
    this.network.loading = true;

    const query = `
      query getRateCard($rateCardId: Int!) {
        viewer {
          rateCardDetail(id: $rateCardId) {
            ratecardId
            name
            shared
            searchCount
            owner {
              userId
              firstName
              lastName
            }
          }
        }
      }
    `;
    const variables = { rateCardId };

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables, abortController.source.token);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.loading = false;
      this.network.handleError("Getting Rate Card", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    if (abortController.aborted()) {
      return;
    }

    return runInAction("getRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return null;
      }

      // NOTE: flow refinement
      if (res === null) {
        return null;
      }

      const rateCardData = res.data.viewer.rateCardDetail;
      if (!rateCardData) {
        this.selectedRateCard = null;
        return null;
      }

      const rateCard = new RateCard(this, rateCardData);
      this.selectedRateCard = rateCard;

      return rateCard;
    });
  }

  async getRateCards(pageQuery: PageQuery): Promise<PaginationInfo> {
    if (!this.rateCardsHasNextPage())
      return { totalCount: 0, startCursor: "", endCursor: "" };

    const abortController = this.network.createAbortController();
    this.network.loading = true;

    let params: string[] = pageQuery.params;
    let args = pageQuery.args;
    let variables = pageQuery.variables;
    let filtersCriteria: string[] = [];

    let sortCriteria: string[] = ["{ field: NAME, direction: ASC }"];

    if (this.rateCardSearch && this.rateCardSearch.trim() !== "") {
      params.push("$rateCardNameSearch: String!");
      filtersCriteria.push(`nameIContains: $rateCardNameSearch`);
      variables.rateCardNameSearch = this.rateCardSearch;
    }

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    const queryFiltersCriteria = filtersCriteria.join(", ");
    const querySortCriteria = sortCriteria.join(", ");

    const query = `
      query getRateCards(${queryParams}) {
        viewer {
          allRateCards(${queryArgs}, filters: { ${queryFiltersCriteria} }, order: [${querySortCriteria}]) {
            edges {
              node {
                ratecardId
                name
                shared
                searchCount
                owner {
                  userId
                  firstName
                  lastName
                }
              }
              cursor
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
          }
        }
      }
    `;

    let res = null;

    try {
      res = await this.fetchGraphQL(query, variables, abortController.source.token);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.loading = false;
      this.network.handleError("Getting Rate Cards", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Rate Card query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    if (abortController.aborted()) {
      return;
    }

    return runInAction("getRateCards--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (this.network.logGraphQLError("Get Rate Cards query", res)) {
        // TODO: Display user friendly error message
        return { totalCount: 0, startCursor: "", endCursor: "" };
      }

      // NOTE: flow refinement
      if (res === null) {
        return;
      }

      const rateCards = res.data.viewer.allRateCards;

      const mappedRateCards = rateCards.edges.map((rateCard) => {
        // console.log("creating", rateCard);
        return new RateCard(this, rateCard.node);
      });

      if (this.rateCardsPagination.currentPage === 0) {
        this.rateCards = mappedRateCards;
      } else {
        this.rateCards = this.rateCards.concat(mappedRateCards);
      }

      return {
        totalCount: rateCards.totalCount,
        startCursor: rateCards.pageInfo.startCursor,
        endCursor: rateCards.pageInfo.endCursor,
      };
    });
  }

  // ------------------------------------------------------------
  //
  //   Remaining Searches
  //
  // ------------------------------------------------------------

  async getRemainingSearches() {
    if (this.remainingSearchesNetwork.loading) {
      return;
    }

    const query = `
      query getSearchesRemaining {
        viewer {
          user {
            resourceAllocations(resourceType: SEARCH) {
              balance
              isUnlimited
            }
          }
        }
      }
    `;

    let res = null;
    this.remainingSearchesNetwork.loading = true;

    try {
      res = await this.fetchGraphQL(query, null);
      // should hit get request
    } catch (e) {
      this.remainingSearchesNetwork.loading = false;
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Getting Remaining Searches", e);
      if (res !== null) {
        this.network.logGraphQLError("Getting Remaining Searches", res);
      }

      this.errorMessage = (
        <p>
          Sorry! we could not retrieve the amount of searches allocated for your user.
        </p>
      );
      this.errorModal.showModal();
      return e;
    }

    if (res.errors) {
      this.remainingSearchesNetwork.loading = false;
      console.error("Errors", res.errors);
      return;
    }

    runInAction("getRemainingSearches--success", () => {
      this.remainingSearchesNetwork.loading = false;

      if (!res) return;

      const resourceAllocations = res.data.viewer.user.resourceAllocations;

      if (!resourceAllocations || !resourceAllocations.length || res.data.errors) {
        this.errorMessage = (
          <p>
            Sorry! we could not retrieve the amount of searches allocated for your user.
          </p>
        );
        this.errorModal.showModal();
        return null;
      }

      this.remainingSearches = resourceAllocations[0].balance;
      this.isUnlimited = resourceAllocations[0].isUnlimited;
    });
  }

  // ------------------------------------------------------------
  //
  //   Batch Search
  //
  // ------------------------------------------------------------

  jobsToSearchItemsToggleAllSelected(): void {
    const selected = !this.jobsToSearchItemsAllSelected;
    this.jobsToSearchItemsView.forEach((jobItem) => {
      jobItem.setSelected(selected);
    });
  }

  addJobToBatchSearch(): void {
    this.showItemAdded = false;
    this.itemsAdded = 0;
    this.showItemExists = false;
    this.itemsExist = 0;
    // const title = this.jobTitles;
    // const country = this.selectedUserCountry;
    const industry = this.selectedIndustry;
    const locations = this.selectedLocations;
    const region = this.selectedRegion;
    const isGSS = this.isGSS;
    const workerType = this.showWorkerTypes ? this.selectedWorkerType : null;
    const selectedTitle = this.selectedJobTitle;
    const tags = this.selectedTags;
    const isLocationsJob =
      this.locationType === LOCATION_TYPE.LOCATION && locations.length > 0;
    const isRegionsJob = this.locationType === LOCATION_TYPE.REGION && region;

    // if (location && country) location.countryCode = country.countryCode;

    this.infoMessage = null;
    const totalItemsAfterAdding = locations.length + this.jobsToSearchItemsTotalCount;
    if (!this.selectedJobTitle) {
      this.infoMessage = <p>You must select at least one Job Title.</p>;
    } else if (totalItemsAfterAdding > this.jobsToSearchLimit) {
      this.infoMessage = (
        <p>
          Batch Search has a limit of {`${this.jobsToSearchLimit}`} searches. Please
          remove some of your searches before continuing.
        </p>
      );
    } else if (totalItemsAfterAdding > this.remainingSearches && !this.isUnlimited) {
      this.infoMessage = (
        <p>
          Sorry! you have exceeded the number of searches allocated for your user. Only{" "}
          <strong>{`${this.remainingSearchesAfterRun}`}</strong> remaining. Please remove
          some of your searches before continuing.
        </p>
      );
    } else if (!industry) {
      this.infoMessage = <p>You must select an Industry.</p>;
    } else if (!locations.length && !region) {
      this.infoMessage = <p>You must select a Location or a Region.</p>;
    }

    if (this.infoMessage) {
      this.infoModal.showModal();
      return;
    }

    // flow check, this will never happen at this point
    // but flow does not detect that
    if (!selectedTitle || !industry || (!locations && !region)) return;

    const jobId = BatchSearchJob.getId(
      selectedTitle.id,
      industry.id,
      workerType?.id,
      isGSS,
      isRegionsJob
    );
    let job: ?BatchSearchJob = this.jobsToSearch.find((job) => job.id === jobId);

    if (!job) {
      const title: RawJobTitle = selectedTitle.clone();
      title.labelData = new BatchSearchCreateLabelData(
        this.jobLabelValue,
        this.jobDescriptionValue
      );
      if (isRegionsJob) {
        job = new BatchSearchJob(title, industry, [], [], [region], workerType, tags);
        console.log("creating region job:", job);
      } else if (isGSS) {
        job = new BatchSearchJob(title, industry, [], locations, [], workerType, tags);
        console.log("creating gss job:", job);
      } else {
        job = new BatchSearchJob(title, industry, locations, [], [], workerType, tags);
        console.log("creating location job:", job);
      }
      this.jobsToSearch.push(job);

      this.jobsToSearch = this.jobsToSearch.sort((job1, job2) => {
        const title1 = job1.jobTitle.title.toLowerCase();
        const title2 = job2.jobTitle.title.toLowerCase();
        return _compareStrings(title1, title2);
      });
    } else {
      if (isRegionsJob) {
        job.addRegion(region) ? (this.itemsAdded += 1) : (this.itemsExist += 1);
        console.log("adding to region job:", job);
      }
      if (isLocationsJob) {
        locations.forEach((location) => {
          job.addLocation(location, isGSS)
            ? (this.itemsAdded += 1)
            : (this.itemsExist += 1);
        });
        isGSS
          ? console.log("adding to gss job:", job)
          : console.log("adding to location job:", job);
      }

      job.tags = tags; // update tags
    }

    this.showItemAdded = this.itemsAdded > 0;
    this.showItemExists = this.itemsExist > 0;

    if (this.jobsToRemoveBeforeAdding && this.jobsToRemoveBeforeAdding.length > 0) {
      this.jobsToRemoveBeforeAdding.forEach((jobToRemove) => {
        this.removeJobFromSearch(jobToRemove);
      });
      this.jobsToRemoveBeforeAdding = [];
    }
  }

  addMoreJobs(clear: boolean = false) {
    if (clear) {
      // this.selectedUserCountry = null;
      this.resetLocations();
      this.resetRegions();
      this.resetIndustries();
      this.resetWorkerTypes();
      this.resetJobTitles();
      this.addTitleWizard.goTo(1);
    }

    this.showAddJobTitlesWizard = true;
  }

  removeJobFromSearch(jobListItem: BatchSearchJobListItem): void {
    this.showItemAdded = false;
    this.showItemExists = false;
    const job = jobListItem.batchSearchJob;
    const location = jobListItem.batchSearchJobLocation;
    const isGSS = jobListItem.isGSS;

    if (location instanceof Region) {
      job.removeRegion(location);
    } else {
      job.removeLocation(location, isGSS);
    }

    const noRegionsLeft = job.regions.length === 0;
    const noLocationsLeft = job.locations.length === 0;
    const noGSSLocationsLeft = job.gssLocations.length === 0;

    if (noRegionsLeft && noLocationsLeft && noGSSLocationsLeft) {
      // filter out this job to remove it from the list
      this.jobsToSearch = this.jobsToSearch.filter((item: BatchSearchJob) => {
        return item.id !== job.id;
      });

      this.showAddJobTitlesWizard = this.jobsToSearch.length === 0;
    }

    // remove item view state
    if (this.jobsToSearchItemsViewState.has(jobListItem.id)) {
      this.jobsToSearchItemsViewState.delete(jobListItem.id);
    }
  }

  onRemoveInvalidJobsFromSearch(): void {
    if (!this.invalidJobs) return;

    this.invalidJobs.forEach((invalidJob) => {
      this.removeJobFromSearch(invalidJob);
    });

    this.invalidJobsErrorModal.hideModal();
    this.invalidJobs = null;

    if (this.jobsToSearch.length === 0) {
      this.wizard.goTo(2);
      this.showAddJobTitlesWizard = true;
    }
  }

  findInvalidJobs(invalidJobsData: InvalidJobData[]): BatchSearchJobListItem[] {
    return invalidJobsData.map((jobData) => {
      let foundLocation = null;
      let isGss = false;

      // find the batch search job
      const locationsJobId = BatchSearchJob.getId(
        jobData.jobOriginalTitleId,
        jobData.industryId,
        jobData?.invalidWorkerTypeId
      );
      const gssJobId = BatchSearchJob.getId(
        jobData.jobOriginalTitleId,
        jobData.industryId,
        jobData?.invalidWorkerTypeId,
        true,
        false
      );
      const regionsJobId = BatchSearchJob.getId(
        jobData.jobOriginalTitleId,
        jobData.industryId,
        jobData?.invalidWorkerTypeId,
        false,
        true
      );
      const foundJob = this.jobsToSearch.find((batchSearchJob) => {
        if (batchSearchJob.id === locationsJobId) {
          foundLocation = batchSearchJob.locations.find(
            (loc) => loc.id.toString() === jobData.invalidLocationId
          );
          if (foundLocation) return true;
        } else if (batchSearchJob.id === regionsJobId) {
          foundLocation = batchSearchJob.regions.find(
            (reg) => reg.id.toString() === jobData.invalidLocationId
          );
          if (foundLocation) return true;
        } else if (batchSearchJob.id === gssJobId) {
          foundLocation = batchSearchJob.gssLocations.find(
            (loc) => loc.id.toString() === jobData.invalidLocationId
          );
          if (foundLocation) {
            isGss = true;
            return true;
          }
        }

        return false;
      });

      // batch search job must exist
      if (!foundJob || !foundLocation) {
        throw new DataError(
          "Invalid Job received but not found on batch search.",
          jobData
        );
      }

      // find the batch search job list item
      const jobItemId = BatchSearchJobListItem.getId(
        foundJob.jobTitle.id,
        foundJob.industry.id,
        foundLocation.id,
        isGss,
        foundJob.workerType?.id || "none"
      );
      const foundItem = this.jobsToSearchItems.find(
        (jobItem) => jobItem.id === jobItemId
      );

      // list item must exist
      if (!foundItem) {
        throw new DataError(
          "Invalid Job was found but not the view item that job.",
          jobData
        );
      }

      return foundItem;
    });
  }

  editJobToSearch(jobListItem: BatchSearchJobListItem): void {
    window.scrollTo({ top: 80, behavior: "smooth" });
    this.loadingForEdit = true;
    this.showItemExists = false;
    this.showItemAdded = false;
    this.jobLabelHelperText = "";
    const job = jobListItem.batchSearchJob;
    const location = jobListItem.batchSearchJobLocation;
    const isGSS = jobListItem.isGSS;
    const locations = isGSS ? [...job.gssLocations] : [...job.locations];

    // const country = this.userCountries.find((c: Location) => {
    //   return c.countryId === location.countryId || c.countryCode === location.countryCode;
    // });
    // if (!country) {
    //   console.error("country not found!");
    //   return;
    // }
    // this.selectedUserCountry = country;
    this.selectedIndustry = null;
    this.resetLocations();
    this.resetJobTitles();
    this.resetWorkerTypes();

    const promises = [];
    if (location instanceof Region) {
      promises.push(this.getRegions());
    } else {
      // Note: no need to load locations here
      // promises.push(this.getLocations());
      promises.push(Promise.resolve());
    }

    Promise.all(promises).then(() => {
      if (location instanceof Region) {
        this.locationType = LOCATION_TYPE.REGION;
        this.selectedRegion = location;
      } else {
        this.locationType = LOCATION_TYPE.LOCATION;
        this.selectedLocations = locations;
        this.isGSS = isGSS;
      }

      this.selectedIndustry = job.industry;
      this.selectedJobTitle = job.jobTitle;
      this.selectedTags = job.tags;
      const labelData = job.jobTitle.labelData;
      this.jobLabelValue = (labelData ? labelData.jobLabel : job.jobTitle.title) || "";
      this.jobDescriptionValue =
        (labelData ? labelData.description : job.jobTitle.description) || "";

      this.jobLabelIsUnique = true; // signal this label is ok

      if (job.workerType) {
        this.getWorkerTypes().then(() => {
          this.selectedWorkerType = this.workerTypes.find(
            (wt) => wt.id === job.workerType?.id
          );
          this.loadingForEdit = false;
        });
      } else {
        this.loadingForEdit = false;
      }

      // job.jobTitle.getDescription();
      // job.jobTitle.toggleSelected(true)
    });

    // this.jobTitleSelectionEnabled = false;
    // this.jobTitlesPagination.goFetch().then(() => {
    //   this.selectedJobTitle = job.jobTitle;
    //   const labelData = job.jobTitle.labelData;
    //   this.jobLabelValue = (labelData ? labelData.jobLabel : job.jobTitle.title) || "";
    //   this.jobDescriptionValue = (labelData ? labelData.description : job.jobTitle.description) || "";
    //   this.jobTitleSelectionEnabled = true;
    // });

    if (location instanceof Region) {
      this.removeJobFromSearch(jobListItem);
    } else {
      const locationsIds = locations.map((l) => l.id);
      const searchItems = this.jobsToSearchItemsView.filter((s) => {
        const sameJob = s.batchSearchJob.id === job.id;
        const hasLocation = locationsIds.includes(s.batchSearchJobLocation.id);
        return sameJob && hasLocation && s.isGSS === isGSS;
      });

      searchItems.forEach((item) => this.removeJobFromSearch(item));
    }
    // this.addTitleWizard.goTo(3);
    this.showAddJobTitlesWizard = true;
  }

  addNewLocationForAllJobs(): void {
    // if (!this.jobsToSearchItemsAllInSameCountry) return;

    const selectedJobs = this.jobsToSearchItems;
    this.jobsToRemoveBeforeAdding = [];

    // select country
    // let selectedCountryCode = selectedJobs[0].batchSearchJobLocation.countryCode;
    // this.selectedUserCountry = this.userCountries.find((c: Location) => c.countryCode === selectedCountryCode);

    // select location
    this.resetLocations();
    this.resetRegions();
    this.locationType = LOCATION_TYPE.LOCATION;

    this.getLocations();
    this.getRegions();

    // select industry
    this.selectedIndustry = selectedJobs[0].batchSearchJob.industry;

    // select titles
    this.resetJobTitles();
    const seenTitles = [];
    selectedJobs.forEach((job) => {
      if (seenTitles.includes(job.batchSearchJob.jobTitle.id)) return;

      // if (!this.jobTitlesViewState.has(job.batchSearchJob.jobTitle.id)) {
      //   this.jobTitlesViewState.set(job.batchSearchJob.jobTitle.id, {
      //     selected: false,
      //     editing: false,
      //     expanded: false
      //   });
      // }

      // job.batchSearchJob.jobTitle.viewState = this.jobTitlesViewState.get(job.batchSearchJob.jobTitle.id);
      job.batchSearchJob.jobTitle.toggleSelected(true);
      seenTitles.push(job.batchSearchJob.jobTitle.id);

      // reset industries if they're not the same across all selected jobs
      if (
        this.selectedIndustry &&
        this.selectedIndustry.id !== job.batchSearchJob.industry.id
      )
        this.resetIndustries();
    });
    this.jobTitlesPagination.goFetch().then(() => {
      this.addTitleWizard.goTo(1);
    });

    // this.skipResetJobsOnChange = true;

    // show add titles form
    this.showAddJobTitlesWizard = true;
  }

  changeLocationForAllSelectedJobs(): void {
    // if (!this.jobsToSearchItemsSelectedAllInSameCountry) return;

    const selectedJobs = this.jobsToSearchItems.filter((job) => job.viewState.selected);
    this.jobsToRemoveBeforeAdding = selectedJobs;

    // select country
    // let selectedCountryCode = selectedJobs[0].batchSearchJobLocation.countryCode;
    // this.selectedUserCountry = this.userCountries.find((c: Location) => c.countryCode === selectedCountryCode);

    // select location
    this.resetLocations();
    this.resetRegions();
    this.locationType = LOCATION_TYPE.LOCATION;

    this.getLocations();
    this.getRegions();

    // select industry
    this.selectedIndustry = selectedJobs[0].batchSearchJob.industry;

    // select titles
    this.resetJobTitles();
    const seenTitles = [];
    selectedJobs.forEach((job) => {
      if (seenTitles.includes(job.batchSearchJob.jobTitle.id)) return;

      // if (!this.jobTitlesViewState.has(job.batchSearchJob.jobTitle.id)) {
      //   this.jobTitlesViewState.set(job.batchSearchJob.jobTitle.id, {
      //     selected: false,
      //     editing: false,
      //     expanded: false
      //   });
      // }

      // job.batchSearchJob.jobTitle.viewState = this.jobTitlesViewState.get(job.batchSearchJob.jobTitle.id);
      job.batchSearchJob.jobTitle.toggleSelected(true);
      seenTitles.push(job.batchSearchJob.jobTitle.id);

      // reset industries if they're not the same across all selected jobs
      if (
        this.selectedIndustry &&
        this.selectedIndustry.id !== job.batchSearchJob.industry.id
      )
        this.resetIndustries();
    });
    this.jobTitlesPagination.goFetch().then(() => {
      this.addTitleWizard.goTo(1);
    });

    // this.skipResetJobsOnChange = true;

    // show add titles form
    this.showAddJobTitlesWizard = true;
  }

  validateStep(step: number): boolean {
    switch (step) {
      case 1:
        if (!Boolean(this.selectedRateType)) {
          this.infoMessage = <div>Select a Rate Type to continue</div>;
          this.infoModal.showModal();
          return false;
        }
        return true;
      case 2:
        if (this.jobsToSearch.length === 0) {
          this.infoMessage = (
            <div>
              Add at least one title in a specific location to the batch search in order
              to continue
            </div>
          );
          this.infoModal.showModal();
          return false;
        }
        return true;
      case 3:
        if (
          this.rateCardAction === RATE_CARD_ACTION.USE_EXISTING &&
          !Boolean(this.selectedRateCard)
        ) {
          this.infoMessage = <div>Select a Rate Card to continue</div>;
          this.infoModal.showModal();
          return false;
        } else if (
          this.rateCardAction === RATE_CARD_ACTION.CREATE &&
          !Boolean(this.newRateCardName)
        ) {
          this.infoMessage = <div>Enter Rate Card name to continue</div>;
          this.infoModal.showModal();
          return false;
        }
        return true;
      default:
        return true;
    }
  }

  onCurrentStepChange(currentStep: number): void {
    switch (currentStep) {
      case 1:
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
      case 2:
        this.resetTags();
        this.resetJobTitles();
        this.resetIndustries();
        this.resetLocations();
        this.resetRegions();
        this.locationType = LOCATION_TYPE.LOCATION;
        break;
      case 3:
        window.scrollTo({ top: 0, behavior: "smooth" });
        break;
      default:
        break;
    }
  }

  addTitleValidateStep(step: number): boolean {
    switch (step) {
      // case 1:
      //   if (!Boolean(this.selectedRateType)) {
      //     this.infoMessage = <p>Select a Rate Type to continue</p>;
      //     this.infoModal.showModal();
      //     return false;
      //   }
      //   return true;
      // case 2:
      //   if (this.jobsToSearch.length === 0) {
      //     this.infoMessage = (
      //       <p>Add at least one title in a specific location to the batch search in order to continue</p>
      //     );
      //     this.infoModal.showModal();
      //     return false;
      //   }
      //   return true;
      // case 3:
      //   if (this.rateCardAction === RATE_CARD_ACTION.USE_EXISTING && !Boolean(this.selectedRateCard)) {
      //     this.infoMessage = <p>Select a Rate Card to continue</p>;
      //     this.infoModal.showModal();
      //     return false;
      //   } else if (this.rateCardAction === RATE_CARD_ACTION.CREATE && !Boolean(this.newRateCardName)) {
      //     this.infoMessage = <p>Enter Rate Card name to continue</p>;
      //     this.infoModal.showModal();
      //     return false;
      //   }
      //   return true;
      default:
        return true;
    }
  }

  addTitleOnCurrentStepChange(currentStep: number): void {
    switch (currentStep) {
      // case 2:
      //   this.resetJobTitles();
      //   this.resetIndustries();
      //   this.resetLocations();
      //   this.resetRegions();
      //   this.locationType = LOCATION_TYPE.LOCATION;
      //   break;
      default:
        break;
    }
  }

  resetAll() {
    this.selectedRateType = null;
    // this.resetUserCountries();
    this.resetJobTitles();
    this.resetIndustries();
    this.resetLocations();
    this.resetRegions();
    this.resetWorkerTypes();
    this.resetRateCards();
    this.resetTags();
    this.jobsToSearch = [];
    this.jobsToSearchItemsViewState = observable.map({});
    this.jobsToRemoveBeforeAdding = null;
    this.invalidJobs = null;
    this.clearJobsToSearchFilters();
    this.showItemAdded = false;
    this.showItemExists = false;
  }

  cancel() {
    if (!this.router) return;
    const router = this.router;

    this.showAddJobTitlesWizard = true;
    this.addTitleWizard.goTo(1);
    this.wizard.goTo(1);
    this.resetAll();
    router.goBack();
  }

  async createAndRunBatchSearch(): Promise<any> {
    if (this.network.loading) return; // I don't wanna call it twice

    // if for some reason we got here without going through the whole process
    // check that all steps are valid.
    for (let i = 1; i < 4; i++) {
      if (!this.validateStep(1)) return;
    }

    const jobs = this.jobsToSearch.map((item) => item.toJsonObject(this.isGSS));
    const rateType = this.selectedRateType ? this.selectedRateType.value : null;
    const rateCardName =
      this.rateCardAction === RATE_CARD_ACTION.CREATE
        ? this.newRateCardName
        : this.selectedRateCard
        ? this.selectedRateCard.name
        : null;

    this.network.loading = true;
    let res = null;

    const variables = {
      input: {
        rateCardLabel: rateCardName,
        rateType: rateType,
        jobs: jobs,
      },
    };
    const query = `
      mutation createBatchSearch($input : CreateBatchSearchInput!){
        createBatchSearch(input:$input){
          batchSearchUuid

          errors {
            __typename
            ...on InvalidJobsError {
              jobs {
                jobOriginalTitleId
                industryId
                invalidLocationId
                invalidCountryId
              }
            }
          }
        }
      }
    `;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      if (e.response && e.response.status === 400 && e.response.data.jobs) {
        this.invalidJobs = this.findInvalidJobs(e.response.data.jobs);
        this.invalidJobsErrorModal.showModal();
      } else {
        this.network.handleError("Creating Batch Search", e);
        this.errorMessage = (
          <p>
            Sorry! Batch Search could not run. There was an error during the operation.
          </p>
        );
        this.errorModal.showModal();
      }

      this.network.loading = false;
      return e;
    }

    return runInAction("createAndRunBatchSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        this.errorMessage = (
          <p>
            Sorry! Batch Search could not run. There was an error during the operation.
          </p>
        );
        this.errorModal.showModal();
        return;
      }

      if (res.data.createBatchSearch.errors) {
        const errors = res.data.createBatchSearch.errors;
        const invalidJobsError = errors.find(
          (err) => err.__typename === "InvalidJobsError"
        );
        if (invalidJobsError) {
          this.invalidJobs = this.findInvalidJobs(invalidJobsError.jobs);
          this.invalidJobsErrorModal.showModal();
        } else {
          this.errorMessage = (
            <p>
              Sorry! Batch Search could not run. There was an error during the operation.
            </p>
          );
          this.errorModal.showModal();
          return;
        }
      }

      const batchSearchUUID = res.data.createBatchSearch.batchSearchUuid;
      if (!batchSearchUUID) {
        console.error("no search id received.");
        this.errorMessage = (
          <p>
            Sorry! Batch Search could not be created. There was an error during the
            operation.
          </p>
        );
        this.errorModal.showModal();
        return null;
      }

      this.resetAll();
      this.wizard.goTo(1);

      if (!this.router) return batchSearchUUID;

      this.router.push(`/batch-searches/${batchSearchUUID}/`);

      return batchSearchUUID;
    });
  }

  async rerunBatchSearch(searchUUID: string): Promise<any> {
    if (!searchUUID) return;
    if (this.network.loading) return;

    this.wizard.goTo(1);
    this.addTitleWizard.goTo(1);
    this.showAddJobTitlesWizard = false;
    this.network.loading = true;
    let res = null;

    const query = `
      query getBatchSearch($batchSearchId: String!) {
        viewer {
          batchSearch(batchSearchUuid: $batchSearchId) {
            uuid
            rateCardId
            rateCardLabel
            rateType
            finished
            statusDisplay
            searchesTotal
            rateCardLabel
            status
            updated
            created
            jobs {
              originalTitleId
              industryId
              jobDescription
              jobLabel
              jobCategory
              jobTitle
              regionIds
              workerType {
                id
                name
              }
              locations {
                locationId
                apiLocationId
                title
                subtitle
                fullTitle
                fullSubtitle
                countryId
                isoCode
              }
              gssLocations {
                locationId
                apiLocationId
                title
                subtitle
                fullTitle
                fullSubtitle
                countryId
                isoCode
              }
              tags {
                tagId
                name
              }
            }
          }
        }
      }
    `;

    const variables = {
      batchSearchId: searchUUID,
    };

    try {
      res = await this.fetchGraphQL(query, variables);
      // res = await this.saraApi.getBatchSearchDetailsFake(searchUUID);
    } catch (e) {
      if (this.network.isCancelError(e)) {
        return e;
      }

      this.network.handleError("Rerunning Batch Search", e);

      this.errorMessage = (
        <p>
          Sorry! we could not recreate the Batch Search. There was an error during the
          operation.
        </p>
      );
      this.errorModal.showModal();
      return e;
    }

    return runInAction("rerunBatchSearch--success", () => {
      this.network.loading = false;
      this.network.error = null;

      if (!res) {
        this.errorMessage = (
          <p>
            Sorry! we could not recreate the Batch Search. There was an error during the
            operation.
          </p>
        );
        this.errorModal.showModal();
        this.wizard.goTo(1);
        return;
      }

      const batchSearchData = res.data.viewer.batchSearch;

      this.network.loading = true;
      const rateCardId = batchSearchData.rateCardId;
      Promise.all([
        // this.getUserCountries(),
        this.getIndustries(),
        this.getAllUserRegions(),
        this.getSelectedRateCard(rateCardId),
        this.getRemainingSearches(),
      ])
        .then(() => {
          this.network.loading = false;

          // setup rate type
          const rateType = batchSearchData.rateType;
          this.selectedRateType = this.rateTypes.find((item) => item.value === rateType);

          // setup jobs & locations
          try {
            this.jobsToSearch = [];
            batchSearchData.jobs.forEach((jobData) => {
              // {
              //     "originalTitleId": 144511,
              //     "industryId": 1,
              //     "jobDescription": "",
              //     "jobLabel": "3D Animator - dltest",
              //     "jobCategory": "Creative",
              //     "jobTitle": "3D Animator",
              //     "regionIds": [],
              //     "workerType": null,
              //     "locations": [
              //         {
              //             "locationId": "14",
              //             "apiLocationId": "14",
              //             "name": "Australia",
              //             "isoCode": "AU"
              //         }
              //     ],
              //     "gssLocations": []
              // }

              // {
              //     "id": "95353",
              //     "title": "1-1-1 aaa test",
              //     "collection": 213,
              //     "isJobLabel": true,
              //     "category": "Test (changed name)",
              //     "showDescription": true,
              //     "shareInfo": {
              //         "jobLabelId": "302129",
              //         "searchOnly": true,
              //         "isMine": false,
              //         "sharedBy": {
              //             "userId": 38949,
              //             "firstName": "Old_55",
              //             "lastName": "Jr."
              //         }
              //     }
              // }

              const rawJobTitleData = {
                id: jobData.originalTitleId,
                title: jobData.jobTitle,
                description: jobData.jobDescription,
                category: jobData.jobCategory,
                showDescription: true,
                isJobLabel: undefined, // don't have it, can't be determined
                shareInfo: {
                  jobLabelId: undefined, // don't have it, can't be determined
                  searchOnly: undefined, // don't have it, can't be determined
                  isMine: undefined, // don't have it, can't be determined
                  sharedBy: undefined, // don't have it, can't be determined
                },
              };

              // const findCountryCode = function(countryId, store) {
              //   const country = store.userCountries.find(item => {
              //     if (!item || !item.countryId || !countryId) return false;
              //     return item.countryId.toString() === countryId.toString();
              //   });
              //   if (country) return country.countryCode;
              //   else return "";
              // };

              const rawJobTitle = new RawJobTitle(this, rawJobTitleData);
              rawJobTitle.labelData = new BatchSearchCreateLabelData(
                jobData.jobLabel,
                jobData.jobDescription
              );

              const industry = this.industries.find(
                (item) => item.id === jobData.industryId
              );
              const workerType = jobData.workerType;

              const regions = jobData.regionIds.map((regionId) => {
                const found = this.allRegions.find((item) => {
                  if (item.id !== regionId) return false;
                  // if (!item.countryCode) item.countryCode = findCountryCode(item.countryId, this);
                  return true;
                });
                if (!found) {
                  throw new RerunError(
                    "RerunBatchSearchError",
                    "Could not recreate the Batch Search. " +
                      "A region was used on the batch search that is no longer available."
                  );
                }

                return found;
              });

              let locations = jobData.locations.map((locationData) => {
                const location = new Location(this, locationData);
                // if (!location.countryCode) location.countryCode = findCountryCode(location.countryId, this);
                return location;
              });
              // Silently filter out locations that have been disabled in ccc
              locations = locations.filter(
                (location) => location.toString() && location.toString() !== ""
              );

              let gssLocations = jobData.gssLocations.map((locationData) => {
                const location = new Location(this, locationData);
                // if (!location.countryCode) location.countryCode = findCountryCode(location.countryId, this);
                return location;
              });
              // Silently filter out locations that have been disabled in ccc
              gssLocations = gssLocations.filter(
                (location) => location.toString() && location.toString() !== ""
              );

              let tags = null;
              if (jobData.tags) {
                tags = jobData.tags.map((tagData) => {
                  // NOTE: nueve gets tag ids from sara and then queries
                  // for existing tags so there's no need to validate here
                  return new Tag(tagData);
                });
              }

              if (!industry) {
                throw new RerunError(
                  "RerunBatchSearchError",
                  "Could not recreate the Batch Search. " +
                    "An industry was used on the batch search that is no longer available."
                );
              }

              if (locations && locations.length > 0) {
                this.jobsToSearch.push(
                  new BatchSearchJob(
                    rawJobTitle,
                    industry,
                    locations,
                    [],
                    [],
                    workerType,
                    tags
                  )
                );
              }

              if (gssLocations && gssLocations.length > 0) {
                this.jobsToSearch.push(
                  new BatchSearchJob(
                    rawJobTitle,
                    industry,
                    [],
                    gssLocations,
                    [],
                    workerType,
                    tags
                  )
                );
              }

              if (regions && regions.length > 0) {
                this.jobsToSearch.push(
                  new BatchSearchJob(
                    rawJobTitle,
                    industry,
                    [],
                    [],
                    regions,
                    workerType,
                    tags
                  )
                );
              }
            });

            // Silently filter out jobs with no locations. This can be caused
            // when we silently filter out locations that have been disable in ccc
            this.jobsToSearch = this.jobsToSearch.filter(
              (job) =>
                job.locations.length > 0 ||
                job.gssLocations.length > 0 ||
                job.regions.length > 0
            );

            this.wizard.goTo(4);
          } catch (error) {
            this.network.loading = false;
            if (error.name === "RerunBatchSearchError") {
              this.errorMessage = <p>{error.message}</p>;
            } else {
              this.errorMessage = (
                <p>
                  Sorry! we could not recreate the Batch Search. There was an error during
                  the operation.
                </p>
              );
            }
            this.errorModal.showModal();
            this.wizard.goTo(1);
            this.resetAll();
            console.error(error);
          }

          // check insufficient searches left
          if (
            !this.isUnlimited &&
            this.remainingSearches < this.jobsToSearchItemsTotalCount
          ) {
            this.errorMessage = (
              <p>
                Sorry! Your account doesn't have enough searches allocated. You need{" "}
                <strong>{this.jobsToSearchItemsTotalCount}</strong> for this batch search.
                You have <strong>{this.remainingSearches}</strong> left.
              </p>
            );
            this.errorModal.showModal();
            this.wizard.goTo(1);
            this.resetAll();
            return;
          }

          // setup rate card
          // already done in this.getSelectedRateCard(rateCardId) above
          this.rateCardAction = RATE_CARD_ACTION.USE_EXISTING;

          // check if ratecard exists
          if (!this.selectedRateCard) {
            this.infoMessage = (
              <p>
                The Rate Card used for the original Batch Search was not found. Please
                indicate which Rate Card to use for this Batch Search.
              </p>
            );
            this.infoModal.showModal();
            this.wizard.goTo(3);
          }

          // check if jobs where ignored
          if (this.jobsToSearch.length < batchSearchData.jobs.length) {
            this.errorMessage = (
              <p>
                {this.jobsToSearch.length > 0 ? "Some" : "All"} jobs in this Batch Search
                were removed due to unavailable locations.
              </p>
            );
            this.errorModal.showModal();
            this.showAddJobTitlesWizard = this.jobsToSearch.length === 0;
            this.wizard.goTo(2);
          }
        })
        .catch((reason) => {
          console.error(reason);
          this.network.loading = false;
          this.errorMessage = (
            <p>
              Sorry! we could not recreate the Batch Search. There was an error during the
              operation.
            </p>
          );
          this.errorModal.showModal();
          this.wizard.goTo(1);
          this.resetAll();
        });

      return res;
    });
  }

  // ------------------------------------------------------------
  //
  //   Jobs to Search filters
  //
  // ------------------------------------------------------------

  onJobsToSearchSelectedIndustryFilterChange(filter: ?ItemFilter) {
    this.jobsToSearchSelectedIndustryFilter = filter;
  }

  onJobsToSearchSelectedCategoryFilterChange(filter: ?ItemFilter) {
    this.jobsToSearchSelectedCategoryFilter = filter;
  }

  onJobsToSearchTitleFilterChange(query: string) {
    this.jobsToSearchTitleFilter = query;
  }

  filterJobsToSearch() {
    this.jobsToSearchFiltersModal.hideModal();
    const appliedFilters: ItemFilter[] = [];

    if (this.jobsToSearchTitleFilter) {
      appliedFilters.push(
        new ContainsItemFilter(
          ["title", "searchedTitle"],
          this.jobsToSearchTitleFilter,
          "titleFilter"
        )
      );
    }

    if (this.jobsToSearchSelectedIndustryFilter) {
      appliedFilters.push(this.jobsToSearchSelectedIndustryFilter);
    }

    if (this.jobsToSearchSelectedCategoryFilter) {
      appliedFilters.push(this.jobsToSearchSelectedCategoryFilter);
    }

    this.jobsToSearchAppliedFilters = appliedFilters; // this should trigger filtering
  }

  clearJobsToSearchFilters() {
    this.jobsToSearchFiltersModal.hideModal();
    this.jobsToSearchSelectedIndustryFilter = null;
    this.jobsToSearchSelectedCategoryFilter = null;
    this.jobsToSearchTitleFilter = "";
    this.jobsToSearchAppliedFilters = [];
  }

  // ------------------------------------------------------------
  //
  //   Bulk Change Industry
  //
  // ------------------------------------------------------------

  bulkChangeIndustryModalOnSelectedIndustryChange(industry: ?Industry) {
    this.bulkChangeIndustryModalSelectedIndustry = industry;
  }

  bulkChangeIndustryModalOnCommitChange() {
    const selectedIndustry = this.bulkChangeIndustryModalSelectedIndustry;
    const selectedJobs = this.jobsToSearchItems.filter((job) => job.viewState.selected);
    const hashTable = {};

    if (!selectedIndustry) {
      return;
    }

    // loop through selected items to changes
    selectedJobs.forEach((jobItem) => {
      // hash item (without industry), we want to avoid duplicates that would be
      // created by bulk changing the industry of two or more items that are
      // the same title, and location but searched on a different industry.
      const job = jobItem.batchSearchJob.jobTitle;
      const industry = "";
      const location = jobItem.batchSearchJobLocation;
      const isGSS = jobItem.isGSS;
      const workerTypeId = jobItem.batchSearchJob.workerType?.id || "none";
      const itemHash = BatchSearchJobListItem.getId(
        job.id,
        industry,
        location.id,
        isGSS,
        workerTypeId
      );
      // console.log("hash:", itemHash);

      // if hash exists
      if (hashTable[itemHash]) {
        // remove item from list
        jobItem.setSelected(false);
        this.removeJobFromSearch(jobItem);
      } else {
        // change item
        jobItem.id = BatchSearchJobListItem.getId(
          job.id,
          selectedIndustry.id,
          location.id,
          isGSS,
          workerTypeId
        );
        jobItem.batchSearchJob.industry = selectedIndustry;
        jobItem.industry = selectedIndustry.name || "";
        jobItem.setSelected(true);

        hashTable[itemHash] = true;
      }
    });

    // clean up
    this.industrySearch = "";
    this.bulkChangeIndustryModalSelectedIndustry = null;
    this.bulkChangeIndustryModal.hideModal();
  }

  bulkChangeIndustryModalOnCancel() {
    this.industrySearch = "";
    this.bulkChangeIndustryModalSelectedIndustry = null;
    this.bulkChangeIndustryModal.hideModal();
  }

  // ------------------------------------------------------------
  //
  //   Bulk Remove Jobs To Search Items
  //
  // ------------------------------------------------------------

  bulkRemoveJobsModalOnConfirm() {
    const selectedJobs = this.jobsToSearchItems.filter((job) => job.viewState.selected);
    // if (selectedJobs.length === this.jobsToSearchItemsView.length) {
    //   this.clearJobsToSearchFilters();
    // }

    selectedJobs.forEach((jobItem) => this.removeJobFromSearch(jobItem));
    this.bulkRemoveJobsModal.hideModal();
  }
}
