// @flow
import { extendObservable, action, runInAction } from "mobx";
import axios from "axios";
import NetworkState from "../../../models/NetworkState";
import ModalState from "../../../models/ModalState";
import NegotiationWorksheet from "../../../models/NegotiationWorksheet";
import MessageState from "../../../models/MessageState";
import {
  PAY_TYPE_OPTIONS,
  RATE_TYPE_OPTIONS,
} from "../../../constants/negotiationWorksheet";
import ApplyTagState from "../../../models/ApplyTagState";
import type { FetchGraphQL } from "../../../App";
import type MobXStore from "../MobXStore";

class NegotiationWorksheetDetailStore {
  store: MobXStore;
  fetchGraphQL: FetchGraphQL;
  router: Object;
  network: NetworkState;
  confirmDeleteModal: ModalState;
  negDetail: NegotiationWorksheet;
  renameWorksheetModal: ModalState;
  onNewWorksheetNameChange: (Event) => void;
  messaging: MessageState;
  showRenameWorksheetModal: () => void;
  worksheetId: number;
  showHelpModal: ModalState;
  showHelp: () => void;
  revisionNumber: number;
  savedVersionNumber: number;
  apiLocationId: number;
  jsonValues: Object;
  setValues: () => void;
  isLatest: boolean;
  applyTagState: ApplyTagState;

  constructor(fetchGraphQL: FetchGraphQL, store: MobXStore) {
    this.fetchGraphQL = fetchGraphQL;
    this.store = store;
    this.router = null;

    extendObservable(this, {
      worksheetId: null,
      search: null,
      confirmDeleteModal: new ModalState(),
      network: new NetworkState(),
      negDetail: null,
      renameWorksheetModal: new ModalState(),
      newWorksheetName: "",
      messaging: new MessageState(),
      showHelpModal: new ModalState(),
      revisionNumber: "",
      savedVersionNumber: null,
      apiLocationId: null,
      jsonValues: {},
      isLatest: true,
      applyTagState: new ApplyTagState(fetchGraphQL),
    });
    this.fetchWorksheet = action(this.fetchWorksheet.bind(this));
    this.onNewWorksheetNameChange = action(this.onNewWorksheetNameChange.bind(this));
    this.showRenameWorksheetModal = action(this.showRenameWorksheetModal.bind(this));
    this.showHelp = action(this.showHelp.bind(this));
    this.setValues = action(this.setValues.bind(this));
  }

  onNewWorksheetNameChange(e: Event) {
    this.newWorksheetName = e.target.value;
  }

  showRenameWorksheetModal() {
    this.messaging.removeAll();
    this.newWorksheetName = this.negDetail.worksheetName;
    this.renameWorksheetModal.showModal();
  }

  showHelp() {
    this.showHelpModal.showModal();
  }

  async fetchWorksheet() {
    this.revisionNumber = "";
    this.store.negotiationWorksheetCreatePTStore.resetValues();
    let res = null;
    if (!/^\d+$/.test(this.worksheetId)) {
      if (this.router) {
        this.router.push({
          pathname: "/404NotFound",
          query: this.router.query,
        });
      }
      return res;
    }
    //   let params: string[] = pageQuery.params;
    // let args = pageQuery.args;
    // let variables = pageQuery.variables;
    let params: string[] = [];
    let args = [];
    let variables = {};
    //let filtersCriteria: string[] = [];

    let worksheetId = this.worksheetId;

    //let sortCriteria: string[] = [];

    params.push("$id: Int!");
    args.push("id: $id");
    variables.id = worksheetId;

    const queryParams = params.join(", ");
    const queryArgs = args.join(", ");
    //const queryFiltersCriteria = filtersCriteria.join(', ');

    const query = `
      query negotiationdetail(${queryParams}) {
        viewer {
          negotiationWorksheet (${queryArgs}, section:ADMIN){
            worksheetName
            payRate
            totalMarkupPercent
            state {
              name
              locationId
            }
            tags{
              name
              tagId
            }
            isIc
            markupType
            rateType
            payType
            created
            createdBy {
              firstName
              lastName
            }
            worksheetId
            country {
              name
              locationId
              apiLocationId
            }
            state {
              name
              locationId
              apiLocationId
            }
            worksheetRevision{
              revisionNumber
              jsonData {
                location
                payType
                payValue
                rateType
                values
                version
                workerType
              }
            }
           }
         }
        }
        `;

    this.network.error = null;
    this.network.loading = true;

    try {
      res = await this.fetchGraphQL(query, variables);
    } catch (e) {
      if (axios.isCancel(e)) {
        return e;
      }

      this.network.handleError("Getting Negotiation Detail", e);
      if (res !== null) {
        this.network.logGraphQLError("Get Negotiation Detail query", res);
      }

      // TODO: Display user friendly error message
      return e;
    }

    runInAction("fetchWorksheet--success", () => {
      this.network.loading = false;
      this.network.error = null;
      if (!res.data.viewer.negotiationWorksheet) {
        if (this.router) {
          this.router.push({
            pathname: "/404NotFound",
            query: this.router.query,
          });
        }
        return;
      }

      const createStore = this.store.negotiationWorksheetCreatePTStore;
      const node = res.data.viewer.negotiationWorksheet.worksheetRevision;
      if (this.network.logGraphQLError("Get Worksheet query", res)) {
        // TODO: Display user friendly error message
        return;
      }
      this.negDetail = new NegotiationWorksheet(
        this,
        res.data.viewer.negotiationWorksheet
      );

      this.savedVersionNumber = node.jsonData.version;
      this.apiLocationId = res.data.viewer.negotiationWorksheet.country.apiLocationId;
      createStore.countryid = res.data.viewer.negotiationWorksheet.country.locationId;
      this.revisionNumber = node.revisionNumber;
      if (res.data.viewer.negotiationWorksheet.state) {
        createStore.state = res.data.viewer.negotiationWorksheet.state.name;
        createStore.stateid = res.data.viewer.negotiationWorksheet.state.locationId;
        createStore.apiStateId = res.data.viewer.negotiationWorksheet.state.apiLocationId;
        createStore.getSchema(
          this.apiLocationId,
          this.savedVersionNumber,
          "detail",
          createStore.apiStateId
        );
      } else {
        createStore.getSchema(this.apiLocationId, this.savedVersionNumber, "detail");
      }
      this.jsonValues = node.jsonData;
    });
  }

  setValues() {
    const createStore = this.store.negotiationWorksheetCreatePTStore;
    var jsonData = this.jsonValues;
    var newPayType = null;
    var newRateType = null;
    createStore.setPayValue(createStore, jsonData.payValue, "detail");

    for (var key in PAY_TYPE_OPTIONS) {
      if (!PAY_TYPE_OPTIONS.hasOwnProperty(key)) continue;

      var obj = PAY_TYPE_OPTIONS[key];
      for (var prop in obj) {
        if (!obj.hasOwnProperty(prop)) continue;

        if (obj.enumValue === jsonData.payType) {
          newPayType = obj.value;
        }
      }
    }
    if (newPayType) {
      createStore.setPayType(createStore, newPayType, "detail");
    }

    if (jsonData.payType !== 3) {
      for (var ptkey in RATE_TYPE_OPTIONS) {
        if (!RATE_TYPE_OPTIONS.hasOwnProperty(ptkey)) continue;

        var ptobj = RATE_TYPE_OPTIONS[ptkey];
        for (var ptprop in ptobj) {
          if (!ptobj.hasOwnProperty(ptprop)) continue;

          if (ptobj.label === jsonData.rateType) {
            newRateType = ptobj.value;
          }
        }
      }

      if (newRateType) {
        createStore.setRateType(createStore, newRateType, "detail");
      }
    }

    if (jsonData.workerType) {
      createStore.setWorkerType(createStore, jsonData.workerType, "detail");
    }

    if (jsonData.values) {
      createStore.currentState.unsavedValues = JSON.parse(jsonData.values);
    }

    createStore.locationId = this.apiLocationId;

    this.isLatest = createStore.isLatest;
    var initialCurrentState = {};
    initialCurrentState.payValue = createStore.currentState.payValue;
    initialCurrentState.payType = createStore.currentState.payType;
    initialCurrentState.rateType = createStore.currentState.rateType;
    initialCurrentState.workerTypeKey = createStore.currentState.workerTypeKey;
    initialCurrentState.unsavedValues = createStore.currentState.unsavedValues;
    createStore.undoQueue[0] = initialCurrentState;
    createStore.currentState = initialCurrentState;
    createStore.tempObj.payValue = jsonData.payValue;
    createStore.tempObj.payType = newPayType;
    createStore.tempObj.rateType = newRateType;
    createStore.tempObj.workerTypeKey = jsonData.workerType;
    createStore.tempObj.unsavedValues = JSON.parse(jsonData.values);
  }
}

export default NegotiationWorksheetDetailStore;
